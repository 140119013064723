import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import CustomButton from "../../Components/Common/CustomButton/CustomButton";
import TabList from "@mui/lab/TabList";
import { Tab } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ROUTE } from "../../Services/Route/Route";

function ClientIndus() {
  const navigate = useNavigate();
  const [value, setValue] = React.useState("1");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <Grid
      container
      spacing={3}
      marginTop={window.innerWidth < 700 ? "40px" : "70px"}
    >
      <Grid xs={12} textAlign={"center"}>
        <h2>Clients & Industries</h2>
        <p className="mb-6">
          Whether as a website development agency or app development agency,
          Evervent has played an integral part in shaping the web presence of
          some elite brands. We've had the pleasure of growing brands in:
        </p>
      </Grid>
      <Grid xs={12} className="clientsIndustries">
        <TabContext value={value}>
          <TabList
            onChange={handleChange}
            aria-label="lab API tabs example"
            centered
            sx={{ marginBottom: "30px" }}
          >
            <Tab
              label={
                <div className="tab-label">
                  <img src="./images/healthcare.svg" />
                  Healthcare
                </div>
              }
              value="1"
            />
            <Tab
              label={
                <div className="tab-label">
                  <img src="./images/sports.svg" />
                  Wellness & Fitness
                </div>
              }
              value="2"
            />
            <Tab
              label={
                <div className="tab-label">
                  <img src="./images/education.svg" />
                  Retail & E- Commerce
                </div>
              }
              value="3"
            />
            <Tab
              label={
                <div className="tab-label">
                  <img src="./images/entertainment.svg" />
                  Hotel & Entertainment
                </div>
              }
              value="4"
            />
            <Tab
              label={
                <div className="tab-label">
                  <img
                    src="./images/Transportation&Logisitics.svg"
                    style={{ width: "100px" }}
                  />
                  Transportation & Logistics
                </div>
              }
              value="5"
            />
            <Tab
              label={
                <div className="tab-label">
                  <img src="./images/insurtech.svg" />
                  InsurTech
                </div>
              }
              value="6"
            />
            <Tab
              label={
                <div className="tab-label">
                  <img src="./images/transportation.svg" />
                  Automobile
                </div>
              }
              value="7"
            />
            <Tab
              label={
                <div className="tab-label">
                  <img src="./images/educationnew.svg" />
                  Education
                </div>
              }
              value="8"
            />
          </TabList>
          <TabPanel value="1">
            <Grid container spacing={3} alignItems={"center"}>
              <Grid xs={12} md={5} textAlign={"center"}>
                <img src="./images/healthcare.svg" />
              </Grid>
              <Grid
                xs={12}
                md={7}
                textAlign={window.innerWidth < 900 ? "center" : "left"}
              >
                <h5>Healthcare</h5>
                <p>
                  Our custom app and software development offering for the
                  healthcare and wellness industry focuses on data security,
                  user-friendly interfaces, and seamless integration with
                  existing systems. Tailored solutions streamline patient
                  management, telemedicine, and health monitoring while ensuring
                  compliance, enhancing care delivery and patient experiences.
                </p>
                <div className="ctaBtn mt-6">
                  <CustomButton
                    text_name="Explore"
                    class_name="blackLgBtn"
                    onClickFunction={() => {
                      navigate(ROUTE.HEALTH_CARE);
                    }}
                  />
                </div>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value="2">
            <Grid container spacing={3} alignItems={"center"}>
              <Grid xs={12} md={5} textAlign={"center"}>
                <img src="./images/sports.svg" />
              </Grid>
              <Grid
                xs={12}
                md={7}
                textAlign={window.innerWidth < 900 ? "center" : "left"}
              >
                <h5>Wellness & Fitness</h5>
                <p>
                  In the wellness and fitness industry, our top-notch custom app
                  and software development solutions are designed to promote
                  healthy lifestyles and improve user engagement. We create apps
                  that offer personalized workout plans, nutrition tracking, and
                  seamless integration with wearables, fostering motivation and
                  long-term wellness goals. Our expertise ensures cutting-edge
                  technology that enhances user experiences and fitness
                  outcomes.
                </p>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value="3">
            <Grid container spacing={3} alignItems={"center"}>
              <Grid xs={12} md={5} textAlign={"center"}>
                <img src="./images/education.svg" />
              </Grid>
              <Grid
                xs={12}
                md={7}
                textAlign={window.innerWidth < 900 ? "center" : "left"}
              >
                <h5>Retail & E- Commerce</h5>
                <p>
                  In the education and training industry, our custom app and
                  software development solutions empower organizations to
                  deliver engaging and effective learning experiences. We create
                  tailored applications that facilitate e-learning, student
                  management, and interactive course content. Our expertise
                  ensures user-friendly interfaces, robust features, and
                  scalability to meet the evolving needs of educators and
                  learners, enhancing educational outcomes and training
                  programs.
                </p>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value="4">
            <Grid container spacing={3} alignItems={"center"}>
              <Grid xs={12} md={5} textAlign={"center"}>
                <img src="./images/entertainment.svg" />
              </Grid>
              <Grid
                xs={12}
                md={7}
                textAlign={window.innerWidth < 900 ? "center" : "left"}
              >
                <h5>Hotel & Entertainment</h5>
                <p>
                  In the Hotel, Media, and Entertainment industry, our custom
                  app and software development solutions cater to your unique
                  needs. We provide interactive hotel management systems, media
                  content delivery platforms, and entertainment app development.
                  Our expertise ensures seamless guest experiences, efficient
                  content distribution, and enhanced customer engagement,
                  driving success in these dynamic sectors.
                </p>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value="5">
            <Grid container spacing={3} alignItems={"center"}>
              <Grid xs={12} md={5} textAlign={"center"}>
                <img src="./images/Transportation&Logisitics.svg" />
              </Grid>
              <Grid
                xs={12}
                md={7}
                textAlign={window.innerWidth < 900 ? "center" : "left"}
              >
                <h5>Transportation & Logistics</h5>
                <p>
                  In the Transportation and Logistics industry, our custom app
                  and software development solutions optimize operations and
                  streamline supply chains. We create tailored applications for
                  route optimization, fleet management, and supply chain
                  visibility. Our expertise ensures efficient logistics, cost
                  savings, and improved delivery schedules, making us your go-to
                  partner for success in this fast-paced industry.
                </p>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value="6">
            <Grid container spacing={3} alignItems={"center"}>
              <Grid xs={12} md={5} textAlign={"center"}>
                <img src="./images/insurtech.svg" />
              </Grid>
              <Grid
                xs={12}
                md={7}
                textAlign={window.innerWidth < 900 ? "center" : "left"}
              >
                <h5>Insurtech Software</h5>
                <p>
                  In the Insurtech industry, our custom app and software
                  development solutions revolutionize insurance processes. We
                  design tailored applications for policy management, claims
                  processing, and risk assessment. Our expertise ensures
                  efficient automation, enhanced customer experiences, and data
                  security compliance, empowering Insurtech companies to thrive
                  in the digital age.
                </p>
                <div className="ctaBtn mt-6">
                  <CustomButton
                    text_name="Explore"
                    class_name="blackLgBtn"
                    onClickFunction={() => {
                      window.location.href = "https://insurvent.com/";
                      navigate(ROUTE.HEALTH_CARE);
                    }}
                  />
                </div>
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value="7">
            <Grid container spacing={3} alignItems={"center"}>
              <Grid xs={12} md={5} textAlign={"center"}>
                <img src="./images/transportation.svg" />
              </Grid>
              <Grid
                xs={12}
                md={7}
                textAlign={window.innerWidth < 900 ? "center" : "left"}
              >
                <h5>Automobiles</h5>
                <p>
                  In the dynamic realm of the automobile industry, our
                  specialized app and software development services are poised
                  to redefine the way vehicles are experienced and maintained.
                  Leveraging cutting-edge solutions such as IoT connectivity,
                  predictive maintenance, and real-time diagnostics, we ensure
                  your brand leads the innovation race. By crafting exceptional
                  servicing & driving experiences, enhancing vehicle safety, and
                  optimizing efficiency, we stand as your trusted partners in
                  shaping the future of automobile technology.
                </p>
                {/* <div className="ctaBtn mt-6">
                  <CustomButton
                    text_name="Explore"
                    class_name="blackLgBtn"
                    onClickFunction={() => {
                      window.location.href = "https://insurvent.com/";
                      navigate(ROUTE.HEALTH_CARE);
                    }}
                  />
                </div> */}
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value="8">
            <Grid container spacing={3} alignItems={"center"}>
              <Grid xs={12} md={5} textAlign={"center"}>
                <img src="./images/educationnew.svg" />
              </Grid>
              <Grid
                xs={12}
                md={7}
                textAlign={window.innerWidth < 900 ? "center" : "left"}
              >
                <h5>Education & Training</h5>
                <p>
                  In the education and training industry, our custom app and
                  software development solutions empower organizations to
                  deliver engaging and effective learning experiences. We create
                  tailored applications that facilitate e-learning, student
                  management, and interactive course content. Our expertise
                  ensures user-friendly interfaces, robust features, and
                  scalability to meet the evolving needs of educators and
                  learners, enhancing educational outcomes and training
                  programs.
                </p>
                {/* <div className="ctaBtn mt-6">
                  <CustomButton
                    text_name="Explore"
                    class_name="blackLgBtn"
                    onClickFunction={() => {
                      window.location.href = "https://insurvent.com/";
                      navigate(ROUTE.HEALTH_CARE);
                    }}
                  />
                </div> */}
              </Grid>
            </Grid>
          </TabPanel>
        </TabContext>
      </Grid>
    </Grid>
  );
}

export default ClientIndus;
