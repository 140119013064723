import React from "react";
import { Box } from "@mui/material";
import "./ChooseBox.scss";

interface ChooseBoxProps {
  imageSrc: string;
  title: string;
  description: string;
  isActive?: boolean;
}

const ChooseBox: React.FC<ChooseBoxProps> = ({
  imageSrc,
  title,
  description,
  isActive = true,
}: ChooseBoxProps) => {
  return (
    <Box className={!isActive ? "chooseBox active" : "chooseBox"}>
      <img src={imageSrc} alt={title} />
      <div>
        <h4 className="mb-3">{title}</h4>
        <p>{description}</p>
      </div>
    </Box>
  );
};

export default ChooseBox;
