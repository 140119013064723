import { Box, Tab } from "@mui/material";
import { useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import "../../../CommonSCSS/common.scss";
import RKTextField from "../FieldTypes/RKTextField/RKTextField";
import CustomButton from "../CustomButton/CustomButton";
import TextArea from "../FieldTypes/TextArea/TextArea";
import {
  internationalMobileNumber,
  isEmpty,
  validateEmail,
} from "../../../SupportingFiles/HelpingFunctions";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Calendly from "../FieldTypes/Calendly/Calendly";
import { FORM_SERVICES } from "../../../Services/FormServices";
import { toast } from "react-toastify";
import Loader from "../Loader/Loader";
import SearchDropdown from "../FieldTypes/SearchDropdown/SearchDropdown";
import { countryCodeOption } from "../../../Services/Type/Home/THomePage";

function RequestQuotationComponent() {
  const [tabsVal, setTabsVal] = useState<"inquire" | "meeting">("inquire");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formFields, setFormFields] = useState<{
    name: { value: string; warning: boolean };
    title: { value: string; warning: boolean };
    organization: { value: string; warning: boolean };
    email: { value: string; warning: boolean };
    country: { value: string; warning: boolean };
    mobile: { value: string; warning: boolean };
    message: { value: string; warning: boolean };
  }>({
    name: { value: "", warning: false },
    title: { value: "", warning: false },
    email: { value: "", warning: false },
    organization: { value: "", warning: false },
    country: { value: "", warning: false },
    mobile: { value: "", warning: false },
    message: { value: "", warning: false },
  });
  const fieldsUpdateState = (attrName: any, value: any) => {
    setFormFields({
      ...formFields,
      [attrName[0]]: {
        value: value,
        warning: isEmpty(value),
      },
    });
  };
  const validateForm = () => {
    let data = { ...formFields };
    data = {
      ...data,
      name: {
        ...data.name,
        warning: isEmpty(data.name.value),
      },
      title: {
        ...data.title,
        warning: isEmpty(data.title.value),
      },
      organization: {
        ...data.organization,
        warning: isEmpty(data.organization.value),
      },
      email: {
        ...data.email,
        warning: !validateEmail(data.email.value),
      },
      country: {
        ...data.country,
        warning: isEmpty(data.country.value),
      },
      mobile: {
        ...data.mobile,
        warning: !internationalMobileNumber(
          data.mobile.value,
          data.country.value
        ),
      },
      message: {
        ...data.message,
        warning: isEmpty(data.message.value),
      },
    };
    setFormFields({ ...data });

    let hasError = false;
    Object.values(data)?.map((item) => {
      if (item.warning) hasError = true;
    });

    if (!hasError) {
      FormSubmit();
    }
  };

  const FormSubmit = () => {
    const onSuccess = (res: any) => {
      const results = res.results;
      const error = results.error;
      if (error === false) {
        toast.success(results.response);
        setFormFields({
          name: { value: "", warning: false },
          title: { value: "", warning: false },
          email: { value: "", warning: false },
          organization: { value: "", warning: false },
          country: { value: "", warning: false },
          mobile: { value: "", warning: false },
          message: { value: "", warning: false },
        });
      } else {
        // console.log(results)
        toast.error(results.message);
      }
    };

    const onError = (err: any) => {
      console.log("updatefalse", err);
    };

    let dto = {
      name: formFields.name.value,
      mobile: formFields.mobile.value,
      title: formFields.title.value,
      company_name: formFields.organization.value,
      company_email: formFields.email.value,
      description: formFields.message.value,
      country_code: formFields.country.value,
    };

    FORM_SERVICES.QuotationFormService(onSuccess, onError, dto);
  };
  useEffect(() => {
    if (tabsVal === "meeting") {
      setTimeout(() => {
        setIsLoading(false);
      }, 3000);
    }
  }, [tabsVal]);

  return (
    <Box className="enquiry-section requestQout">
      <Grid container spacing={3} justifyContent={"center"}>
        <Grid xs={12} lg={5}>
          <h1 className="mb-6">
            Request a <span>Quotation</span>
          </h1>
          <p className="mb-7">We area here for you. how can we help?</p>
          <img
            src="./images/pd_enquiry_request_img.svg"
            alt=""
            className="request_sec_img"
            style={{
              marginBottom: window.innerWidth < 900 ? "30px" : "0px",
            }}
          />
        </Grid>
        <Grid xs={12} lg={7}>
          <TabContext value={tabsVal}>
            <Box className="tabBox">
              <TabList
                className=""
                onChange={(e, a: "inquire" | "meeting") => {
                  setTabsVal(a);
                  if (a === "meeting") {
                    setIsLoading(true);
                  }
                }}
              >
                <Tab
                  className={tabsVal === "inquire" ? "active" : ""}
                  label="Inquire"
                  value="inquire"
                />
                <Tab
                  className={tabsVal === "meeting" ? "active" : ""}
                  label="Schedule a meeting"
                  value="meeting"
                />
              </TabList>
            </Box>
            {/* INQUIRE TAB  */}
            <TabPanel className="tabPanel" value="inquire">
              <Grid container spacing={3}>
                <Grid xs={12} sm={6} md={4} lg={12}>
                  <RKTextField
                    class_name="inputField"
                    title={"Name"}
                    value={formFields.name.value}
                    attrName={["name"]}
                    value_update={fieldsUpdateState}
                    warn_status={formFields.name.warning}
                    error_messg="Please enter name"
                  />
                </Grid>
                <Grid xs={12} sm={6} md={4} lg={12}>
                  <RKTextField
                    class_name="inputField"
                    title={"Title"}
                    value={formFields.title.value}
                    attrName={["title"]}
                    value_update={fieldsUpdateState}
                    warn_status={formFields.title.warning}
                    error_messg="Please enter title"
                  />
                </Grid>
                <Grid xs={12} sm={6} md={4} lg={12}>
                  <RKTextField
                    class_name="inputField"
                    title={"Company or Organization Name"}
                    value={formFields.organization.value}
                    attrName={["organization"]}
                    value_update={fieldsUpdateState}
                    warn_status={formFields.organization.warning}
                    error_messg="Please enter organization"
                  />
                </Grid>
                <Grid xs={4} sm={2} md={4} lg={3}>
                  <SearchDropdown
                    class_name="inputField"
                    title={"Country"}
                    value={formFields.country.value}
                    attrName={["country"]}
                    value_update={fieldsUpdateState}
                    warn_status={formFields.country.warning}
                    error_message={" select country"}
                    data={countryCodeOption}
                  />
                </Grid>
                <Grid xs={8} sm={4} md={4} lg={9}>
                  <RKTextField
                    class_name="inputField"
                    title={"Mobile Number"}
                    value={formFields.mobile.value}
                    attrName={["mobile"]}
                    value_update={fieldsUpdateState}
                    warn_status={formFields.mobile.warning}
                    error_messg={
                      formFields.mobile.value?.length
                        ? " Please enter valid mobile number"
                        : " Please enter mobile number"
                    }
                    max_length={15}
                    validation_type="NUMBER"
                  />
                </Grid>
                <Grid xs={12} sm={12} md={4} lg={12}>
                  <RKTextField
                    class_name="inputField"
                    title={"Company Email"}
                    value={formFields.email.value}
                    attrName={["email"]}
                    value_update={fieldsUpdateState}
                    warn_status={formFields.email.warning}
                    error_messg={
                      formFields.email.value?.length
                        ? " Please enter valid email"
                        : " Please enter email"
                    }
                  />
                </Grid>
                <Grid xs={12}>
                  <TextArea
                    class_name="inputField"
                    title={"How can we help?"}
                    value={formFields.message.value}
                    placeholder={"Enter description here"}
                    attrName={["message"]}
                    value_update={fieldsUpdateState}
                    warn_status={formFields.message.warning}
                    error_messg="Please enter message"
                  />
                </Grid>
                <Grid xs={12} className="ctaBtn request">
                  <CustomButton
                    text_name={"Submit"}
                    class_name="blackLgBtn"
                    onClickFunction={validateForm}
                  />
                </Grid>
              </Grid>
            </TabPanel>
            {/* MEETING TAB  */}
            <TabPanel className="tabPanel calendlyTab" value="meeting">
              <Grid container>
                <Grid xs={12}>
                  {isLoading ? <Loader /> : null}
                  <Calendly />
                </Grid>
              </Grid>
            </TabPanel>
          </TabContext>
        </Grid>
      </Grid>
    </Box>
  );
}

export default RequestQuotationComponent;
