import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Link } from "@mui/material";
import "./Footer.scss";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import { useNavigate } from "react-router-dom";
import { ROUTE } from "../../../Services/Route/Route";

const Footer: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Box className="footer ">
      <Grid container spacing={3}>
        <Grid xs={6} md={3}>
          <img src="./images/evervent-logo.svg" alt="evervent" height="40px" />
          <ul>
            <li>
              {/* <Link href="https://evervent.in/about">About us</Link> */}
              <Link
                onClick={() => {
                  navigate(ROUTE.ABOUT);
                }}
              >
                About us
              </Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  navigate(ROUTE.CONTACT);
                }}
              >
                Contact
              </Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  navigate(ROUTE.CAREER);
                }}
              >
                Career
              </Link>
            </li>
            <li>
              <Link>Blog</Link>
            </li>
          </ul>
        </Grid>
        <Grid xs={6} md={3}>
          <h5>Industry</h5>
          <ul>
            <li>
              <Link
                onClick={() => {
                  navigate(ROUTE.HEALTH_CARE);
                }}
              >
                Healthcare
              </Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  navigate(ROUTE.WELLNESS_FITNESS);
                }}
              >
                Wellness & Fitness
              </Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  navigate(ROUTE.RETAIL);
                }}
              >
                Retail & E-Commerce
              </Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  navigate(ROUTE.TRAVEL_HOSPITALITY);
                }}>Travel & Hospitality</Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  navigate(ROUTE.TRANSPORTATION);
                }}
              >
                Transportation & Logistics
              </Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  navigate(ROUTE.AUTOMOTIVE);
                }}>Automotive</Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  navigate(ROUTE.MEDIA_ENTERTAINMENT);
                }}
              >
                {" "}
                Media and Entertainment
              </Link>
            </li>
            <li>
              <Link href="https://insurvent.com/" target="_blank">
                Insurance
              </Link>
            </li>
            <li>
              <Link onClick={() => navigate(ROUTE.EDUCATION)}>
                Education & E-Learning
              </Link>
            </li>
          </ul>
        </Grid>
        <Grid xs={6} md={3}>
          <h5>What We Do</h5>
          <ul>
            <li>
              <Link>Blockchain Development</Link>
            </li>
            <li>
              <Link>AI-Powered App Builder</Link>
            </li>
            <li>
              <Link onClick={() => navigate(ROUTE.GROWTH_MARKETING)}>
                Growth & Marketing
              </Link>
            </li>
            <li>
              <Link onClick={() => navigate(ROUTE.DESIGN_STUDIO)}>
                Design Studio
              </Link>
            </li>
            <li>
              <Link onClick={() => navigate(ROUTE.PRODUCT_DEVELOPMENT)}>
                Product Development
              </Link>
            </li>
            <li>
              <Link onClick={() => navigate(ROUTE.IT_CONSULTING)}>
                IT Consulting & Staff Augmentation
              </Link>
            </li>
          </ul>
        </Grid>
        <Grid xs={6} md={3} className="footer_addr">
          <Box display={'flex'} columnGap={'22px'} flexWrap={"wrap"}>
            <Box className="box1">
              <p>India | Mohali</p>
              <img src="/images/logo_ind.svg" alt="ind" />
            </Box>
            <Box>
              <p>4th Floor, plot no. 341,</p>
              <p> Industrial Area, Phase 9, </p>
              <p>Mohali, Punjab, India - 160062</p>
              <span><img className="mr-1" src="/images/dial.svg" alt="dd" />+91-96250 70315</span>
            </Box>
          </Box>
          <Box className="mt-3 mb-3" display={'flex'} columnGap={'22px'} flexWrap={"wrap"}>
            <Box className="box1">
              <p>USA | Illinois</p>
              <img src="/images/logo_usa.svg" alt="ind" />
            </Box>
            <Box>
              <p>USA 1145- Windbrooke</p>
              <p>Drive, Buffalo Grove - IL</p>
              <p>(60089)</p>
              <span><img className="mr-1" src="/images/dial.svg" alt="dd" />+1 (773) 231-0045</span>
            </Box>
          </Box>
          <Link href="mailto:hello@evervent.io">hello@evervent.io</Link>
          <ul className="socialLinks">
            <li>
              <a href="https://www.facebook.com/everventindia" target="_blank">
                <FacebookIcon />
              </a>
            </li>
            <li>
              <a
                href="https://in.linkedin.com/company/evervent1"
                target="_blank"
              >
                <LinkedInIcon />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/accounts/login/?next=/evervent_/"
                target="_blank"
              >
                <InstagramIcon />
              </a>
            </li>
          </ul>


          {/* <p>
            4<sup>th</sup> Floor, plot no. 341, Industrial Area, Phase 9,
            Mohali, Punjab, India - 160062
          </p>
          <p className="my-4">+91-96250 70315</p>
          <Link href="mailto:hello@evervent.io">hello@evervent.io</Link>
          <ul className="socialLinks">
            <li>
              <a href="https://www.facebook.com/everventindia" target="_blank">
                <FacebookIcon />
              </a>
            </li>
            <li>
              <a
                href="https://in.linkedin.com/company/evervent1"
                target="_blank"
              >
                <LinkedInIcon />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/accounts/login/?next=/evervent_/"
                target="_blank"
              >
                <InstagramIcon />
              </a>
            </li>
          </ul> */}
        </Grid>
        <Grid xs={12} textAlign={"center"}>
          <img
            src="./images/hrline.svg"
            width="100%"
            style={{ maxWidth: "600px", margin: "12px auto" }}
            alt="img"
          />
          <p>© Copyright 2023 www.evervent.io | All Rights Reserved.</p>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
