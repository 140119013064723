import React from "react";

interface SelectBoxProps {
  title: string;
  description: string;
  imageUrl: string;
  class_name?: string;
}

function SelectBox({
  title,
  description,
  imageUrl,
  class_name,
}: SelectBoxProps) {
  return (
    <div className={`selectBox ${class_name} `}>
      <div className="tickIcon"></div>
      <img src={imageUrl} alt={title} />
      <h5>{title}</h5>
      <p>{description}</p>
    </div>
  );
}

export default SelectBox;
