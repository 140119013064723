import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useState } from "react";
import "../../CommonSCSS/common.scss";
import Footer from "../../Components/Desktop/Footer/Footer";
import Navbar from "../../Components/Desktop/Navbar/Navbar";
import MNavbar from "../../Components/Mobile/Navbar/MNavbar";
import useIsMobile from "../../SupportingFiles/MobileProvider";
import CaseStudyBox from "../HealthCare/CaseStudyBox/CaseStudyBox";
import RequestQuotationComponent from "../../Components/Common/RequestComponent/RequestQuotationComponent";
import "react-multi-carousel/lib/styles.css";
import RequestQuotation from "../../Components/Common/RequestQuotationPopup/RequestQuotation";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Typography } from "@mui/material";
import CustomButton from "../../Components/Common/CustomButton/CustomButton";
import CaseStudyModal, { caseType } from "../../Components/Common/CaseStudy/CaseStudyModal";


function AutoMotive() {
    const isMobile = useIsMobile();
    const [open, setOpen] = useState<boolean>(false);
    const [openRequestQuotation, setOpenRequestQuotation] = useState<boolean>(false);
    const travelNeed = [
        {
            expanded: "panel1", heading: "Big Data Warehousing Solutions:", content: [
                "Dive into the era of big data with our Big Data Warehousing Solutions, handling massive volumes of information with efficiency and precision.",
                "Unlock the potential of diverse and complex data sets, turning them into actionable insights that drive innovation and competitive advantage.",
                "Scale your data infrastructure seamlessly, ensuring that your organization is ready to tackle the challenges and opportunities presented by the big data landscape.",
            ]
        },
        {
            expanded: "panel2", heading: "Dealer Management Systems (DMS):", content: [
                "Revolutionize your dealership operations with our DMS, a comprehensive platform for seamless inventory management, sales tracking, and customer engagement.",
                "Elevate your automotive business efficiency with our Dealer Management System, integrating sales, service, and customer relations into a cohesive digital ecosystem.",
                "Drive success on and off the lot with our DMS, empowering dealerships with intuitive tools for optimal performance and customer satisfaction.",
            ]
        },
        {
            expanded: "panel3", heading: "CRM, and ERP Solutions:", content: [
                "Transform your online presence with our E-Commerce solutions, merging user-friendly interfaces with robust backend systems for a seamless customer experience.",
                "Amplify customer relationships with our CRM solutions, fostering personalized interactions that enhance loyalty and drive business growth.",
                "Achieve operational excellence with our ERP solutions, integrating core business processes and data for enhanced efficiency and strategic decision-making."
            ]
        },
        {
            expanded: "panel4", heading: "Supply Chain Management (SCM):", content: [
                "Optimize your supply chain with our SCM solutions, providing real-time visibility, process automation, and demand forecasting for agile and responsive operations.",
                "Streamline logistics and reduce costs with our Supply Chain Management solutions, ensuring a resilient and efficient supply network.",
                "Navigate the complexities of global supply chains confidently with our SCM solutions, unlocking new levels of efficiency and collaboration."
            ]
        },
        {
            expanded: "panel5", heading: "Management Information Systems (MIS):", content: [
                "Empower your leadership with our MIS, delivering accurate and timely information for strategic planning, performance monitoring, and decision support.",
                "Harness the power of data for informed decision-making with our Management Information Systems, tailored to meet the unique needs of your organization.",
                "Elevate organizational efficiency and effectiveness with our MIS solutions, providing a comprehensive view of key performance indicators and trends."
            ]
        },
        {
            expanded: "panel6", heading: "Product Life Cycle Management (PLCM):", content: [
                "Maximize product success from inception to retirement with our PLCM solutions, offering end-to-end management of product data, processes, and collaboration.",
                "Innovate and accelerate time-to-market with our Product Life Cycle Management solutions, ensuring seamless collaboration and streamlined workflows.",
                "Navigate the complexities of product development with confidence using our PLCM solutions, optimizing every stage of the product life cycle."
            ]
        },
        {
            expanded: "panel7", heading: "Enterprise Mobility Solutions:", content: [
                "Embrace the future of work with our Enterprise Mobility Solutions, empowering your workforce to stay connected, productive, and agile in a mobile-first world.",
                "Enhance business operations with our Mobility Solutions, enabling seamless collaboration and access to critical information anytime, anywhere.",
                "Unlock the full potential of your enterprise with our Mobility Solutions, fostering innovation and responsiveness through secure and scalable mobile platforms."
            ]
        },
        {
            expanded: "panel8", heading: "Software Maintenance and Support:", content: [
                "Ensure the longevity and performance of your software with our Maintenance and Support services, offering timely updates, bug fixes, and continuous improvements.",
                "Experience peace of mind with our Software Maintenance and Support, providing proactive monitoring and responsive assistance to keep your systems running smoothly.",
                "Extend the life of your software investments with our comprehensive Maintenance and Support solutions, tailored to meet the evolving needs of your business."
            ]
        },
        {
            expanded: "panel9", heading: "Logistics and Inventory Solutions:", content: [
                "Streamline your logistics and inventory management with our solutions, optimizing processes, reducing costs, and improving overall supply chain efficiency.",
                "Gain real-time visibility into your inventory with our Logistics Solutions, ensuring accurate tracking, minimizing stockouts, and maximizing operational efficiency.",
                "Navigate the complexities of modern logistics with confidence using our Inventory Solutions, designed to enhance accuracy, responsiveness, and customer satisfaction."
            ]
        },
    ]
    const [expanded, setExpanded] = useState<string | false>("panel1");
    const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };
    const experties = [
        "UI/UX Design", "Web Application Development", "Mobile Application Development", "Enterprise Software Development",
        "Product Strategy for Startups", "Quality Assurance", "Maintenance & Support", "Consulting Services"
    ]
    const [openCaseModal, setOpenCaseModal] = useState<boolean>(false);
    const [clickedCase, setClickedCase] = useState<caseType>({
        head: "", subHead: "", mainHeading: "", keywords: "", headPara: "", intro: "",
        challenge: "", solution: "", key: [], conclusion: ""
    });
    const caseStudies: caseType[] = [
        {
            head: "Rowley Autos", subHead: "Empowering Rowley Autos with Customized Digital Solutions Tailored to Their Business Needs",
            keywords: "Client: Rowley Autos, Coventry, UK.",
            mainHeading: "Empowering Rowley Autos with Customized Digital Solutions Tailored to Their Business Needs",
            headPara: "",
            intro: "At Evervent, we understand that every business has unique challenges and opportunities. Rowley Autos, a key player in the UK automotive sector specializing in heavy goods vehicle (HGV) maintenance and inspections, approached us with a clear vision: to optimize their Periodic Maintenance Inspection (PMI) processes. They needed a solution that not only automated PMI checks but also seamlessly integrated with their existing operations, enhancing data accuracy and transparency. Here's how Evervent made it happen.",
            challenge: "Rowley Autos faced significant challenges with manual PMI checks for their extensive HGV fleet. These processes were not only time-consuming but also prone to human errors, leading to data inconsistencies. Our task was clear: understand their business use case deeply and provide digital solutions that would complement their existing workflow.",
            solution: "Evervent, led by our Senior Product Manager, embarked on a journey to develop a tailored solution that met Rowley Autos' specific needs:",
            key: [
                { heading: "Time and Cost Savings", content: "Automation significantly reduced manual effort, leading to substantial time and cost savings." },
                { heading: "Data Accuracy", content: "Transitioning to digital processes minimized errors, elevating the quality of PMI inspections and reports." },
                { heading: "Enhanced Customer Experience", content: "The streamlined booking system improved client satisfaction and loyalty." },
                { heading: "Seamless Integration", content: "Our solutions seamlessly integrated into Rowley Autos' workflow, minimizing disruption and ensuring a smooth transition." },
                { heading: "Transparency and Compliance", content: "Real-time access to data and compliance with UK regulations ensured transparency and minimized compliance risks." },
                { heading: "Broking System", content: "We developed a user-friendly booking system that allowed Rowley Autos' clients to schedule PMI checks and services online. This streamlined the appointment booking process while ensuring minimal disruption to their workflow." },
                { heading: "iPad Inspection App", content: "Our dedicated iPad application simplified PMI inspections, offering pre-defined checklists and real-time syncing with the central database. This complemented their technician's tasks, reducing inspection time and improving accuracy." },
                { heading: "Robust Reporting System", content: "Our solution integrated a robust reporting system. It produced detailed inspection reports with digital signatures and compliance indicators, accessible in real-time to enhance transparency and data integrity" },
                { heading: "Data Analytics", content: "We included advanced data analytics tools, offering insights into PMI trends. This empowered Rowley Autos to make data-driven decisions, optimize maintenance planning, and control costs." },
            ],
            conclusion: ""
        },
        {
          head: "Store Finder", subHead: "Revolutionizing Automotive Retail with Evervent's Dealer & Service Center Locator",
          keywords: "Auto Dealer Store Finder, Automotive Software Solutions, Customer-Centric Technology",
          mainHeading: "Pioneering Auto Retail Solutions: Unleashing the Power of Precision",
          headPara: "Redefining Customer Engagement through Advanced Automotive Software",
          intro: "Evervent's Auto Dealer Store Finder is more than a conventional mapping tool; it's a tech-driven solution reshaping the landscape of automotive retail. This case study delves into the technological innovations that have made our Locator a game-changer for the industry.",
          challenge: "In a highly competitive automotive market, the challenge was not only to provide customers with location information but to leverage technology for a richer, more engaging experience. The goal was to transform a simple locator into a tech-driven hub for customer interaction.",
          solution: "Evervent's Auto Dealer Store Finder tackled this challenge with a suite of technological features",
          key: [
              { heading: "Geospatial Intelligence", content: "Leveraging advanced geospatial technology, our solution ensures accurate mapping and location data, providing customers with real-time information." },
              { heading: "API Integrations", content: "Seamless integration with dealership databases and third-party APIs ensures that the locator reflects the most up-to-date information on inventory, promotions, and services." },
              { heading: "Augmented Reality (AR) Interface", content: "Taking the user experience to the next level, our solution incorporates AR, allowing customers to visualize dealerships and service centers in their physical surroundings through their mobile devices." },
              { heading: "Machine Learning Algorithms", content: "To enhance user recommendations, our locator employs machine learning algorithms that analyze customer preferences, providing personalized suggestions for dealerships and services." },
              { heading: "Mobile App Integration", content: "Our solution seamlessly integrates with mobile applications, allowing users to access the locator directly from their smartphones for on-the-go convenience." },
              { heading: "Feedback Analytics", content: "Customer feedback isn't just collected; it's analyzed using sentiment analysis algorithms, providing dealerships with valuable insights for continuous improvement." },
          ],
          conclusion: "Evervent's Auto Dealer Store Finder is a tech marvel, transforming the mundane task of finding an auto dealer into an engaging and dynamic experience. By leveraging cutting-edge geospatial intelligence, AR interfaces, and machine learning, our solution exemplifies how technology can redefine customer engagement in the automotive retail sector. It's not just a locator; it's a technological tour de force."
      },
    ]

    return (
      <Box className="pagesWrapper">
        <RequestQuotation
          open={openRequestQuotation}
          setOpen={setOpenRequestQuotation}
          popup_heading=""
        />

        <CaseStudyModal
          open={openCaseModal}
          setOpen={setOpenCaseModal}
          item={clickedCase}
        />

        {isMobile ? <MNavbar /> : <Navbar open={open} setOpen={setOpen} />}

        <Box className="edu_header">
          <Grid container spacing={3}>
            <Grid xs={12} lg={6} className="head">
              <h1 style={{ display: "block" }}>
                <span>Drive into the Future </span>of Automotive Software with
                Evervent's Expertise!
              </h1>
              <p>
                Fuel your automotive software evolution with our expertise!
                Whether you're building from the ground up or refining your
                existing system, Evervent is your trusted collaborator. We excel
                in automotive engineering services, delivering bespoke software
                solutions that align with your business needs. Count on us to
                steer your success as a leading automotive software development
                company, propelling your business ahead of the competition.
                Let's embark on a thrilling journey to innovation together!
              </p>
            </Grid>
            <Grid xs={12} lg={6} display={"flex"} justifyContent={"center"}>
              <img src="/images/automotive_header.svg" alt="con" />
            </Grid>
          </Grid>
        </Box>

        <Box className="auto_expertise">
          <Grid container columnSpacing={3}>
            <Grid xs={12} className="mb-10" textAlign={"center"}>
              <h2 className="mb-3">
                <span>Our expertise lies in creating customized software </span>
                solutions tailored to the unique requirements of the automotive
                industry.
              </h2>
              <p>
                We provide an extensive range of services aimed at improving
                your workflow, and we possess the ability to join a project at
                any stage. Our main services include
              </p>
            </Grid>
          </Grid>
          <Box className="expertise_bottom">
            <Grid container spacing={3}>
              {experties?.map((item) => (
                <Grid xs={12} sm={6} md={4} lg={3}>
                  <Box className="main_box">
                    <img
                      className="mr-4"
                      src="/images/radio_check.svg"
                      alt=""
                    />
                    <p>{item}</p>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>

        <Box className="trans_offers auto_solutions">
          <Grid container spacing={3}>
            <Grid xs={12} lg={6} display={"flex"} justifyContent={"center"}>
              <img src="/images/autoSolution.svg" alt="" />
            </Grid>
            <Grid xs={12} lg={6}>
              <div className="head mb-6">
                <h2>
                  <span>Several of the primary </span>
                  solutions we offer include
                </h2>
              </div>
              <Box className="content">
                {travelNeed.map((item, i) => (
                  <Accordion
                    key={i}
                    expanded={expanded === item.expanded}
                    onChange={handleChange(item.expanded)}
                    className="main"
                  >
                    <AccordionSummary
                      expandIcon={
                        expanded === item.expanded ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )
                      }
                      className="headBox"
                      // aria-controls={item.expanded + "bh-content"}
                      // id={item.expanded + "bh-header"}
                    >
                      <Typography>
                        <img src="/images/check_bw.svg" alt="check" />
                        {item.heading}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className="bodyBox mt-3">
                      {item?.content?.map((data) => (
                        <Typography>
                          <img src="/images/small_check_bw.svg" alt="check" />
                          {data}
                        </Typography>
                      ))}
                      <Box className="ctaBtn mt-2">
                        <CustomButton
                          text_name={"Inquire"}
                          class_name="blueMdBtn"
                          onClickFunction={() => setOpenRequestQuotation(true)}
                        />
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box className="case_study">
          <Grid container columnSpacing={3}>
            <Grid xs={12} className="mb-10" textAlign={"center"}>
              <h2 className="mb-3">Case Studies </h2>
              <p>
                In the realm of challenges, every case study is a chapter of
                solutions, each offering its unique wisdom to those who seek the
                knowledge it holds.
              </p>
            </Grid>
          </Grid>
          <Box className="case_bottom">
            <Grid
              container
              columnSpacing={3}
              justifyContent={"center"}
              rowGap={"24px"}
            >
              {caseStudies?.map((item) => (
                <Grid xs={6} sm={6} md={4} lg={3}>
                  <CaseStudyBox
                    color="#79D05A"
                    title={item?.head}
                    description={item?.subHead}
                    onClickFunction={() => {
                      setOpenCaseModal(true);
                      setClickedCase(item);
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>

        <Box className="edu_quotation">
          <RequestQuotationComponent />
        </Box>

        <Footer />
      </Box>
    );
}

export default AutoMotive;


