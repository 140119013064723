import { useEffect } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import AutoMotive from "./Pages/AutoMotive/AutoMotive";
import Aboutus from "./Pages/CmsPages/Aboutus/Aboutus";
import Career from "./Pages/CmsPages/Career/Career";
import Contactus from "./Pages/CmsPages/Contactus/Contactus";
import DesignStudio from "./Pages/DesignStudio/DesignStudio";
import Education from "./Pages/Education/Education";
import GrowthMarketing from "./Pages/GrowthMarketing/GrowthMarketing";
import HealthCare from "./Pages/HealthCare/HealthCare";
import Homepage from "./Pages/Homepage/Homepage";
import ItConsulting from "./Pages/ItConsulting/ItConsulting";
import MediaEntertainment from "./Pages/MediaEntertainment/MediaEntertainment";
import ProductDevelopment from "./Pages/ProductDevelopment/ProductDevelopment";
import Retail from "./Pages/Retail/Retail";
import Transportation from "./Pages/TransportationAndLogistics/Transportation";
import TravelHospitality from "./Pages/TravelHospitality/TravelHospitality";
import WellnessandFitness from "./Pages/WellnessandFitness/WellnessandFitness";
import { ROUTE } from "./Services/Route/Route";
import ERP from "./Pages/ERP/ERP";

const AppRoutes = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, 100);
  }, [pathname]);

  return (
    <>
      <Routes>
        <Route path={ROUTE.HOME} element={<Homepage />} />
        <Route path={ROUTE.HEALTH_CARE} element={<HealthCare />} />
        <Route path={ROUTE.CAREER} element={<Career />} />
        <Route path={ROUTE.ABOUT} element={<Aboutus />} />
        <Route path={ROUTE.CONTACT} element={<Contactus />} />
        <Route path={ROUTE.WELLNESS_FITNESS} element={<WellnessandFitness />} />
        <Route path={ROUTE.PRODUCT_DEVELOPMENT} element={<ProductDevelopment />} />
        <Route path={ROUTE.DESIGN_STUDIO} element={<DesignStudio />} />
        <Route path={ROUTE.IT_CONSULTING} element={<ItConsulting />} />
        <Route path={ROUTE.GROWTH_MARKETING} element={<GrowthMarketing />} />
        <Route path={ROUTE.EDUCATION} element={<Education />} />
        <Route path={ROUTE.RETAIL} element={<Retail />} />
        <Route path={ROUTE.TRANSPORTATION} element={<Transportation />} />
        <Route path={ROUTE.TRAVEL_HOSPITALITY} element={<TravelHospitality />} />
        <Route path={ROUTE.AUTOMOTIVE} element={<AutoMotive />} />
        <Route path={ROUTE.MEDIA_ENTERTAINMENT} element={<MediaEntertainment />} />
        <Route path={ROUTE.ERP} element={<ERP />} />
      </Routes>
    </>
  )
}


function App() {
  return (
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  );
}

export default App;
