import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import "../../CommonSCSS/common.scss";
import Footer from "../../Components/Desktop/Footer/Footer";
import Navbar from "../../Components/Desktop/Navbar/Navbar";
import MNavbar from "../../Components/Mobile/Navbar/MNavbar";
import useIsMobile from "../../SupportingFiles/MobileProvider";
import CaseStudyBox from "../HealthCare/CaseStudyBox/CaseStudyBox";
import RequestQuotationComponent from "../../Components/Common/RequestComponent/RequestQuotationComponent";
import "react-multi-carousel/lib/styles.css";
import RequestQuotation from "../../Components/Common/RequestQuotationPopup/RequestQuotation";
import CaseStudyModal, { caseType } from "../../Components/Common/CaseStudy/CaseStudyModal";
import CustomButton from "../../Components/Common/CustomButton/CustomButton";

function MediaEntertainment() {
  const isMobile = useIsMobile();
  const [open, setOpen] = useState<boolean>(false);
  const [openRequestQuotation, setOpenRequestQuotation] = useState<boolean>(false);
  useEffect(() => {
    if (openRequestQuotation) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [openRequestQuotation]);
  const [expertise, setExpertise] = useState([
    {
      boxcolorClass: "light-purple",
      image: "/images/me_bigdata_icon.png",
      heading: "Big Data",
      readmore: false,
      content:
        "Our solutions enhance user engagement and attention by collecting data, creating personalized media consumption profiles, and identifying ",
      fullContent:
        "Our solutions enhance user engagement and attention by collecting data, creating personalized media consumption profiles, and identifying behavioral patterns. This enables us to capture more data and gain a deeper understanding of the desires of important customers.",
    },
    {
      boxcolorClass: "light-green",
      image: "/images/me_ai_icon.png",
      heading: "Artificial Intelligence",
      readmore: false,
      content:
        "We utilize AI to streamline content creation, enhance targeted advertising, and facilitate improved discovery and recommendations.",
      fullContent:
        "We utilize AI to streamline content creation, enhance targeted advertising, and facilitate improved discovery and recommendations. By incorporating Big Data, our AI-integrated applications recognize customers' unique preferences, enabling them to explore the most engaging products, services, and content.",
    },
    {
      boxcolorClass: "light-orange",
      image: "/images/me_iot_icon.png",
      heading: "IoT -Internet of Things",
      readmore: false,
      content:
        "With the IoT solutions developed by Evervent teams, businesses have the ability to eliminate the obstacles associated with manually ",
      fullContent:
        "With the IoT solutions developed by Evervent teams, businesses have the ability to eliminate the obstacles associated with manually completing routine tasks. This allows their customers to allocate their time towards activities they truly enjoy, subsequently enhancing their engagement and satisfaction levels.",
    },
    {
      boxcolorClass: "light-pink",
      image: "/images/me_erp_icon.png",
      heading: "ERP for Media and Entertainment",
      readmore: false,
      content:
        "Our ERP solutions encompass a wide range of systems, from production management and planning to finance management, timekeeping and payroll,",
      fullContent:
        "Our ERP solutions encompass a wide range of systems, from production management and planning to finance management, timekeeping and payroll, statistics, and more. By offering these comprehensive solutions, we enable our clients to achieve various benefits, such as subscriber database growth, improved tracking of advertising performance, enhanced performance of media sales teams, and streamlined billing processes.",
    },
    {
      boxcolorClass: "light-yellow",
      image: "/images/me_crm_icon.png",
      heading: "CRM for Media and Entertainment",
      readmore: false,
      content:
        "CRM systems created by Evervent consist of key elements including a contact database, a sales automation program, a report generation ",
      fullContent:
        "CRM systems created by Evervent consist of key elements including a contact database, a sales automation program, a report generation system, a workflow automation system, an opportunity management and forecasting system, among others. With the addition of personalized features, the solutions we provide enable companies to enhance their sales, improve both customer and marketing services, and elevate their overall operational performance.",
    },
  ]);
  const handleReadMoreClick = (index: number) => {
    // Create a copy of the expertise array
    const updatedExpertise = [...expertise];

    // Toggle the readmore property for the clicked item
    updatedExpertise[index].readmore = !updatedExpertise[index].readmore;

    // Update the state with the modified array
    setExpertise(updatedExpertise);
  };
  const [craftingCustomSoftware, setCraftingCustomSoftware] = useState([
    {
      image: "./images/ccs_web_app_dev_icon.svg",
      heading: "Web Application Development",
      readmore: false,
      content:
        "Whether you need a single-page application (SPA), a progressive web application (PWA), or any other web-based platform developed",
      fullContent:
        "Whether you need a single-page application (SPA), a progressive web application (PWA), or any other web-based platform developed, our team is ready to assist. Our experts stay up-to-date with the latest trends in the media and entertainment industry, ensuring we can provide the perfect solution for your needs.",
    },
    {
      image: "./images/ccs_mobile_app_dev_icon.svg",
      heading: "Mobile Application Development",
      readmore: false,
      content:
        "The Evervent mobile application development team specializes in creating robust, reliable, and feature-rich applications that prioritize ",
      fullContent:
        "The Evervent mobile application development team specializes in creating robust, reliable, and feature-rich applications that prioritize a well-designed user experience and an intuitive user interface. Our team is dedicated to providing exceptional and Pioneering  experiences through the implementation of technologies such as , artificial intelligence (AI), internet of things (IoT), big data, and other popular mobile app technologies.",
    },
    {
      image: "./images/ccs_data_analytics_icon.svg",
      heading: "Data Analytics & Visualization Services",
      readmore: false,
      content:
        "At Evervent we provide our clients with the opportunity to utilize data analytics to improve their business processes, establish brand loyalty,",
      fullContent:
        "At Evervent we provide our clients with the opportunity to utilize data analytics to improve their business processes, establish brand loyalty, and boost engagement. Complemented by our data visualization services, our solutions offer a valuable chance to obtain visual representations of data.",
    },
    {
      image: "./images/ccs_cs_services_icon.svg",
      heading: "Cybersecurity Services",
      readmore: false,
      content:
        "Bearing in mind the criticality of data safety for businesses and their clients, our experts employ a variety of cutting-edge technologies ",
      fullContent:
        "Bearing in mind the criticality of data safety for businesses and their clients, our experts employ a variety of cutting-edge technologies and strategies to guarantee the utmost security in every solution provided to our valued clients. ",
    },
    {
      image: "./images/ccs_game_dev_icon.svg",
      heading: "Game Development Services",
      readmore: false,
      content:
        "Regardless of whether you need a fantasy sports software development, an e-learning gamified application, or a multi-level gaming platform ",
      fullContent:
        "Regardless of whether you need a fantasy sports software development, an e-learning gamified application, or a multi-level gaming platform, our teams at Evervent have the expertise to make your solution truly exceptional. By staying up-to-date with the latest trends in the gaming industry, we create applications that provide an unforgettable gaming experience, utilizing innovative technologies like augmented reality.",
    },
    {
      image: "./images/ccs_sm_dev_icon.svg",
      heading: "Social Media Platform Development",
      readmore: false,
      content:
        "Evervent, with a dedicated team of developers specializing in social media platforms, designs and develops web and mobile solutions with the purpose of connecting people ",
      fullContent:
        "Evervent, with a dedicated team of developers specializing in social media platforms, designs and develops web and mobile solutions with the purpose of connecting people, whether within an organization or across the world. Our social media platforms incorporate the latest trends in the media and entertainment industry, providing comprehensive features.",
    },
  ]);
  const handlecscsReadMoreClick = (index: number) => {
    const updatedCraftingCustomSoftware = [...craftingCustomSoftware];
    updatedCraftingCustomSoftware[index].readmore = !updatedCraftingCustomSoftware[index].readmore;
    setCraftingCustomSoftware(updatedCraftingCustomSoftware)
  }
  const [openCaseModal, setOpenCaseModal] = useState<boolean>(false);
  const [clickedCase, setClickedCase] = useState<caseType>({
    head: "",
    subHead: "",
    mainHeading: "",
    keywords: "",
    headPara: "",
    intro: "",
    challenge: "",
    solution: "",
    key: [],
    conclusion: "",
  });
  const caseStudies: caseType[] = [
    {
      head: "Big Data",
      subHead: "Revolutionizing Media Consumption with Big Data Insights",
      keywords: " Big Data, User Engagement, Personalized Profiles",
      mainHeading: "Enhancing User Engagement Through Big Data Analytics",
      headPara:
        "Unveiling Personalized Media Experiences, One Data Point at a Time  ",
      intro:
        "Evervent's foray into Big Data in the media and entertainment sector aimed to redefine user engagement. By harnessing data, creating personalized profiles, and identifying behavioral patterns, our goal was to provide a deeper understanding of customers' desires for a more immersive media experience.",
      challenge:
        "Traditional media lacks the tailored touch needed for modern audiences. The challenge was to leverage Big Data to create personalized experiences, capturing user preferences and behaviors to revolutionize how media is consumed.",
      solution:
        "Evervent crafted solutions that enhance user engagement through Big Data analytics. By creating personalized media consumption profiles, our applications deliver content that resonates with individual preferences, providing a more enriching and captivating experience.",
      key: [
        {
          heading: "Data-Driven Personalization",
          content: "Harnessing Big Data to understand user preferences.",
        },
        {
          heading: "Behavioral Pattern Recognition",
          content:
            "Identifying and adapting to user behaviors for tailored content delivery.",
        },
        {
          heading: "Immersive User Profiles",
          content:
            "Creating detailed and personalized media consumption profiles.",
        },
        {
          heading: "Content Recommendations",
          content:
            "AI-driven suggestions for a more personalized viewing experience.",
        },
        {
          heading: "Engagement Analytics",
          content:
            "Tracking user engagement to refine content delivery strategies.",
        },
        {
          heading: "Continuous Learning Algorithms",
          content: " Evolving recommendations based on user interactions.",
        },
      ],
      conclusion:
        "Through the power of Big Data, Evervent has transformed media consumption, providing users with a personalized and engaging experience that goes beyond traditional content delivery",
    },
    {
      head: "AI-Infused CRM",
      subHead: "Transforming Media Operations with AI-Infused CRM",
      keywords: "AI-Infused CRM, Media Operations, Personalized Features",
      mainHeading: " Elevating Media Business with AI-Enhanced CRM  ",
      headPara: "Personalized Connections, Elevated Operational Performance",
      intro:
        " Evervent delved into AI-infused CRM solutions to enhance media operations and connections. The aim was to create CRM systems that go beyond data, offering personalized features to elevate sales, marketing services, and overall operational performance.",
      challenge:
        "Traditional CRM systems lack the personal touch needed for the media and entertainment industry. The challenge was to integrate AI to create CRM solutions that not only manage data but also foster personalized connections with users.",
      solution:
        " Evervent's AI-infused CRM systems redefine how media businesses connect with their audience. With personalized features such as contact databases, workflow automation, and sales automation, our solutions enhance customer engagement and elevate operational efficiency.",
      key: [
        {
          heading: "Contact Database Personalization",
          content: "Tailoring contact databases for personalized connections.",
        },
        {
          heading: "Sales Automation",
          content:
            "Streamlining sales processes through AI-driven automation.",
        },
        {
          heading: "Workflow Automation",
          content:
            "Enhancing operational efficiency with automated workflows.",
        },
        {
          heading: "Opportunity Management",
          content: "Utilizing AI for forecasting and opportunity management.",
        },
        {
          heading: "Personalized Reports",
          content:
            "Generating reports with insights for improved decision-making.",
        },
        {
          heading: "Enhanced Customer Services",
          content:
            "Elevating both customer and marketing services through personalization.",
        },
      ],
      conclusion:
        "Evervent's AI-infused CRM solutions revolutionize how media businesses operate, offering personalized connections and streamlined operations for sustained success.",
    },
  ];
  
  return (
    <Box className="pagesWrapper">
      <RequestQuotation
        open={openRequestQuotation}
        setOpen={setOpenRequestQuotation}
        popup_heading=""
      />
      <CaseStudyModal
        open={openCaseModal}
        setOpen={setOpenCaseModal}
        item={clickedCase}
      />

      {isMobile ? <MNavbar /> : <Navbar open={open} setOpen={setOpen} />}

      <Box className="pagesHeader mb-20 mb-40-m">
        <Grid container columnSpacing={3}>
          <Grid xs={12} md={6} className="head">
            <h1 className="mb-6">
              Evervent's Tech Marvel:
              <span style={{ display: "block" }}>
                Transforming Media Landscape with Captivating App Solutions.
              </span>
            </h1>
            <p>
              At Evervent, we're not just pioneers in media and entertainment
              app development—we're passionate creators. We use the latest
              technology to craft custom software solutions that elevate media
              operations, open exciting new channels, infuse data with advanced
              analytics, transform user experiences, and skyrocket audience
              engagement. Join us on a journey to simplicity and captivation in
              every interaction.
            </p>
          </Grid>
          <Grid xs={12} md={6}>
            <img
              src="/images/media-entertainment.svg"
              alt="icon"
              style={{ margin: "auto" }}
            />
          </Grid>
        </Grid>
      </Box>

      <Box className="mediaEntertaiment-expertise mb-20 mb-40-m">
        <Grid
          container
          columnSpacing={3}
          marginBottom={"60px"}
          className="mb-40-m"
        >
          <Grid xs={12} md={6}>
            <h2>
              <span>Our expertise in the </span>
              realm of Media and Entertainment
            </h2>
          </Grid>
          <Grid xs={12} md={6}></Grid>
        </Grid>
        <Grid container columnSpacing={3} marginBottom={"60px"}>
          {expertise.map((data, index) => (
            <Grid xs={12} sm={6} lg={4}>
              <Box className={data.boxcolorClass}>
                <img src={data.image} alt="Image" className="mb-7" />
                <h4>{data.heading}</h4>
                <p className="mt-3">
                  {data.readmore ? (
                    <>{data.fullContent}</>
                  ) : (
                    <>
                      {data.content}{" "}
                      <a
                        className="read_more"
                        onClick={() => handleReadMoreClick(index)}
                      >
                        Read More
                      </a>
                    </>
                  )}
                </p>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>

      <Box className="crafting_custom_section mb-20 mb-40-m">
        <Grid container columnSpacing={3} marginBottom={"40px"}>
          <Grid xs={12} textAlign={"center"}>
            <h2 className="mb-6">
              Crafting custom software for media and entertainment needs is our
              forte
            </h2>
            <p>
              We provide a diverse range of services aimed at optimizing your
              workflow, and can seamlessly integrate into any stage of a
              project. Our core services include:
            </p>
          </Grid>
        </Grid>
        <Grid container columnSpacing={3}>
          {craftingCustomSoftware.map((data, index) => (
            <Grid xs={12} sm={6} lg={4}>
              <Box className="crafting_custtom_box">
                <Box className="iconbox">
                  <img src={data.image} alt="" />
                </Box>
                <h4>{data.heading}</h4>
                <p className="my-4">
                  {data.readmore ? (
                    <>{data.fullContent}</>
                  ) : (
                    <>
                      {data.content}
                      <a
                        className="read_more"
                        onClick={() => handlecscsReadMoreClick(index)}
                      >
                        {" "}
                        Read More
                      </a>
                    </>
                  )}
                </p>
                <Box className="ctaBtn">
                  <CustomButton
                    text_name={"Inquire"}
                    class_name="blueMdBtn"
                    onClickFunction={() => setOpenRequestQuotation(true)}
                  />
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>

      <Box className="case_study">
        <Grid container columnSpacing={3}>
          <Grid xs={12} className="mb-10" textAlign={"center"}>
            <h2 className="mb-3">Case Studies</h2>
            <p>
              In the realm of challenges, every case study is a chapter of
              solutions, each offering its unique wisdom to those who seek the
              knowledge it holds.
            </p>
          </Grid>
        </Grid>
        <Box className="case_bottom">
          <Grid
            container
            columnSpacing={3}
            justifyContent={"center"}
            rowGap={"24px"}
          >
            {caseStudies?.map((item) => (
              <Grid xs={6} sm={6} md={4} lg={3}>
                <CaseStudyBox
                  color="#79D05A"
                  title={item?.head}
                  description={item?.subHead}
                  onClickFunction={() => {
                    setOpenCaseModal(true);
                    setClickedCase(item);
                    console.log(item, "---");
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>

      <Box className="edu_quotation">
        <RequestQuotationComponent />
      </Box>

      <Footer />
    </Box>
  );
}


export default MediaEntertainment;
