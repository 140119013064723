import { Box, Paper, Fade } from "@mui/material";
import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import CustomButton from "../../Components/Common/CustomButton/CustomButton";
import RequestQuotation from "../../Components/Common/RequestQuotationPopup/RequestQuotation";

function WelcomeScreen() {
  const [counter, setCounter] = useState(1);
  const [pause, setPause] = useState(false);
  const [openRequestQuotation, setOpenRequestQuotation] =
    useState<boolean>(false);
  const content = [
    {
      heading: (
        <>
          Unleash your business's <span>Digital Transformation</span> potential
          Innovate, streamline & expand
        </>
      ),
      desc: "Driving Brand Expansion with a Decade of Technological Expertise and Innovative Experience",
      img_url: "./images/headerimg.svg",
    },
    {
      heading: (
        <>
          As We Ignite and Sustain Your Brand's <span> Digital Revolution</span>
          , We Pledge to Lead You Towards a Future of Limitless Achievement and
          Promise.
        </>
      ),
      desc: "Witness the Spectacle of Technological Brilliance made right for you with a strategic approach.",
      img_url: "./images/headerimg2.svg",
    },
    {
      heading: (
        <>
          Crafting Strategies that Go Beyond Clicks and Impressions to Forge{" "}
          <span>Strong Customer Connections</span> and Drive Sustainable Growth.
        </>
      ),
      desc: "Tech-Driven digital marketing for elevating customer engagement & lasting growth",
      img_url: "./images/headerimg3.svg",
    },
    {
      heading: (
        <>
          <span>Custom App Development</span> Unleashes Innovative Solutions,
          Empowering Your Business to Thrive in the Digital Age.
        </>
      ),
      desc: "Tech-Powered solutions, transforming ideas into innovative apps",
      img_url: "./images/headerimg4.svg",
    },
    {
      heading: (
        <>
          <span>Strategic Workforce Partner</span>, Providing You with Skilled
          Professionals, Enabling Your Team to Achieve Greater Efficiency and
          Success.
        </>
      ),
      desc: "Tech-Enhanced workforce, achieving excellence through reliant & experienced professionals.",
      img_url: "./images/headerimg5.svg",
    },
  ];

  const handleNext = () => {
    if (counter !== content.length) {
      setCounter(counter + 1);
    } else {
      setCounter(1);
    }
  };

  const handleMouse = () => {
    setPause(!pause);
  };

  useEffect(() => {
    let interval = setInterval(() => {
      if (!pause) {
        handleNext();
      } else {
        clearInterval(interval);
      }
    }, 3000);
    return () => clearInterval(interval);
  });

  return (
    <Box className="header mb-10">
      <RequestQuotation
        open={openRequestQuotation}
        setOpen={setOpenRequestQuotation}
        popup_heading=""
      />
      <Grid container rowSpacing={3}>
        {/* <Grid xs={12} textAlign={"center"}>
          <h1>
            Unleash your business's <span>Digital Transformation</span>{" "}
            potential: Innovate, streamline & expand
          </h1>
          <h6 className="mt-3">
            Driving Brand Expansion with a Decade of Technological Expertise and
            Innovative Experience
          </h6>
        </Grid> */}

        <Grid xs={12} textAlign={"center"}>
          <Box className="contentSlide">
            <div
              className="slide"
              onMouseEnter={handleMouse}
              onMouseLeave={handleMouse}
            >
              {content.map((item: any, index: any) => (
                <div
                  className={counter - 1 === index ? "show" : "not-show"}
                  key={index}
                >
                  <h1>{item.heading}</h1>
                  <h6 className="mt-3">{item.desc}</h6>
                  <div className="pt-3 pb-3">
                    <img src={item.img_url} className="mx-auto" alt="img" />
                  </div>
                </div>
              ))}
            </div>
          </Box>
        </Grid>

        {/* <Grid xs={12} textAlign={"center"}>
          <img src="./images/headerimg.svg" className="mx-auto" alt="img" />
        </Grid> */}
        <Grid xs={12} textAlign={"center"} className="ctaBtn">
          <CustomButton
            text_name="Request a Quotation"
            class_name="blackLgBtn"
            onClickFunction={()=> setOpenRequestQuotation(true)}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default WelcomeScreen;
