import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useState } from "react";
import "../../CommonSCSS/common.scss";
import Footer from "../../Components/Desktop/Footer/Footer";
import Navbar from "../../Components/Desktop/Navbar/Navbar";
import MNavbar from "../../Components/Mobile/Navbar/MNavbar";
import useIsMobile from "../../SupportingFiles/MobileProvider";
import CustomButton from "../../Components/Common/CustomButton/CustomButton";
import CaseStudyBox from "../HealthCare/CaseStudyBox/CaseStudyBox";
import RequestQuotationComponent from "../../Components/Common/RequestComponent/RequestQuotationComponent";
import RequestQuotation from "../../Components/Common/RequestQuotationPopup/RequestQuotation";
import CaseStudyModal, { caseType } from "../../Components/Common/CaseStudy/CaseStudyModal";

function Education() {
  const isMobile = useIsMobile();
  const [open, setOpen] = useState<boolean>(false);
  const [portVal, setPortVal] = useState<number>(1);
  const [openRequestQuotation, setOpenRequestQuotation] = useState<boolean>(false);
  const [openCaseModal, setOpenCaseModal] = useState<boolean>(false);
  const [clickedCase, setClickedCase] = useState<caseType>({
    head: "", subHead: "", mainHeading: "", keywords: "", headPara: "", intro: "",
    challenge: "", solution: "", key: [], conclusion: ""
  });
  const portfolio = [
    {
      id: 1,
      heading: "Professional Development App for Corporate Training",
      content: "Designed an app for corporate professionals, featuring bite-sized modules, simulations, and real-world case studies.",
      image: "/images/edu_port1.svg",
    },
    {
      id: 2,
      heading: "Language Learning App with AI Tutoring",
      content: "Created a language learning app incorporating AI-powered tutoring for personalized language lessons.",
      image: "/images/edu_port2.svg",
    },
    {
      id: 3,
      heading: "AR-enhanced Science Curriculum for Secondary Education",
      content: "Developed an Augmented Reality app that brings science concepts to life with 3D models, simulations, and virtual experiments.",
      image: "/images/edu_port3.svg",
    },
    {
      id: 4,
      heading: "VR Virtual Campus for Higher Education",
      content: "Designed a Virtual Reality campus for higher education institutions, offering immersive lectures, virtual libraries, and collaborative study spaces.",
      image: "/images/edu_port4.svg",
    },
    {
      id: 5,
      heading: "Skill Development App for Vocational Training",
      content: "Created a vocational training app with practical, hands-on modules for skill development.",
      image: "/images/edu_port5.svg",
    },
    {
      id: 6,
      heading: "E-Learning Marketplace for Specialized Courses",
      content: "Developed a platform for instructors to create and sell specialized courses to a global audience.",
      image: "/images/edu_port6.svg",
    },
    {
      id: 7,
      heading: "Parent-Teacher Communication App for Primary Schools",
      content: "Designed a communication app for parents and teachers to stay connected regarding student progress, events, and announcements.",
      image: "/images/edu_port7.svg",
    },
    {
      id: 8,
      heading: "STEM Education App for Interactive Labs",
      content: "Built an app for STEM education, featuring virtual interactive labs and simulations.",
      image: "/images/edu_port8.svg",
    },
    {
      id: 9,
      heading: "Adaptive Learning App for Higher Ed",
      content: "Created an adaptive learning platform for higher education institutions, offering personalized learning paths based on individual student performance.",
      image: "/images/edu_port9.svg",
    },
  ]
  const activePort = portfolio.filter((item) => item.id === portVal)?.[0];
  const caseStudies: caseType[] = [
    {
      head: "Beyond Boundaries", subHead: "A Tale of Educational Transformation through Comprehensive LMS Enhancement",
      mainHeading: "Beyond Boundaries: A Tale of Educational Transformation through Comprehensive LMS Enhancement",
      keywords: "Educational Transformation, Learning Management System (LMS), Web and Mobile Development, Enterprise Solutions, Third-Party Integration, Modernization of LMS, Challenges in Education Sector, Mobile Accessibility, Seamless Integration, User Experience (UI/UX), Data Analytics, Responsive Design",
      headPara: "Breaking Barriers: A Strategic Blend of Web & Mobile Development, Enterprise Solutions, and Third-Party Integration Redefines the Learning Experience",
      intro: "In the rapidly evolving landscape of education and corporate training, the implementation of a robust Learning Management System (LMS) is pivotal. This case study delves into the challenges faced by a client in the education sector and outlines our comprehensive solution, which included the development of both web and mobile LMS, enterprise-level features, third-party integrations, and the modernization of their existing LMS.",
      challenge: "Our client, a leading educational institution, faced challenges in managing and delivering their diverse range of courses efficiently. Their existing LMS was outdated, lacked mobile accessibility, and struggled with seamless integration of third-party tools. The need for an enterprise-level solution that could adapt to the modern learning environment was evident.",
      solution: "We revamped outdated LMS, making it accessible on both web and mobile devices for a consistent user experience. Our enterprise-grade solution ensures scalability for a growing user base and diverse courses. We seamlessly integrated popular tools, enhancing functionality. Modernization brought the LMS up-to-date with the latest tech and improved UI/UX, including data analytics for smarter decisions. The result? A transformed learning environment, ready for the future.",
      key: [
        { heading: "Responsive Design", content: "Ensured accessibility across devices, offering a consistent experience on both web and mobile platforms." },
        { heading: "Scalability", content: "Built an enterprise-grade LMS capable of handling a growing user base and diverse course content." },
        { heading: "Third-Party Integrations", content: "Seamlessly integrated with popular tools and services, enhancing functionality and user experience." },
        { heading: "LMS Modernization", content: "Upgraded the LMS with the latest technologies, improved UI/UX, and incorporated data analytics for informed decision-making." },
        { heading: "Web & Mobile LMS Development", content: "We designed and developed a responsive web-based LMS accessible across devices, ensuring a seamless learning experience. Additionally, we crafted a mobile application to cater to the on-the-go needs of learners, fostering flexibility and accessibility." },
        { heading: "Enterprise LMS Development", content: "Understanding the scale and diversity of our client's courses, we engineered an enterprise-level LMS. This involved creating a scalable architecture to accommodate a growing user base and a wide array of course materials." },
      ],
      conclusion: "Through the successful implementation of a comprehensive solution, our client witnessed a transformative shift in their learning environment. The newly developed LMS not only addressed their immediate challenges but also positioned them to adapt to future trends in education technology. The integration of web and mobile solutions, enterprise features, and third-party tools created a versatile and dynamic learning ecosystem, ensuring the institution's continued success in providing quality education."
    },
    {
      head: "Unlocking Knowledge Anytime, Anywhere", subHead: "Evervent's m-Learning Marvel Redefines Education for a Dynamic Future",
      mainHeading: "Unlocking Knowledge Anytime, Anywhere: Evervent's m-Learning Marvel Redefines Education for a Dynamic Future",
      keywords: "m-Learning, Educational Technology, Mobile Learning Applications, Immersive Environments, Interactive Learning Tools, Real-time Collaboration, Adaptive Learning Paths, AI Algorithms, Comprehensive Assessment Tools, Transformative Learning Experience, Educational Landscape, Remote Education Solutions, Trailblazing, Paradigm Shift, Future of Education, Digital Learning, Virtual Environments, Educational Innovation, Continuous Improvement Cycle, Education Technology",
      headPara: "Unlocking Knowledge Anytime, Anywhere: Evervent's m-Learning Marvel Redefines Education for a Dynamic Future",
      intro: "Evervent, a trailblazing educational technology company, embarked on a groundbreaking journey to revolutionize traditional learning experiences by introducing Mobile Learning (m-Learning) applications. Recognizing the evolving needs of the education sector, Evervent set out to create a cutting-edge m-Learning platform that transcends the boundaries of conventional learning.",
      challenge: "Evervent identified key challenges within traditional classrooms, including limited engagement, difficulty catering to diverse learning styles, and the growing demand for remote education solutions. The challenge was not just to replicate the classroom experience but to enhance it, making learning more accessible, interactive, and adaptable to the individual needs of students.",
      solution: "Evervent's solution was the development and implementation of an immersive m-Learning platform. This platform aimed to transcend the limitations of physical classrooms, providing students with a captivating and interactive learning environment. By leveraging m-Learning technology, Evervent sought to create a holistic solution that embraced the future of education.",
      key: [
        { heading: "Immersive Environments", content: "Evervent's m-Learning platform transported students into lifelike virtual environments, allowing them to explore subjects in a three-dimensional space. This immersive experience made complex concepts more digestible and engaging." },
        { heading: "Interactive Learning Tools", content: "The platform featured a suite of interactive tools, including 3D models, simulations, and hands-on activities. Students could manipulate virtual objects, conduct experiments, and participate in interactive lessons, fostering a deeper understanding of the curriculum." },
        { heading: "Real-time Collaboration", content: "Evervent prioritized real-time collaboration, enabling students and educators to interact seamlessly within the virtual space. Virtual study groups, collaborative projects, and interactive discussions became integral components of the learning experience." },
        { heading: "Adaptive Learning Paths", content: "The platform incorporated AI algorithms to assess individual learning styles and progress. This adaptive learning approach ensured that each student received personalized content, optimizing the educational journey for diverse needs." },
        { heading: "Comprehensive Assessment Tools", content: "Evervent's platform included robust assessment and feedback tools. Educators could track student performance in real time, providing immediate feedback and facilitating a continuous improvement cycle." },
      ],
      conclusion: " Evervent's m-Learning platform has redefined the educational landscape, offering a transformative learning experience. The integration of immersive environments, interactive tools, and adaptive learning paths has not only addressed the challenges of traditional classrooms but has set a new standard for education technology."
    },
  ]

  return (
    <Box className="pagesWrapper">
      <RequestQuotation
        open={openRequestQuotation}
        setOpen={setOpenRequestQuotation}
        popup_heading=""
      />

      <CaseStudyModal
        open={openCaseModal}
        setOpen={setOpenCaseModal}
        item={clickedCase}
      />

      {isMobile ? <MNavbar /> : <Navbar open={open} setOpen={setOpen} />}

      <Box className="edu_header">
        <Grid container spacing={3}>
          <Grid xs={12} md={7} lg={6} className="head">
            <h1>
              Evervant EduCraft: <span>Where Learning Meets Limitlessness</span>
            </h1>
            <p>
              Dive into seamless learning with Evervant's custom eLearning
              solutions. We redefine engagement and outcomes for educators and
              students. Here's how we conquer your Education and eLearning
              software challenges
            </p>
            <Box className="ctaBtn">
              <CustomButton text_name={"Get Started"} class_name="blackLgBtn" onClickFunction={()=>setOpenRequestQuotation(true)} />
            </Box>
          </Grid>
          <Grid
            xs={12}
            md={5}
            lg={6}
            display={"flex"}
            justifyContent={"center"}
          >
            <img src="/images/education_header.svg" alt="con" />
          </Grid>
        </Grid>
      </Box>

      <Box className="edu_service">
        <Grid container columnSpacing={3}>
          <Grid xs={12} className="head mb-10" textAlign={"center"}>
            <h2 className="mb-3">
              <span>Key </span>Services
            </h2>
            <p>We are ready with that whatever you want</p>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid xs={12} sm={6} lg={4}>
            <Box className="content_box">
              <img src="/images/service_edu1.svg" alt="lor" />
              <h5>Learning Management System (LMS)</h5>
              <p>
                LMS is a tool for managing education. It's developed for web and
                mobile, customized for big companies, integrates with other
                tools, and is regularly updated for efficiency.
              </p>
              <Box className="ctaBtn">
                <CustomButton
                  text_name={"Inquire"}
                  class_name="blueMdBtn"
                  onClickFunction={() => setOpenRequestQuotation(true)}
                />
              </Box>
            </Box>
          </Grid>
          <Grid xs={12} sm={6} lg={4}>
            <Box className="content_box">
              <img src="/images/service_edu2.svg" alt="lor" />
              <h5>Student Lifecycle Management</h5>
              <p>
                A comprehensive guide from enrollment to graduation! It's like
                the GPS for academic journeys, ensuring students don't get lost
                in the labyrinth of paperwork.
              </p>
              <Box className="ctaBtn">
                <CustomButton
                  text_name={"Inquire"}
                  class_name="blueMdBtn"
                  onClickFunction={() => setOpenRequestQuotation(true)}
                />
              </Box>
            </Box>
          </Grid>
          <Grid xs={12} sm={6} lg={4}>
            <Box className="content_box">
              <img src="/images/service_edu3.svg" alt="lor" />
              <h5>Candidate and Result Data Capturing</h5>
              <p>
                It's like building a digital record of candidates and their
                achievements—key for making informed choices in hiring or
                academic assessments.
              </p>
              <Box className="ctaBtn">
                <CustomButton
                  text_name={"Inquire"}
                  class_name="blueMdBtn"
                  onClickFunction={() => setOpenRequestQuotation(true)}
                />
              </Box>
            </Box>
          </Grid>
          <Grid xs={12} sm={6} lg={4}>
            <Box className="content_box">
              <img src="/images/service_edu4.svg" alt="lor" />
              <h5>e-Learning Portal</h5>
              <p>
                An e-learning portal is your digital gateway to courses and
                educational content, offering a user-friendly online environment
                for effective learning and skill development.
              </p>
              <Box className="ctaBtn">
                <CustomButton
                  text_name={"Inquire"}
                  class_name="blueMdBtn"
                  onClickFunction={() => setOpenRequestQuotation(true)}
                />
              </Box>
            </Box>
          </Grid>
          <Grid xs={12} sm={6} lg={4}>
            <Box className="content_box">
              <img src="/images/service_edu5.svg" alt="lor" />
              <h5>m-Learning Applications</h5>
              <p>
                M-learning apps are like pocket classrooms, letting you learn on
                your phone anytime, anywhere.
              </p>
              <Box className="ctaBtn">
                <CustomButton
                  text_name={"Inquire"}
                  class_name="blueMdBtn"
                  onClickFunction={() => setOpenRequestQuotation(true)}
                />
              </Box>
            </Box>
          </Grid>
          <Grid xs={12} sm={6} lg={4}>
            <Box className="content_box">
              <img src="/images/service_edu6.svg" alt="lor" />
              <h5>Virtual Classroom</h5>
              <p>
                Facilitating virtual classrooms and video-based lessons for
                convenient remote learning.
              </p>
              <Box className="ctaBtn">
                <CustomButton
                  text_name={"Inquire"}
                  class_name="blueMdBtn"
                  onClickFunction={() => setOpenRequestQuotation(true)}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box className="edu_portfolio">
        <Grid container columnSpacing={3}>
          <Grid xs={12} className="head mb-10" textAlign={"center"}>
            <h2 className="mb-3">
              <span>Unveiling Our </span>Impressive App Portfolio
            </h2>
            <p>
              Evervent opens the door to a realm of educational possibilities.
              Join us in unveiling our impressive app portfolio, carefully
              curated to redefine the way you learn and excel.
            </p>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid xs={12} sm={12} md={12} lg={4}>
            <Box className="edu_aproach">
              {
                <>
                  <h4>{activePort.heading}</h4>
                  <p>{activePort.content}</p>
                </>
              }
            </Box>
          </Grid>
          <Grid xs={12} sm={12} md={6} lg={4}>
            <Box className="edu_aproach" alignItems={"center"}>
              <img src={activePort.image} alt="pp" />
            </Box>
          </Grid>
          <Grid
            xs={12}
            sm={12}
            md={6}
            lg={4}
            display={"flex"}
            alignItems={"center"}
          >
            <Box className="points">
              {portfolio?.map((item, i) => (
                <Box
                  className={`point_section ${
                    activePort.id === i + 1 ? "active" : ""
                  }`}
                  onClick={() => {
                    setPortVal(item.id);
                  }}
                >
                  <div className="number">
                    <p className="count">0{item.id}</p>
                    <div className="line1"></div>
                  </div>
                  <p className="heading">{item.heading}</p>
                </Box>
              ))}
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box className="case_study">
        <Grid container columnSpacing={3}>
          <Grid xs={12} className="mb-10" textAlign={"center"}>
            <h2 className="mb-3"> Case Studies </h2>
            <p>
              In the realm of challenges, every case study is a chapter of
              solutions, each offering its unique wisdom to those who seek the
              knowledge it holds.
            </p>
          </Grid>
        </Grid>
        <Box className="case_bottom">
          <Grid
            container
            columnSpacing={3}
            justifyContent={"center"}
            rowGap={"24px"}
          >
            {caseStudies?.map((item) => (
              <Grid xs={6} sm={6} md={4} lg={3}>
                <CaseStudyBox
                  color="#79D05A"
                  title={item?.head}
                  description={item?.subHead}
                  onClickFunction={() => {
                    setOpenCaseModal(true);
                    setClickedCase(item);
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>

      <Box className="edu_quotation">
        <RequestQuotationComponent />
      </Box>

      <Footer />
    </Box>
  );
}

export default Education;


