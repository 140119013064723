import React, { useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Button, Link, Menu, MenuItem } from "@mui/material";
import "./Navbar.scss";
import CustomButton from "../../Common/CustomButton/CustomButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useNavigate } from "react-router-dom";
import { ROUTE } from "../../../Services/Route/Route";
import RequestQuotation from "../../Common/RequestQuotationPopup/RequestQuotation";

function Navbar({ open, setOpen }: { open: boolean; setOpen: any }) {
  const navigate = useNavigate();
  const [insuranceMenuAnchorEl, setInsuranceMenuAnchorEl] =
    useState<null | HTMLElement>(null);
  const handleInsuranceMenuClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    document.body.style.overflow = "scroll";
    setInsuranceMenuAnchorEl(event.currentTarget);
    
  };
  const handleMenuClose = () => {
    setInsuranceMenuAnchorEl(null);
  };

  const [insuranceMenuAnchorE2, setInsuranceMenuAnchorE2] =
    useState<null | HTMLElement>(null);
  const handleInsuranceMenuClick2 = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setInsuranceMenuAnchorE2(event.currentTarget);
  };
  const handleMenuClose2 = () => {
    setInsuranceMenuAnchorE2(null);
  };

  const [insuranceMenuAnchorE3, setInsuranceMenuAnchorE3] =
    useState<null | HTMLElement>(null);
  const handleInsuranceMenuClick3 = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setInsuranceMenuAnchorE3(event.currentTarget);
  };
  const handleMenuClose3 = () => {
    setInsuranceMenuAnchorE3(null);
  };

  const [openRQuote, setOpenRquote] = useState(false);

  return (
    <Box className="navbar">
      <RequestQuotation
        open={openRQuote}
        setOpen={setOpenRquote}
        popup_heading="Get In Touch"
      />
      <img
        src="./images/evervent-logo.svg"
        className="logo"
        alt="evervent"
        style={{ cursor: "pointer" }}
        onClick={() => {
          navigate(ROUTE.HOME);
        }}
      />
      <ul>
        {/* industries dropdown */}
        <li>
          <Button
            aria-controls="insurance-menu"
            aria-haspopup="true"
            onClick={handleInsuranceMenuClick}
            className="dropdown_menu_btn"
          >
            Industry <KeyboardArrowDownIcon />
          </Button>
          <Menu
            id="insurance-menu"
            anchorEl={insuranceMenuAnchorEl}
            open={Boolean(insuranceMenuAnchorEl)}
            onClose={handleMenuClose}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.2))",
                mt: 1.5,
                minHeight: "310px",
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            MenuListProps={{
              "aria-labelledby": "insurance-button",
            }}
          >
            <MenuItem
              onClick={() => {
                navigate(ROUTE.HEALTH_CARE);
                handleMenuClose();
              }}
            >
              Healthcare
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate(ROUTE.WELLNESS_FITNESS);
                handleMenuClose();
              }}
            >
              Wellness & Fitness
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate(ROUTE.RETAIL);
                handleMenuClose();
              }}
            >
              Retail & E-Commerce
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate(ROUTE.TRAVEL_HOSPITALITY);
                handleMenuClose();
              }}
            >
              Travel & Hospitality
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate(ROUTE.TRANSPORTATION);
                handleMenuClose();
              }}
            >
              Transportation & Logistics
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate(ROUTE.AUTOMOTIVE);
                handleMenuClose();
              }}
            >
              Automotive
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate(ROUTE.MEDIA_ENTERTAINMENT);
                handleMenuClose();
              }}
            >
              Media and Entertainment
            </MenuItem>
            <MenuItem
              onClick={() => window.open("https://insurvent.com/", "_blank")}
            >
              Insurance
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate(ROUTE.EDUCATION);
                handleMenuClose();
              }}
            >
              Education & E-Learning
            </MenuItem>
          </Menu>
        </li>

        {/* products dropdown */}
        <li>
          <Button
            aria-controls="insurance-menu"
            aria-haspopup="true"
            onClick={handleInsuranceMenuClick2}
            className="dropdown_menu_btn"
          >
            Products <KeyboardArrowDownIcon />
          </Button>
          <Menu
            id="insurance-menu"
            anchorEl={insuranceMenuAnchorE2}
            open={Boolean(insuranceMenuAnchorE2)}
            onClose={handleMenuClose2}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.2))",
                mt: 1.5,
                minHeight: "80px",
                minWidth:'120px',
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            MenuListProps={{
              "aria-labelledby": "insurance-button",
            }}
          >
            <MenuItem onClick={()=>navigate(ROUTE.ERP)}>
              ERP
            </MenuItem>
            <MenuItem onClick={handleMenuClose2} disabled>
              LMS
            </MenuItem>
          </Menu>
        </li>

        {/* expertise dropdown */}
        <li>
          <Button
            aria-controls="insurance-menu"
            aria-haspopup="true"
            onClick={handleInsuranceMenuClick3}
            className="dropdown_menu_btn"
          >
            Expertise <KeyboardArrowDownIcon />
          </Button>
          <Menu
            id="insurance-menu"
            anchorEl={insuranceMenuAnchorE3}
            open={Boolean(insuranceMenuAnchorE3)}
            onClose={handleMenuClose3}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.2))",
                mt: 1.5,
                minHeight: "220px",
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            MenuListProps={{
              "aria-labelledby": "insurance-button",
            }}
          >
            <MenuItem onClick={handleMenuClose2} disabled>
              Blockchain Development
            </MenuItem>
            <MenuItem onClick={handleMenuClose2} disabled>
              AI-Powered App Builder
            </MenuItem>
            <MenuItem onClick={() => navigate(ROUTE.GROWTH_MARKETING)}>
              Growth & Marketing
            </MenuItem>
            <MenuItem onClick={() => navigate(ROUTE.DESIGN_STUDIO)}>
              Design Studio
            </MenuItem>
            <MenuItem
              onClick={() => {
                navigate(ROUTE.PRODUCT_DEVELOPMENT);
              }}
            >
              Product Development
            </MenuItem>
            <MenuItem onClick={() => navigate(ROUTE.IT_CONSULTING)}>
              IT Consulting & Staff Augmentation
            </MenuItem>
          </Menu>
        </li>
        {/* <li>
          <Link
            onClick={() => {
              navigate(ROUTE.PRODUCT_DEVELOPMENT);
            }}
          >
            Product Development
          </Link>
        </li>
        <li>
          <Link onClick={() => navigate(ROUTE.DESIGN_STUDIO)}>
            Design Studio
          </Link>
        </li>
        <li>
          <Link onClick={() => navigate(ROUTE.GROWTH_MARKETING)}>
            Growth & Marketing
          </Link>
        </li>
        <li>
          <Link onClick={() => navigate(ROUTE.IT_CONSULTING)}>
            IT Consulting & Staff Augmentation
          </Link>
        </li> */}

        <li>
          <Link
            onClick={() => {
              navigate(ROUTE.ABOUT);
            }}
          >
            About us
          </Link>
        </li>

        <li>
          <Link
            onClick={() => {
              navigate(ROUTE.CAREER);
            }}
          >
            Career
          </Link>
        </li>

        <li className="ctaBtn">
          <CustomButton
            text_name="Get In Touch"
            class_name="yellowMdBtn"
            onClickFunction={() => {
              setOpenRquote(true);
            }}
          />
        </li>
      </ul>
    </Box>
  );
}

export default Navbar;
