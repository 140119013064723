import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useState } from "react";
import { toast } from "react-toastify";
import CustomButton from "../../../Components/Common/CustomButton/CustomButton";
import RKTextField from "../../../Components/Common/FieldTypes/RKTextField/RKTextField";
import SearchDropdown from "../../../Components/Common/FieldTypes/SearchDropdown/SearchDropdown";
import TextArea from "../../../Components/Common/FieldTypes/TextArea/TextArea";
import Footer from "../../../Components/Desktop/Footer/Footer";
import Navbar from "../../../Components/Desktop/Navbar/Navbar";
import MNavbar from "../../../Components/Mobile/Navbar/MNavbar";
import { FORM_SERVICES } from "../../../Services/FormServices";
import { countryCodeOption } from "../../../Services/Type/Home/THomePage";
import { internationalMobileNumber, isEmpty, validateEmail } from "../../../SupportingFiles/HelpingFunctions";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import "../../CmsPages/CmsPages.scss";

function Contactus() {
  const isMobile = useIsMobile();
  const [open, setOpen] = useState<boolean>(false);
  const [quoteFields, setQuoteFields] = useState<{
    name: { value: string; warning: boolean };
    subject: { value: string; warning: boolean };
    country: { value: string; warning: boolean };
    mobile: { value: string; warning: boolean };
    email: { value: string; warning: boolean };
    company: { value: string; warning: boolean };
    description: { value: string; warning: boolean };
  }>({
    name: { value: "", warning: false },
    subject: { value: "", warning: false },
    country: { value: "", warning: false },
    mobile: { value: "", warning: false },
    email: { value: "", warning: false },
    company: { value: "", warning: false },
    description: { value: "", warning: false },
  });

  const fieldsUpdateState = (attrName: any, value: any) => {
    setQuoteFields({
      ...quoteFields, [attrName[0]]: {
        value: value, warning: isEmpty(value)
      }
    })
  };
  const validate = () => {
    let data = { ...quoteFields }
    data = {
      ...data,
      name: { ...data.name, warning: isEmpty(data.name.value) },
      subject: { ...data.subject, warning: isEmpty(data.subject.value) },
      company: { ...data.company, warning: isEmpty(data.company.value) },
      country: { ...data.country, warning: isEmpty(data.country.value) },
      mobile: { ...data.mobile, warning: !internationalMobileNumber(data.mobile.value, data.country.value) },
      email: { ...data.email, warning: !validateEmail(data.email.value) },
      description: { ...data.description, warning: isEmpty(data.description.value) }
    }
    setQuoteFields({ ...data })

    let hasError = false;
    Object.values(data)?.map((item) => {
      if (item.warning) hasError = true;
    })

    if (!hasError) {
      FormSubmit();
    }
  }

  const FormSubmit = () => {
    const onSuccess = (res: any) => {
      const results = res.results;
      if (results.error === false) {
        toast.success(results.response)
        setQuoteFields({
          name: { value: "", warning: false },
          subject: { value: "", warning: false },
          company: { value: "", warning: false },
          country: { value: "", warning: false },
          mobile: { value: "", warning: false },
          email: { value: "", warning: false },
          description: { value: "", warning: false },
        })
      } else {
        // console.log(results)
        toast.error(results.message)
      }
    }
    const onError = (err: any) => {
      console.log("err", err)
    }
    let dto = {
      name: quoteFields.name.value,
      mobile: quoteFields.mobile.value,
      title: quoteFields.subject.value,
      company_name: quoteFields.company.value,
      company_email: quoteFields.email.value,
      description: quoteFields.description.value,
      country_code: quoteFields.country.value,
    }
    FORM_SERVICES.QuotationFormService(onSuccess, onError, dto)
  }

  return (
    <>
      {isMobile ? <MNavbar /> : <Navbar open={open} setOpen={setOpen} />}
      <Box className="cms_wrapper contact_top">
        <Box className="contact_header">
          <Grid container spacing={3}>
            <Grid
              xs={12}
              sm={12}
              md={6}
              lg={6}
              margin={"auto"}
              textAlign={"center"}
            >
              <h5>
                Contact <span>Us</span>
              </h5>
              <p>
                We are re-imagining the future of Fintech & Digital Platforms by
                building Disruptive technology
              </p>
            </Grid>
          </Grid>
          <Grid container spacing={3} className="contact_box">
            <Grid xs={12} sm={12} md={6} lg={6}>
              <Box className="cont_leftSection">
                <Grid container spacing={3}>
                  <Grid xs={12}>
                    <RKTextField
                      class_name="inputField"
                      title={"Name"}
                      value={quoteFields.name.value}
                      attrName={["name"]}
                      value_update={fieldsUpdateState}
                      warn_status={quoteFields.name.warning}
                      error_messg={" Enter Name"}
                    />
                  </Grid>
                  <Grid xs={12}>
                    <RKTextField
                      class_name="inputField"
                      title={"Subject"}
                      value={quoteFields.subject.value}
                      attrName={["subject"]}
                      value_update={fieldsUpdateState}
                      warn_status={quoteFields.subject.warning}
                      error_messg={" Enter Subject"}
                    />
                  </Grid>
                  <Grid xs={12} className="searchSelect">
                    <SearchDropdown
                      class_name="inputField"
                      title={"Country"}
                      value={quoteFields.country.value}
                      attrName={["country"]}
                      value_update={fieldsUpdateState}
                      warn_status={quoteFields.country.warning}
                      error_message={" Please select country code"}
                      data={countryCodeOption}
                    />
                  </Grid>
                  <Grid xs={12}>
                    <RKTextField
                      class_name="inputField"
                      title={"Phone Number"}
                      value={quoteFields.mobile.value}
                      attrName={["mobile"]}
                      value_update={fieldsUpdateState}
                      warn_status={quoteFields.mobile.warning}
                      error_messg={quoteFields.mobile.value?.length ? " Enter valid Mobile" : " Enter Mobile"}
                      max_length={15}
                      validation_type="NUMBER"
                    />
                  </Grid>
                  <Grid xs={12}>
                    <RKTextField
                      class_name="inputField"
                      title={"Organization/Company"}
                      value={quoteFields.company.value}
                      attrName={["company"]}
                      value_update={fieldsUpdateState}
                      warn_status={quoteFields.company.warning}
                      error_messg={" Enter Oragnization/Company"}
                    />
                  </Grid>
                  <Grid xs={12}>
                    <RKTextField
                      class_name="inputField"
                      title={"Email ID"}
                      value={quoteFields.email.value}
                      attrName={["email"]}
                      value_update={fieldsUpdateState}
                      warn_status={quoteFields.email.warning}
                      error_messg={quoteFields.email.value?.length ? " Enter valid Email" : " Enter Email"}
                    />
                  </Grid>
                  <Grid xs={12} className="textDesc">
                    <TextArea
                      class_name="inputField"
                      title={"Description"}
                      value={quoteFields.description.value}
                      attrName={["description"]}
                      value_update={fieldsUpdateState}
                      warn_status={quoteFields.description.warning}
                      error_messg={" Enter description"}
                    />
                  </Grid>
                  <Grid xs={12} textAlign="center" className="ctaBtn">
                    <CustomButton text_name="Submit" class_name="blueLgBtn"
                      onClickFunction={validate} />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid xs={12} sm={12} md={6} lg={6}>
              <Box className="cont_rightSection">
                <img src="./images/contactus_img.svg" alt="" height={"250px"} />
                <ul className="social_sites">
                  <li className="phone">
                    <Box>
                      <h6>Phone</h6>
                      <p>
                        +91 62399 21246 (HR) +91 97813 71136 +91 96250 70315
                        +017235 04600
                      </p>
                    </Box>
                  </li>
                  <li className="address">
                    <Box>
                      <h6>Address</h6>
                      <p>#341, 4th Floor, Phase - 9, Mohali, Punjab, 160062</p>
                    </Box>
                  </li>
                  <li className="social">
                    <Box>
                      <h6>Social Media</h6>
                      <Link
                        href="https://in.linkedin.com/company/evervent1"
                        target="_blank"
                      >
                        Linkedin,
                      </Link>
                      <Link
                        href="https://www.facebook.com/everventindia"
                        target="_blank"
                      >
                        Facebook,
                      </Link>
                      <Link
                        href="https://www.instagram.com/accounts/login/?next=/evervent_/"
                        target="_blank"
                      >
                        Instagram
                      </Link>
                    </Box>
                  </li>
                </ul>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Footer />
    </>
  );
}

export default Contactus;
