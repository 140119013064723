import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import FAQ from "../../Components/Common/FAQ/FAQ";

function FAQs() {
  const faqs = [
    {
      expanded: "panel1",
      title: "What industries do you serve?",
      description:
        "We serve all industries by offering expertise in custom app and software development tailored to your specific needs. Whether you're in healthcare, finance, or any other sector, we provide guidance and solutions to help you achieve your goals through technology.",
    },
    {
      expanded: "panel2",
      title: "Do you offer ongoing support and maintenance?",
      description:
        "Yes, we offer ongoing support and maintenance services for custom app and software development across all industries. Our commitment extends beyond the initial development phase, ensuring that your solutions stay up-to-date, secure, and optimized for your evolving needs.",
    },
    {
      expanded: "panel3",
      title: "How long does the development process take?",
      description:
        "The development process duration can vary depending on the complexity and scope of your custom app or software project. However, we prioritize efficiency and can work to deliver solutions promptly, meeting your industry's specific requirements while minimizing time-to-market.",
    },
    {
      expanded: "panel4",
      title: "Can you integrate with existing system?",
      description:
        "Absolutely, we have the agility and expertise to seamlessly integrate our custom app and software solutions with your existing core systems in any industry. Our team ensures a smooth integration process, enhancing the efficiency and functionality of your technology stack while preserving data integrity and security.",
    },
  ];
  return (
    <Grid container spacing={3} alignItems="center">
      <Grid xs={12} textAlign={"center"}>
        <h2 className="mb-4">Tailored Solutions for Every Business</h2>
      </Grid>
      <Grid xs={12} md={5}>
        <img src="./images/faqs.svg" width="100%" />
      </Grid>
      <Grid xs={12} md={7}>
        <FAQ data={faqs} />
      </Grid>
    </Grid>
  );
}

export default FAQs;
