import React from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

interface Props {
  title: string;
  value: any;
  attrName: any;
  value_update: Function;
  dropdown_data: Array<any>;
  warn_status?: boolean;
  class_name?: string;
  error_messg?: string;
  info_status?: boolean;
  info_message?: string;
  sx?: any;
  disabled?: boolean;
}

const defaultProps: Props = {
  title: "",
  value: "",
  attrName: "",
  warn_status: false,
  value_update: () => {},
  dropdown_data: [],
  error_messg: "select",
  info_status: false,
  info_message: "This is dummy text for select dropdown.",
  sx: "",
  disabled: false,
};

const SelectDropdown: React.FC<Props> = ({
  title,
  value,
  value_update,
  attrName,
  dropdown_data,
  warn_status,
  class_name,
  error_messg,
  info_status,
  info_message,
  sx,
  disabled,
}) => {
  return (
    <div className={class_name} style={{ ...sx }}>
      <FormControl fullWidth>
        <InputLabel>{title}</InputLabel>
        <Select
          disabled={disabled}
          value={value}
          label={title}
          onChange={(e) => value_update(attrName, e.target.value)}
        >
          {dropdown_data.map((data) => (
            <MenuItem value={data.label}>{data.value}</MenuItem>
          ))}
        </Select>
      </FormControl>
      {info_status === true ? (
        <Tooltip title={info_message ?? ""}>
          <span className="infoMessage">
            <InfoOutlinedIcon />
          </span>
        </Tooltip>
      ) : null}
      {warn_status ? <span className="error">{error_messg}</span> : null}
    </div>
  );
};

SelectDropdown.defaultProps = defaultProps;

export default SelectDropdown;
