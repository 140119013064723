export const ROUTE = {
  HOME: "/",
  HEALTH_CARE: "/health-care",
  CAREER: "/career",
  ABOUT: "/about",
  CONTACT: "/contact",
  WELLNESS_FITNESS: "/wellness-fitness",
  PRODUCT_DEVELOPMENT: "/product-development",
  DESIGN_STUDIO: "/design-studio",
  IT_CONSULTING: "/it-consulting&staff-augmentation",
  GROWTH_MARKETING: "/growth-marketing",
  EDUCATION: "/education&e-learning",
  RETAIL: "/retail&e-commerce",
  TRAVEL_HOSPITALITY: "/travel-hospitality",
  AUTOMOTIVE: "/automotive",
  TRANSPORTATION: "/transportation&logistics",
  MEDIA_ENTERTAINMENT: "/media&entertainment",
  ERP: "/erp",
};
