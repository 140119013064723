import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { Link, TextField, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import "../FieldTypes.scss";

const RKTextField = ({
  title = "",
  value = "",
  attrName = "",
  value_update = () => {},
  warn_status = false,
  info_status = false,
  class_name = "",
  error_messg = "Enter valid value",
  info_message = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
  disabled,
  max_length,
  required,
  validation_type,
  placeholder,
}: {
  title: string;
  value: any;
  attrName: any;
  value_update: any;
  warn_status: boolean | undefined;
  info_status?: boolean;
  info_message?: string;
  class_name?: string;
  error_messg?: string;
  validation_type?:
    | "NUMBER"
    | "NAME"
    | "ALPHANUMERIC"
    | "PASSWORD"
    | "ALL_CAPS"
    | "REG_NO";
  ref?: any;
  required?: boolean;
  max_length?: number;
  disabled?: boolean;
  placeholder?: string;
}) => {
  const [passVisible, setPassVisible] = useState<boolean>(true);
  const [localValue, setLocalValue] = useState<string>(value);
  
  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  const isIndianVehicleNumber = (text: string): boolean => {
    const indianVehicleNumberRegex =
      /^[A-Z]{0,2}[0-9]{0,2}[A-Z]{0,4}[0-9]{0,4}$/;
    return indianVehicleNumberRegex.test(text);
  };

  const onChangeText = (updatedValue: string) => {
    if (validation_type == "NAME") {
      let valid_value = updatedValue.replace(/[^a-zA-Z ]/g, "");
      setLocalValue(valid_value);
    } else if (validation_type == "NUMBER") {
      let valid_value = updatedValue.replace(/[^0-9]/g, "");
      setLocalValue(valid_value);
    } else if (validation_type == "ALPHANUMERIC") {
      let valid_value = updatedValue.replace(/[^a-zA-Z0-9 ]/g, "");
      setLocalValue(valid_value);
    } else if (validation_type === "ALL_CAPS") {
      setLocalValue(updatedValue.toUpperCase());
    } else if (validation_type === "REG_NO") {
      const cleanedValue = updatedValue.replace(/\s/g, "").toUpperCase();

      if (isIndianVehicleNumber(cleanedValue)) {
        setLocalValue(cleanedValue);
      }
    } else {
      setLocalValue(updatedValue);
    }
  };

  return (
    <div className={`inputField ${class_name}`}>
      <TextField
        fullWidth
        label={title}
        variant="outlined"
        type={
          validation_type === "PASSWORD" && passVisible ? "password" : "text"
        }
        value={localValue}
        placeholder={placeholder}
        inputProps={{ maxLength: max_length }}
        onChange={(e) => {
          onChangeText(e.target.value);
        }}
        onBlur={() => {
          value_update(attrName, localValue);
        }}
        required={required}
        disabled={disabled}
      />
      {validation_type === "PASSWORD" ? (
        <Link
          onClick={() => {
            setPassVisible(!passVisible);
          }}
          className="passwordView"
        >
          {passVisible === true ? <VisibilityOffIcon /> : <VisibilityIcon />}
        </Link>
      ) : null}
      {info_status === true ? (
        <Tooltip title={info_message === undefined ? "" : info_message}>
          <span className="infoMessage">
            <InfoOutlinedIcon />
          </span>
        </Tooltip>
      ) : null}
      {warn_status == true ? (
        <span className="error">{error_messg}</span>
      ) : null}
    </div>
  );
};

export default RKTextField;
