export function validateEmail(email: string): boolean {
  const emailRegex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z-])+\.)+([a-zA-Z]{2,4})+$/;
  return emailRegex.test(email);
}
export function validateMobileNumber(
  mobileNumber: string | Date | null
): boolean {
  if (typeof mobileNumber === "string") {
    if (mobileNumber !== undefined && mobileNumber !== null) {
      const mobile = mobileNumber.toString().trim();
      if (
        mobile.toString().length === 10 &&
        parseInt(mobile.substring(0, 1)) >= 6
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export function internationalMobileNumber(
  mobileNumber: string | Date | null,
  code: string
): boolean {
  if (typeof mobileNumber === "string" && mobileNumber !== null) {
    const regexPatterns: { [key: string]: RegExp } = {
      "+61": /^(\+61|0)4\d{8}$/,
      "+55": /^\+55(?:6[1-9]|7[0-9]|8[1-9]|9[1-9])\d{8}$/,
      "+1": /^(?:\+1)?[-.\s]?(\d{3})[-.\s]?(\d{3})[-.\s]?(\d{4})$/,
      "+45": /^(\+45|0045)?[1-9][0-9]{7}$/,
      "+91": /^(\+91[\-\s]?)?[6789]\d{9}$/,
      "+81": /^(?:\+81|0)(?:80|90)-\d{4}-\d{4}$/,
      "+7": /^(\+7|8)[-\s]?\(?\d{3}\)?[-\s]?\d{3}[-\s]?\d{2}$/,
      "+971": /^(\+971|0)(5[0245689])(\d{7})$/,
      "+44": /^(?:\+44\s?7\d{3}|\(?0?7\d{3}\)?)\s?\d{3}\s?\d{3}$/,
      "+84": /^(01[289]|09)[0-9]{8}$/,
      "+263": /^\+2637[01258]\d{7}$/,
      "+53": /^\+53-5\d{7}$/,
      "+964": /^(\+964|0)?7[0-9]{8}$/,
      "+27": /^(\+27|0)?[678][0-9]{8}$/,
      "+254": /^(\+254|0)?[7][0-9]{8}$/,
      "+86": /^(\+86|0)?1[3456789][0-9]{9}$/,
      "+33": /^(\+33|0)?[67][0-9]{8}$/,
      "+353": /^(\+353|0)?[89][0-9]{7}$/,
      "+30": /^(\+30|0)?[69][0-9]{9}$/,
      "+1-876": /^(\+1|0)?[8765][0-9]{9}$/,
      "+965": /^(\+965|0)?[569][0-9]{7}$/,
      "+98": /^(\+98|0)?9[0-9]{9}$/,
    };
    const codeTrimmed = code.trim().replace(/\s/g, '');
    const mobile = mobileNumber.trim();
    if (!(codeTrimmed in regexPatterns)) {
      // Handle the case where 'code' is not a valid key in regexPatterns
      const normanlRegex = /^\d{7,15}$/
      return normanlRegex.test(mobile);
    }
    return regexPatterns[codeTrimmed].test(mobile)
  }
  return false;
}

export const isEmpty = (value: string | Date | null | undefined | number) => {
  return (
    value === null || value === undefined || `${value}`.trim().length === 0
  );
};
