import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import "../../CommonSCSS/common.scss";
import Footer from "../../Components/Desktop/Footer/Footer";
import Navbar from "../../Components/Desktop/Navbar/Navbar";
import MNavbar from "../../Components/Mobile/Navbar/MNavbar";
import useIsMobile from "../../SupportingFiles/MobileProvider";
import CustomButton from "../../Components/Common/CustomButton/CustomButton";
import CaseStudyBox from "../HealthCare/CaseStudyBox/CaseStudyBox";
import RequestQuotationComponent from "../../Components/Common/RequestComponent/RequestQuotationComponent";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import RequestQuotation from "../../Components/Common/RequestQuotationPopup/RequestQuotation";
import CaseStudyModal, { caseType } from "../../Components/Common/CaseStudy/CaseStudyModal";

function Transportation() {
    const isMobile = useIsMobile();
    const [open, setOpen] = useState<boolean>(false);
    const [openRequestQuotation, setOpenRequestQuotation] = useState<boolean>(false);
    const services = [
        {
            id: 1,
            heading: "Crowd Shipping App",
            content: "Seamlessly track packages with precision through GPS systems, ensuring accurate and timely deliveries.",
            image: "/images/trans_service1.svg",
        },
        {
            id: 2,
            heading: "Freight Rate Comparison App",
            content: "Compare rates based on size, weight, style, color, distance, and pickup/drop location, optimizing cost-effective logistics.",
            image: "/images/trans_service2.svg",
        },
        {
            id: 3,
            heading: "Warehouse Management App",
            content: "Top-class solutions for inventory, shipping, distribution, and workflow management accessible through mobile devices.",
            image: "/images/trans_service3.svg",
        },
        {
            id: 4,
            heading: "Truck Direction Routing App",
            content: "Perfectly guides logistics companies to avoid traffic and optimize routes for efficient transport.",
            image: "/images/trans_service4.svg",
        },
        /////////////////////////////
        {
            id: 5, heading: "Crowd Shipping App", content: "Seamlessly track packages with precision through GPS systems, ensuring accurate and timely deliveries.",
            image: "/images/trans_service1.svg",
        },
        {
            id: 6, heading: "Freight Rate Comparison App", content: "Compare rates based on size, weight, style, color, distance, and pickup/drop location, optimizing cost-effective logistics.",
            image: "/images/trans_service2.svg",
        },
        {
            id: 7, heading: "Warehouse Management App", content: "Top-class solutions for inventory, shipping, distribution, and workflow management accessible through mobile devices.",
            image: "/images/trans_service3.svg",
        },
        {
            id: 8, heading: "Truck Direction Routing App", content: "Perfectly guides logistics companies to avoid traffic and optimize routes for efficient transport.",
            image: "/images/trans_service4.svg",
        },
        //////////////////////////
        // {
        //     id: 5,
        //     heading: "Billing Software Solutions",
        //     content: "Handle billing processes, invoicing, and tracking seamlessly for products and services.",
        //     image: "/images/trans_service5.svg",
        // },
        // {
        //     id: 6,
        //     heading: "Supply Chain Software Solutions",
        //     content: "Execute supply chain transactions, maintain supplier relationships, and automate operations for business control.",
        //     image: "/images/trans_service6.svg",
        // },
        // {
        //     id: 7,
        //     heading: "Shipping Order Processing Tools",
        //     content: "Track order data, monitor inventory status, and ensure on-time packing and delivery of customer orders.",
        //     image: "/images/trans_service7.svg",
        // },
        // {
        //     id: 8,
        //     heading: "Supplier Management Solutions",
        //     content: "Achieve cost savings, quality control, and transparency in supplier relationships through customized IT solutions.",
        //     image: "/images/trans_service8.svg",
        // },
        // {
        //     id: 9,
        //     heading: "Freight Forwarding Software",
        //     content: "Ensuring security, transparency, and performance for the transportation and logistics sector.",
        //     image: "/images/trans_service9.svg",
        // },
        // {
        //     id: 10,
        //     heading: "Transportation Software Solutions",
        //     content: "Streamlining booking, ticketing, safety measures, and accessibility for passenger transport.",
        //     image: "/images/trans_service10.svg",
        // },
        // {
        //     id: 11,
        //     heading: "Logistics Software Automation",
        //     content: "Automating logistics processes, coordinating documents, invoices, routes, packages, and real-time tracking.",
        //     image: "/images/trans_service11.svg",
        // },
        // {
        //     id: 12,
        //     heading: "Fleet Management Software",
        //     content: "Advanced solutions for tracking fleet vehicles, monitoring real-time performance, and enhancing efficiency.",
        //     image: "/images/trans_service12.svg",
        // },
        // {
        //     id: 13,
        //     heading: "RFID and Beacon Solutions",
        //     content: "Implementing RFID and Beacon technology to track goods and inventories throughout the supply chain.",
        //     image: "/images/trans_service13.svg",
        // },
        // {
        //     id: 14,
        //     heading: "Traffic Management Software",
        //     content: "Real-time traffic management systems powered by advanced geo-mapping and location technologies.",
        //     image: "/images/trans_service14.svg",
        // },
    ]
    const responsive = {
        desktop: {
            breakpoint: { max: 4000, min: 1200 },
            items: 4,
            paritialVisibilityGutter: 60,
        },
        tablet: {
            breakpoint: { max: 1199, min: 901 },
            items: 3,
            paritialVisibilityGutter: 40,
        },
        tablet2: {
            breakpoint: { max: 900, min: 701 },
            items: 2,
            paritialVisibilityGutter: 40,
        },
        mobile: {
            breakpoint: { max: 700, min: 0 },
            items: 1,
            paritialVisibilityGutter: 40,
        },
    };
    useEffect(() => {
        if (openRequestQuotation) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "";
        }
    }, [openRequestQuotation]);
    const [openCaseModal, setOpenCaseModal] = useState<boolean>(false);
    const [clickedCase, setClickedCase] = useState<caseType>({
        head: "", subHead: "", mainHeading: "", keywords: "", headPara: "", intro: "",
        challenge: "", solution: "", key: [], conclusion: ""
    });
    const caseStudies: caseType[] = [
        {
            head: "Crowd Shipping", subHead: "Precision Logistics - Revolutionizing Deliveries with Evervent's Crowd Shipping App",
            keywords: "Crowd Shipping App, GPS Tracking, Logistics Innovation",
            mainHeading: "Precision Logistics Excellence with Crowd Shipping Innovation",
            headPara: "Elevating Deliveries through GPS Precision and Unmatched Efficiency",
            intro: "Evervent's Crowd Shipping App stands as a beacon of innovation, transforming precision tracking in logistics. This groundbreaking solution goes beyond mere package tracking, offering GPS precision for accurate and timely deliveries, ushering in a new era in logistics management.",
            challenge: "In the dynamic and unpredictable logistics landscape, the challenge was clear: create a solution that not only tracked packages with precision but also ensured timely deliveries. Meeting this challenge was crucial for businesses aiming to thrive in the competitive logistics arena.",
            solution: "Evervent's Crowd Shipping App responded to this challenge with a multifaceted solution",
            key: [
                { heading: "GPS Tracking Excellence", content: "Tailor-made GPS monitoring systems were implemented, aligning with specific company needs. This ensured not just tracking but precision and efficiency in every step of the package's journey." },
                { heading: "Real-Time Monitoring", content: "The app goes beyond static tracking, providing real-time updates. Logistics companies can monitor deliveries as they happen, allowing for on-the-fly optimization of routes to enhance efficiency." },
                { heading: "Enhanced Accuracy", content: "Precision in tracking isn't just about location; it's about timing. Increased accuracy in delivery times reduces delays, contributing significantly to heightened customer satisfaction." },
                { heading: "Seamless Integration", content: "Recognizing the diverse tech landscapes of logistics companies, the app seamlessly integrates with existing systems. The implementation is smooth, ensuring a quick and disruption-free adoption." },
                { heading: "Efficient Logistics Management", content: "Optimization isn't just a feature; it's a philosophy. Logistics professionals benefit from optimized routes, reduced delivery times, and an overall improvement in the efficiency of the entire delivery process." },
                { heading: "Customer Satisfaction", content: "The ultimate goal is satisfied customers. Ensuring accurate and timely deliveries isn't just a metric; it's a commitment. The Crowd Shipping App significantly contributes to elevated customer satisfaction and builds loyalty." },
            ],
            conclusion: "Evervent's Crowd Shipping App isn't just a tool; it's a paradigm shift in precision logistics. Businesses leveraging this innovative solution gain not just efficiency but a competitive edge in navigating the intricacies of deliveries with unparalleled accuracy. The app is more than a solution; it's a strategic asset, redefining the benchmarks of excellence in logistics management."
        },
        {
            head: "Freight Rate Comparison", subHead: "Transforming Logistics with Freight Rate Comparison",
            keywords: "Freight Rate Comparison App, Logistics Efficiency, Cost Optimization",
            mainHeading: "Freight Optimization: A Game-Changer in Logistics",
            headPara: "Streamlining Logistics Costs through Intelligent Rate Comparison",
            intro: "Evervent's Freight Rate Comparison App emerged as a transformative force in the logistics landscape, offering businesses the ability to optimize costs through intelligent rate comparison. This digital solution compares rates based on various parameters, optimizing logistics processes for cost-effective operations.",
            challenge: "The challenge was to create a tool that not only compared freight rates but also considered factors like size, weight, style, color, distance, and pickup/drop location for a comprehensive logistics optimization.",
            solution: "Evervent's Freight Rate Comparison App addressed this challenge with:",
            key: [
                { heading: "Comprehensive Rate Comparison", content: "The app compares rates based on a range of parameters, including size, weight, style, color, distance, and pickup/drop location, providing businesses with a holistic view of cost-effective logistics solutions." },
                { heading: "Optimized Logistics Processes", content: "By considering multiple factors, businesses can make informed decisions, choosing logistics solutions that not only save costs but also enhance overall efficiency." },
                { heading: "Data-Driven Decision-Making", content: "The app empowers businesses with data-driven insights, enabling them to choose the most cost-effective logistics options for each shipment." },
                { heading: "Enhanced Visibility", content: "Businesses gain enhanced visibility into logistics costs, allowing for better budgeting and financial planning." },
                { heading: "Streamlined Operations", content: "Logistics professionals benefit from streamlined operations, optimized routes, and improved overall efficiency." },
                { heading: "Tailored Solutions", content: "The app is tailored to meet the distinctive needs of the logistics and distribution landscape, providing customized solutions for varying business sizes." },
            ],
            conclusion: "Evervent's Freight Rate Comparison App has become a vital tool in the logistics industry, empowering businesses to make informed, cost-effective decisions and streamline their operations for enhanced profitability."
        },
        {
            head: "Rowley Autos", subHead: "Empowering Rowley Autos with Customized Digital Solutions Tailored to Their Business Needs",
            keywords: "Client: Rowley Autos, Coventry, UK.",
            mainHeading: "Empowering Rowley Autos with Customized Digital Solutions Tailored to Their Business Needs",
            headPara: "",
            intro: "At Evervent, we understand that every business has unique challenges and opportunities. Rowley Autos, a key player in the UK automotive sector specializing in heavy goods vehicle (HGV) maintenance and inspections, approached us with a clear vision: to optimize their Periodic Maintenance Inspection (PMI) processes. They needed a solution that not only automated PMI checks but also seamlessly integrated with their existing operations, enhancing data accuracy and transparency. Here's how Evervent made it happen.",
            challenge: "Rowley Autos faced significant challenges with manual PMI checks for their extensive HGV fleet. These processes were not only time-consuming but also prone to human errors, leading to data inconsistencies. Our task was clear: understand their business use case deeply and provide digital solutions that would complement their existing workflow.",
            solution: "Evervent, led by our Senior Product Manager, embarked on a journey to develop a tailored solution that met Rowley Autos' specific needs:",
            key: [
                { heading: "Time and Cost Savings", content: "Automation significantly reduced manual effort, leading to substantial time and cost savings." },
                { heading: "Data Accuracy", content: "Transitioning to digital processes minimized errors, elevating the quality of PMI inspections and reports." },
                { heading: "Enhanced Customer Experience", content: "The streamlined booking system improved client satisfaction and loyalty." },
                { heading: "Seamless Integration", content: "Our solutions seamlessly integrated into Rowley Autos' workflow, minimizing disruption and ensuring a smooth transition." },
                { heading: "Transparency and Compliance", content: "Real-time access to data and compliance with UK regulations ensured transparency and minimized compliance risks." },
                { heading: "Broking System", content: "We developed a user-friendly booking system that allowed Rowley Autos' clients to schedule PMI checks and services online. This streamlined the appointment booking process while ensuring minimal disruption to their workflow." },
                { heading: "iPad Inspection App", content: "Our dedicated iPad application simplified PMI inspections, offering pre-defined checklists and real-time syncing with the central database. This complemented their technician's tasks, reducing inspection time and improving accuracy." },
                { heading: "Robust Reporting System", content: "Our solution integrated a robust reporting system. It produced detailed inspection reports with digital signatures and compliance indicators, accessible in real-time to enhance transparency and data integrity" },
                { heading: "Data Analytics", content: "We included advanced data analytics tools, offering insights into PMI trends. This empowered Rowley Autos to make data-driven decisions, optimize maintenance planning, and control costs." },
            ],
            conclusion: "At Evervent, our mission is to not just provide software but to truly understand your business needs and craft digital solutions that complement your existing processes. Our partnership with Rowley Autos demonstrates our commitment to delivering tailored, innovative solutions that drive operational excellence and long-term success. As Evervent's Senior Product Manager, I take immense pride in our team's contribution to this project. This case study underscores our dedication to understanding your business and providing digital solutions that enhance your operations and foster growth. Discover how Evervent can transform your business through a deep understanding of your unique needs and custom digital solutions that seamlessly complement your processes."
        },
    ]

    return (
      <Box className="pagesWrapper">
        <RequestQuotation
          open={openRequestQuotation}
          setOpen={setOpenRequestQuotation}
          popup_heading=""
        />

        <CaseStudyModal
          open={openCaseModal}
          setOpen={setOpenCaseModal}
          item={clickedCase}
        />

        {isMobile ? <MNavbar /> : <Navbar open={open} setOpen={setOpen} />}

        <Box className="edu_header">
          <Grid container columnSpacing={3}>
            <Grid xs={12} md={6} className="head">
              <h1>
                Revolutionizing Commerce{" "}
                <span>through Technological Mastery</span>
              </h1>
              <p>
                In the domain of logistics and distribution, Evervent is at the
                forefront of crafting transformative solutions that redefine the
                very essence of efficiency, transparency, and profitability. Our
                innovative technological interventions are meticulously designed
                to streamline and optimize the intricate processes that define
                logistics operations.
              </p>
              <Box className="ctaBtn">
                <CustomButton
                  text_name={"Get Started"}
                  class_name="blackLgBtn"
                  onClickFunction={()=>setOpenRequestQuotation(true)}
                />
              </Box>
            </Grid>
            <Grid xs={12} md={6} className="bottom_content">
              <img src="/images/transportation_header.svg" alt="con" />
            </Grid>
          </Grid>
        </Box>

        <Box className="retail_products">
          <Grid container columnSpacing={3}>
            <Grid xs={12} className="head mb-6" textAlign={"center"}>
              <h2 className="mb-3">
                <span>Our </span>Services
              </h2>
              <p>
                Periodic Maintenance & Inspection App: Designed to streamline
                maintenance processes, enhance equipment performance, and ensure
                compliance, our app is a comprehensive solution for businesses
                seeking efficiency in their maintenance routines.
              </p>
            </Grid>
          </Grid>
          <Grid container>
            <Grid xs={12}>
              <Carousel
                responsive={responsive}
                renderArrowsWhenDisabled={true}
                swipeable={true}
                draggable={true}
                customTransition={"transform 800ms ease-in-out"}
                className="retail_carousel"
              >
                {services?.map((item) => (
                  <div className="main_box">
                    <div className="img_box">
                      <img src={item?.image} alt="service" />
                    </div>
                    {/* <h5>0{item?.id}</h5> */}
                    <h5>{item?.heading}</h5>
                    <p className="mb-8">{item?.content}</p>
                    <Box className="ctaBtn">
                      <CustomButton
                        text_name={"Inquire"}
                        class_name="blueMdBtn"
                        onClickFunction={() => setOpenRequestQuotation(true)}
                      />
                    </Box>
                  </div>
                ))}
              </Carousel>
            </Grid>
          </Grid>
        </Box>

        <Box className="trans_path">
          <Grid container rowSpacing={3}>
            <Grid md={6} lg={6}>
              <Box>
                <Grid container spacing={3} className="section1">
                  <Grid xs={12} className="head mb-6">
                    <h2 className="mb-3">
                      Evervent Revolutionizing Logistics & Transportation Path
                    </h2>
                    <p>
                      Periodic Maintenance & Inspection App: Designed to
                      streamline maintenance processes, enhance equipment
                      performance, and ensure compliance, our app is a
                      comprehensive solution for businesses seeking efficiency
                      in their maintenance routines.
                    </p>
                  </Grid>
                  <Grid xs={12} sm={6} md={6} lg={6} className="main_box">
                    <Box className="trans_box">
                      <h5>Innovative Tech Integration</h5>
                      <p>
                        Elevating logistics with avant-garde technology,
                        Evervent pioneers the fusion of cutting-edge solutions
                        into the very fabric of the industry.
                      </p>
                    </Box>
                  </Grid>
                  <Grid xs={12} sm={6} md={6} lg={6} className="main_box">
                    <Box className="trans_box">
                      <h5>Tech-Driven Ideation</h5>
                      <p>
                        Our unique approach transforms conceptual logistics
                        ideas into immersive, interactive, and technology-driven
                        experiences.
                      </p>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid
              sm={12}
              md={6}
              lg={6}
              display={"flex"}
              alignItems={"end"}
              justifyContent={"center"}
            >
              <img src="/images/trans_path.svg" alt="" />
            </Grid>
            <Grid lg={12}>
              <Box>
                <Grid container spacing={3} className="section2 section1">
                  <Grid xs={12} sm={6} md={3} className="main_box">
                    <Box className="trans_box">
                      <h5>Tailored Tech Solutions</h5>
                      <p>
                        Evervent crafts bespoke products precisely tailored to
                        meet the distinctive needs of the logistics and
                        distribution landscape
                      </p>
                    </Box>
                  </Grid>
                  <Grid xs={12} sm={6} md={3} className="main_box">
                    <Box className="trans_box">
                      <h5>Size, Solution & Scalability</h5>
                      <p>
                        At the core of our strategy lies a commitment to
                        prioritize individual logistics requirements, delivering
                        scalable solutions that align with varying business
                        sizes.
                      </p>
                    </Box>
                  </Grid>
                  <Grid xs={12} sm={6} md={3} className="main_box">
                    <Box className="trans_box">
                      <h5>Efficiency Amplified Through Tech</h5>
                      <p>
                        Evervent introduces unprecedented efficiency into
                        logistics operations through the strategic application
                        of advanced technology solutions.
                      </p>
                    </Box>
                  </Grid>
                  <Grid xs={12} sm={6} md={3} className="main_box">
                    <Box className="trans_box">
                      <h5>Innovative Tech Integration</h5>
                      <p>
                        Elevating logistics with avant-garde technology,
                        Evervent pioneers the fusion of cutting-edge solutions
                        into the very fabric of the industry.
                      </p>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box className="trans_offers">
          <Grid container columnSpacing={3}>
            <Grid xs={12} lg={6} display={"flex"} justifyContent={"center"}>
              <img src="/images/trans_unique.svg" alt="" />
            </Grid>
            <Grid xs={12} lg={6}>
              <div className="head mb-6">
                <h2>
                  Unique Offerings<span> of Our Solutions inculcate.</span>
                </h2>
              </div>
              <Box className="content">
                <div className="main_box mb-4">
                  <img src="/images/check.svg" alt="check" />
                  <h6>
                    Advanced Asset Tracking:{" "}
                    <span>
                      Seamlessly keep track of corporate assets, shipments, and
                      cargo conditions throughout the entire supply chain
                      journey.
                    </span>
                  </h6>
                </div>
                <div className="main_box mb-4">
                  <img src="/images/check.svg" alt="check" />
                  <h6>
                    GPS Monitoring Excellence:{" "}
                    <span>
                      Implement tailor-made GPS monitoring systems that align
                      with the specific needs of your company, ensuring
                      precision and efficiency.
                    </span>
                  </h6>
                </div>
                <div className="main_box mb-4">
                  <img src="/images/check.svg" alt="check" />
                  <h6>
                    Route Planning Mastery:{" "}
                    <span>
                      Empower logistics and transportation professionals with
                      cutting-edge route planning software, optimizing
                      dispatches, and enhancing fleet management.
                    </span>
                  </h6>
                </div>
                <div className="main_box mb-4">
                  <img src="/images/check.svg" alt="check" />
                  <h6>
                    Smart Asset Audits:{" "}
                    <span>
                      Evervent's advanced solutions track billing errors,
                      inconsistencies, and monitor carrier performance, ensuring
                      a cost-effective and streamlined logistics operation.
                    </span>
                  </h6>
                </div>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box className="case_study">
          <Grid container columnSpacing={3}>
            <Grid xs={12} className="mb-10" textAlign={"center"}>
              <h2 className="mb-3">Case Studies </h2>
              <p>
                In the realm of challenges, every case study is a chapter of
                solutions, each offering its unique wisdom to those who seek the
                knowledge it holds.
              </p>
            </Grid>
          </Grid>
          <Box className="case_bottom">
            <Grid
              container
              columnSpacing={3}
              justifyContent={"center"}
              rowGap={"24px"}
            >
              {caseStudies?.map((item) => (
                <Grid xs={6} sm={6} md={4} lg={3}>
                  <CaseStudyBox
                    color="#79D05A"
                    title={item?.head}
                    description={item?.subHead}
                    onClickFunction={() => {
                      setOpenCaseModal(true);
                      setClickedCase(item);
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>

        <Box className="edu_quotation">
          <RequestQuotationComponent />
        </Box>

        <Footer />
      </Box>
    );
}

export default Transportation;


