import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../../CommonSCSS/common.scss";
import CustomButton from "../../Components/Common/CustomButton/CustomButton";
import Footer from "../../Components/Desktop/Footer/Footer";
import useIsMobile from "../../SupportingFiles/MobileProvider";
import CogBox from "./CogBox/CogBox";
import ContentList from "./ContentList/ContentList";
import DashBorderBox from "./DashBorderBox/DashBorderBox";
import NumberBox from "./NumberBox/NumberBox";
import MNavbar from "../../Components/Mobile/Navbar/MNavbar";
import Navbar from "../../Components/Desktop/Navbar/Navbar";
import RequestQuotationComponent from "../../Components/Common/RequestComponent/RequestQuotationComponent";
import RequestQuotation from "../../Components/Common/RequestQuotationPopup/RequestQuotation";
import CaseStudyModal, { caseType } from "../../Components/Common/CaseStudy/CaseStudyModal";

function HealthCare() {
  const [open, setOpen] = useState<boolean>(false);
  const [value, setValue] = React.useState("1");
  const isMobile = useIsMobile();
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const responsive = {
    desktop: {
      breakpoint: { max: 4000, min: 1200 },
      items: 3,
      paritialVisibilityGutter: 60,
    },
    tablet: {
      breakpoint: { max: 1199, min: 821 },
      items: 2,
      paritialVisibilityGutter: 40,
    },
    mobile: {
      breakpoint: { max: 820, min: 0 },
      items: 1,
      paritialVisibilityGutter: 40,
    },
  };
  const tailoredHealthcare = {
    mobile: {
      breakpoint: { max: 4000, min: 0 },
      items: 1,
    },
  };
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const tabdata = [
    {
      label: "Electronic Medical Records (EMR)",
      imgsrc: "./images/user-icon.svg",
      content: {
        imgsrc: "./images/laptop.svg",
        description:
          "Manage medical data with ease. Our custom healthcare software solutions allow you to manage patient profiles, medical histories, treatment plans, prescriptions, and client appointments.",
        items: [
          "Secure medical document management",
          "Patient profiles and medical histories",
          "E-prescriptions and treatment plans",
          "Automated appointment scheduling",
          "Medical billing, CRM, and HIS integrations",
          "Robust reporting, analytics, and offline access",
        ],
      },
    },
    {
      label: "Telemedicine Software",
      imgsrc: "./images/call-icon.svg",
      content: {
        imgsrc: "./images/laptop.svg",
        description:
          "Deliver exceptional patient care around the clock, regardless of location. We specialize in implementing video consultations, secure messaging, and remote monitoring solutions that adhere to HIPAA regulations. We also offer offline charting, automated patient appointment scheduling, and seamless integration with electronic medical records (EMRs) to streamline healthcare services.",
        items: [
          "HIPAA-compliant video consultations",
          "Virtual visits for non-emergency concerns",
          "Instant messaging for quick medical advice",
          "E-prescriptions",
          "Automated appointment scheduling",
          "Integration with EMR systems",
        ],
      },
    },
    {
      label: "Doctor-on-Demand Applications",
      imgsrc: "./images/doctor-icon.svg",
      content: {
        imgsrc: "./images/laptop.svg",
        description:
          "Ensure high-quality patient care no matter the location. We specialize in implementing secure and HIPAA-compliant video consultations, instant messaging, and remote monitoring. Enhance convenience with offline charting, automated patient scheduling, and seamless EMR integration.",
        items: [
          "On-demand appointments for urgent needs",
          "Remote video consultations",
          "Location-based search for nearby doctors",
          "Medical billing and in-app payments",
          "E-prescriptions",
          "Doctor ratings & feedback",
        ],
      },
    },
    {
      label: "Mobile Health (MHealth) and IOT Apps",
      imgsrc: "./images/mobiles-icon.svg",
      content: {
        imgsrc: "./images/laptop.svg",
        description:
          "Provide healthcare and wellness solutions wherever you are. Our healthcare app development company specializes in creating various mobile health (mHealth) apps. These apps offer users swift and convenient access to essential healthcare services, enable fitness tracking, medication management with timely reminders, and foster engagement within health communities.",
        items: [
          "Fitness and activity tracking",
          "Medical charting",
          "Telemedicine",
          "Appointments scheduling",
          "Mental health and meditations",
          "Remote patient monitoring",
        ],
      },
    },
    {
      label: "Custom Patient Portals",
      imgsrc: "./images/signin-icon.svg",
      content: {
        imgsrc: "./images/laptop.svg",
        description:
          "Enable users to conveniently access their complete personal health information from a single location. We prioritize secure storage for health records, prescriptions, test results, and doctor visit data. Additionally, we offer the option to seamlessly integrate your portal with medical billing, insurance management, patient scheduling, real-time chat functionality, and eLearning resources.",
        items: [
          "Personal health records",
          "Test results and doctor's notes",
          "Appointment scheduling",
          "Telemedicine integrations",
          "Built-in chat",
          "Billing and insurance",
        ],
      },
    },
    {
      label: "Integrated Healthcare Systems",
      imgsrc: "./images/database-icon.svg",
      content: {
        imgsrc: "./images/laptop.svg",
        description:
          "Create a tailored medical solution with a flexible and expandable modular structure. We'll assist you in choosing the most suitable pre-built modules, integrating existing legacy systems, and crafting personalized dashboards. You can count on robust data security, compliance, and seamless data sharing through data warehouse and Master Data Management (MDM) solutions.",
        items: [
          "HIPAA-compliant video consultations",
          "Data interoperability",
          "HIPAA compliance",
          "Third-party integrations",
          "Business process automation",
          "AI, and LLM",
          "MDM & data warehousing",
        ],
      },
    },
  ];
  const [openRequestQuotation, setOpenRequestQuotation] = useState<boolean>(false);
  const [openCaseModal, setOpenCaseModal] = useState<boolean>(false);
  const [clickedCase, setClickedCase] = useState<caseType>({
    head: "", subHead: "", mainHeading: "", keywords: "", headPara: "", intro: "",
    challenge: "", solution: "", key: [], conclusion: ""
  });
  const caseStudies: caseType[] = [
    {
      head: "", subHead: "",
      keywords: "Empowering Healthcare Providers: Evervent's Next-Gen Revenue Cycle Management Software",
      mainHeading: "Revolutionizing Revenue Management, One Innovation at a Time",
      headPara: "",
      intro: "Evervent's Revenue Cycle Management (RCM) Software represents a paradigm shift in healthcare finance. Designed to revolutionize revenue management, it automates intricate tasks such as billing and collections, empowering healthcare providers to enhance efficiency, expedite payment processes, and focus on what truly matters: exceptional patient care.",
      challenge: "Healthcare providers in the US are confronted with the formidable challenge of efficiently managing revenue cycles. Labor-intensive billing, collections, and intricate insurance claims have led to escalating administrative burdens and diminished patient satisfaction.",
      solution: "Traditionally, managing revenue cycles involved labor-intensive tasks, often leading to delays in payments and administrative bottlenecks. Evervent's RCM software redefines this process. By automating tasks once handled manually, like billing and collections, it accelerates revenue cycles and significantly improves operational efficiency.",
      key: [
        { heading: "Automated Billing and Collections", content: "Evervent's RCM software automates the billing process, ensuring accurate and timely submission of claims. It expedites collections, reducing outstanding accounts and enhancing cash flow." },
        { heading: "Seamless Payment Processing", content: "The software streamlines payment processing, enabling healthcare providers to receive payments faster. Quick and efficient payments improve financial liquidity, ensuring uninterrupted operations." },
        { heading: "Real-time Revenue Insights", content: "Evervent’s RCM software offers real-time analytics, providing invaluable insights into revenue streams. Providers can make data-driven decisions, optimize services, and maximize profitability." },
        { heading: "Compliance Assurance", content: "Evervent’s software adheres to the highest standards of data security and compliance. It ensures that healthcare providers meet regulatory requirements, safeguarding sensitive patient information." },
        { heading: "Exceptional Patient Experience", content: "By expediting payment processes and reducing administrative burdens, Evervent's RCM software enhances patient experiences. Providers can devote more time to patient care, fostering trust and satisfaction." },
      ],
      conclusion: "Evervent's Revenue Cycle Management Software isn't just a solution; it's a transformative force shaping the future of healthcare finance. By automating critical tasks, it accelerates revenue cycles, fortifies financial stability, and elevates patient care experiences. With Evervent's RCM software, healthcare providers unlock unparalleled efficiency, ensuring timely payments and cultivating enduring patient-provider relationships. It's not merely about managing revenue; it's about revolutionizing the healthcare ecosystem, one automated task at a time. Evervent empowers healthcare providers to focus on their passion: delivering exceptional care, while the software takes care of the rest. Welcome to the future of healthcare finance - welcome to Evervent."
    },
    {
      head: "", subHead: "",
      keywords: "Innovative EMR System for Vector Borne Disease Control & Management",
      mainHeading: "Revolutionizing Healthcare for a Healthier Tomorrow",
      headPara: "",
      intro: "In the ever-evolving landscape of healthcare, Evervent's Electronic Medical Record (EMR) System has emerged as a beacon of innovation, especially in the context of Vector Borne Diseases. This one-pager delves into the transformative application of this cutting-edge EMR System within US Healthcare Institutions, showcasing its remarkable impact on disease control and management.",
      challenge: "Vector Borne Diseases pose a significant public health challenge, demanding meticulous data management, rapid response, and seamless coordination between healthcare providers. Traditional record-keeping methods proved inadequate, leading to inefficiencies, delayed responses, and compromised patient care.",
      solution: "The innovative EMR System offers automated administrative tasks, seamless integration, patient-centric features, optimized insurance utilization, advanced data analytics, and AI-driven predictive analytics. This redefines disease control and management, amplifies patient satisfaction, and ensures efficient resource allocation, ultimately leading to a healthier and more efficient healthcare system.",
      key: [
        { heading: "Streamlining Administrative Tasks", content: "The EMR System automates administrative duties, reducing redundant data entry and paperwork. This allows healthcare providers to dedicate more time to patient care and disease control initiatives." },
        { heading: "Adaptability and Integration", content: "The system seamlessly integrates with existing healthcare infrastructure, ensuring a smooth transition and enhancing overall operational efficiency." },
        { heading: "Patient Comfort Maximization", content: "Patients benefit from streamlined appointments, instant access to medical records, and simplified insurance processes. This patient-centric approach fosters trust and comfort." },
        { heading: "Utilization of Healthcare Insurances", content: "The system maximizes the utilization of healthcare insurances, reducing financial burdens on patients and ensuring comprehensive coverage for treatments and medications." },
        { heading: "Advanced Data Analytics", content: "The EMR System harnesses the power of data analytics to provide real-time insights. Healthcare providers can make informed decisions, optimize resources, and implement targeted disease control strategies." },
        { heading: "AI for Predictive Analytics", content: "Artificial Intelligence (AI) algorithms analyze patterns and predict disease outbreaks, enabling proactive measures and strategic planning for disease control and prevention." },
        { heading: "Efficiency Amplified", content: "Administrative efficiency has surged, allowing healthcare professionals to focus on proactive disease control measures and patient care." },
        { heading: "Enhanced Patient Experiences", content: "Patients enjoy streamlined services, prompt responses, and personalized care, elevating overall satisfaction levels." },
        { heading: "Optimized Resource Allocation", content: "AI-driven predictive analytics optimize resource allocation, ensuring that interventions are timely, effective, and tailored to specific disease patterns." },
        { heading: "Financial Relief", content: "By maximizing insurance utilization and reducing administrative costs, the EMR System ensures financial relief for both healthcare institutions and patients." },
      ],
      conclusion: "This Electronic Medical Record System stands at the forefront of healthcare innovation. By seamlessly integrating technology, data analytics, and AI, it has not just transformed disease control and management; it has redefined patient experiences and operational efficiency for US Healthcare Institutions and Providers. This EMR System is not just a software; it's a strategic partner in the mission for a healthier, disease-free tomorrow."
    },
  ]

  return (
    <Box className="pagesWrapper">

      <RequestQuotation open={openRequestQuotation} setOpen={setOpenRequestQuotation} popup_heading="" />

      <CaseStudyModal open={openCaseModal} setOpen={setOpenCaseModal} item={clickedCase} />

      {isMobile ? <MNavbar /> : <Navbar open={open} setOpen={setOpen} />}

      <Box className="pagesHeader mb-20 mb-40-m">
        <Grid container spacing={3} alignItems="center">
          <Grid xs={12} md={6}>
            <h6>Healthcare & wellness</h6>
            <h1 className="my-6">Healthcare Software Development Services</h1>
            <p>
              At Evervent, we specialise in creating custom medical solutions
              that prioritise patient care, enhance operational efficiency, and
              manage extensive data securely. Our focus is on delivering
              patient-centred, compliant and secure healthcare innovations.
            </p>
            <div className="ctaBtn mt-6">
              <CustomButton
                text_name="Request a Quotation"
                class_name="blackLgBtn"
                onClickFunction={() => setOpenRequestQuotation(true)}
              />
            </div>
          </Grid>
          <Grid xs={12} md={6}>
            <img src="./images/healthcare-wellness.svg" alt="healthcare" />
          </Grid>
        </Grid>
      </Box>

      <Grid container spacing={3} marginBottom={"80px"} className="mb-40-m">
        <Grid xs={12} textAlign="center">
          <h2>Improved patient care and streamlined operations</h2>
          <p>
            Elevate healthcare with Evervent. We offer secure data solutions,
            seamless UX and robust analytics for medical organisations,
            associations and startups.
          </p>
        </Grid>
      </Grid>

      <Box className="partialSlider mb-20 mb-40-m">
        <Carousel
          partialVisbile
          responsive={responsive}
          renderArrowsWhenDisabled={true}
          swipeable={true}
          draggable={true}
          customTransition={"transform 800ms ease-in-out"}
        >
          <div className="px-4">
            <CogBox
              color="#2F81DD"
              title="Enhance Operational Efficiency"
              description="Evervent understands that operational efficiency is paramount in today's ever-changing healthcare business landscape. We specialize in providing healthcare enterprises with customized software solutions, focusing on:"
              items={[
                "Patient Relationship Management",
                "Digital Health Records",
                "Comprehensive Healthcare Practice Oversight & Management",
              ]}
            />
          </div>
          <div className="px-4">
            <CogBox
              color="#5BAC3F"
              title="Digital Patient Engagement Soutions"
              description="Elevate patient care through tailored digital solutions. Our core priorities include a user-friendly interface, effortless onboarding, offline accessibility, and sustained user interaction."
              items={[
                "Patient Engagement Platforms",
                "Appointment Schedulers & Booking Systems",
                "Remote Consultations & Telemedicine",
                "Digital Medical Markets",
              ]}
            />
          </div>
          <div className="px-4">
            <CogBox
              color="#F6A13C"
              title="Unlocking Medical Insights on a Grand Scale"
              description="Organize extensive medical data systematically, decode diagnostic findings, detect and manage uncommon medical conditions, and reduce diagnostic inaccuracies through seamless integration of Machine Learning & AI."
              items={[
                "Healthcare Data Aggregation & Warehousing",
                "Integration of Master Data Management (MDM)",
                "Utilizing BI and Visual Analytics",
                "Intelligent Healthcare Assistant Bots & Language Learning Models (LLMs)",
              ]}
            />
          </div>
          <div className="px-4">
            <CogBox
              color="#FF725E"
              title="Streamlined Integrations for Accessibility and Security"
              description="We ensure that health information exchange is made easy and secure through resilient API architecture, HIPAA-compliant linkage, and data compatibility."
              items={[
                "Digital Health Records Integration",
                "Healthcare Learning Management Systems & ERP Systems",
                "Billing and Claims Processing Solutions",
                "Human Capital Management (HCM) and Workforce Optimization",
                "Integration with Insurance Data Networks",
              ]}
            />
          </div>
          <div className="px-4">
            <CogBox
              color="#7165E4"
              title="Patient Data Sanctity & Security"
              description=" Comply with healthcare regulations and defend against data leaks. We integrate the best security practices into the SDLC to proactively identify, detect, respond, and recover from cyber threats."
              items={[
                "Secure cloud services",
                "HIPAA-compliance",
                "Encryption and access controls",
              ]}
            />
          </div>
          <div className="px-4">
            <CogBox
              color="#2F81DD"
              title="Software Support & Maintenance"
              description=" Work with an enterprise-level partner to support your solution for as long as needed. Release new features, automate delivery, and quality with our expert healthcare software developers."
              items={[
                "Support & maintenance",
                "Cloud cost optimization",
                "Site Reliability Engineering (SRE)",
                "UI/UX and software modernization",
              ]}
            />
          </div>
        </Carousel>
      </Box>

      <Grid container spacing={3} marginBottom={"80px"} className="mb-40-m">
        <Grid xs={12} textAlign="center">
          <h2>Transform Your Healthcare Services</h2>
          <p>
            We provide end-to-end healthcare software development services
            tailored to meet patient requirements while aligning with your
            business objectives.
          </p>
        </Grid>
        <Grid
          xs={12}
          display={"flex"}
          flexWrap={"wrap"}
          gap={"24px"}
          alignItems="flex-start"
          alignSelf="stretch"
          marginTop="24px"
        >
          <ContentList>Secure medical data management</ContentList>
          <ContentList>Compliance with healthcare standards</ContentList>
          <ContentList>
            Deep analysis of healthcare data and AI insights
          </ContentList>
          <ContentList>
            Focus on UX and performance for better patient service
          </ContentList>
          <ContentList>High-speed data processing</ContentList>
          <ContentList>Healthcare business process optimization</ContentList>
          <ContentList>State-of-the-art data visualization</ContentList>
          <ContentList>Integrations with 3rd-party systems</ContentList>
        </Grid>
      </Grid>
      {!isMobile ? (
        <Box className="verticalTabs py-20 mb-20 mb-40-m">
          <Grid container spacing={3}>
            <Grid xs={12} textAlign="center">
              <h2>Tailored Healthcare Software Development Services</h2>
              <p className="mb-10">
                Discover Typical Solutions We Create as a Medical Software
                Development Firm.
              </p>
            </Grid>
            <Grid xs={12} display="flex" gap="24px">
              <ul
                className="tabul"
                style={{
                  maxWidth: "420px",
                  flex: "1 0 0",
                  alignSelf: "flex-start",
                }}
              >
                {tabdata.map((data, index) => (
                  <li
                    onClick={() => {
                      setSelectedTab(index);
                    }}
                  >
                    <div className="tabs">
                      <div className="icon">
                        <div>
                          <img src={data.imgsrc} />
                        </div>
                      </div>
                      <div
                        className={
                          selectedTab === index ? "title active" : "title"
                        }
                      >
                        <h5>{data.label}</h5>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
              <div className="contents">
                <img src={tabdata[selectedTab].content.imgsrc} />
                <p>{tabdata[selectedTab].content.description}</p>
                <ul>
                  {tabdata[selectedTab].content.items.map((data, index) => (
                    <li>{data}</li>
                  ))}
                </ul>
                <Box className="ctaBtn mt-8">
                  <CustomButton
                    text_name={"Inquire"}
                    class_name="blueMdBtn"
                    onClickFunction={() => setOpenRequestQuotation(true)}
                  />
                </Box>
              </div>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box className="verticalTabs py-10 mb-20 mb-40-m">
          <Grid container spacing={3}>
            <Grid xs={12} textAlign="center">
              <h2>Tailored Healthcare Software Development Services</h2>
              <p>
                Discover Typical Solutions We Create as a Medical Software
                Development Firm.
              </p>
            </Grid>
            <Grid xs={12}>
              <Box className="partialSlider mt-6">
                <Carousel
                  responsive={tailoredHealthcare}
                  renderArrowsWhenDisabled={true}
                  autoPlay={true}
                >
                  {tabdata.map((data) => (
                    <div className="contents">
                      <img src={data.content.imgsrc} />
                      <h4>{data.label}</h4>
                      <p>{data.content.description}</p>
                      <ul>
                        {data.content.items.map((data, index) => (
                          <li>{data}</li>
                        ))}
                      </ul>
                      <Box className="ctaBtn mt-8">
                        <CustomButton
                          text_name={"Inquire"}
                          class_name="blueMdBtn"
                          onClickFunction={() => setOpenRequestQuotation(true)}
                        />
                      </Box>
                    </div>
                  ))}
                </Carousel>
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}

      {/* <Grid container spacing={3} justifyContent="center">
        <Grid xs={12} textAlign="center">
          <h2>Case Studies</h2>
          <p>
            When it comes to mobile and web application development, a case
            study typically focuses on documenting and analyzing a specific
            project or application. The purpose of such a case study is to
            provide a detailed account of the development process, challenges
            faced, solutions implemented, and the final outcomes.
          </p>
        </Grid>
        <Grid xs={12} lg={4} textAlign="center">
          <CaseStudyBox
            color="#79D05A"
            title="Rowley Autos"
            description="Empowering Rowley Autos with Customized Digital Solutions Tailored to Their Business Needs"
          />
        </Grid>
      </Grid> */}

      <Grid container spacing={3} marginBottom={"80px"} className="mb-40-m">
        <Grid xs={12}>
          <h2>Secure Your Healthcare Data</h2>
          <p>
            To safeguard your business, Evervent adheres to all crucial industry
            regulations and data protection standards in the development of
            healthcare software products.
          </p>
        </Grid>
        <Grid xs={12}>
          <Box display="flex" flexWrap="wrap" gap="40px">
            <NumberBox
              title="Healthcare regulatory standards and certifications"
              number={1}
              paragraph1="Our team follows NIST guidelines and key privacy standards like GDPR, HIPAA and HITECH."
              paragraph2="We use data exchange standards HL7, FHIR, CDA, DICOM, ICD, ICPC-2 as well ISO 9001, ISO27001, and SOC2 certifications."
            />
            <NumberBox
              title="Secure Data Storage and Transmission"
              number={2}
              paragraph1="To protect your product from system failures and harmful attacks, we exclusively use highly-trusted hosting servers like Microsoft Azure and AWS. We also encrypt data flow between the system, cloud, and users."
              paragraph2=""
            />
            <NumberBox
              title="Robust Data and Access Control"
              number={3}
              paragraph1="We employ a variety of security measures that have proven effective in the field."
              paragraph2="They include multi-factor authentication, strong passwords, automatic logout, permission levels, and robust logging systems to retain previous user data."
            />
          </Box>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={3}
        justifyContent="center"
        marginBottom={"80px"}
        className="mb-40-m"
      >
        <Grid xs={12} textAlign="center">
          <h2>Case Studies</h2>
          <p>
            When it comes to mobile and web application development, a case
            study typically focuses on documenting and analyzing a specific
            project or application. The purpose of such a case study is to
            provide a detailed account of the development process, challenges
            faced, solutions implemented, and the final outcomes.
          </p>
        </Grid>
      </Grid>

      <Grid container spacing={3} marginBottom={"80px"} className="mb-40-m">
        <Grid xs={12} md={5}>
          <img src="./images/medical-tests.svg" width="100%" alt="img" />
        </Grid>
        <Grid xs={12} md={7} display="flex" flexDirection="column" gap="24px">
          <h2>Healthcare Financial Optimization Suite</h2>
          <h5 style={{ fontWeight: "600" }}>
            How can healthcare providers optimize their revenue cycles,
            streamline administrative tasks, and enhance patient satisfaction
            while ensuring compliance and data security?
          </h5>
          <DashBorderBox
            title="The Challenge"
            description=" Healthcare providers in the US are confronted with the formidable challenge of efficiently managing revenue cycles. Labor-intensive billing, collections, and intricate insurance claims have led to escalating administrative burdens and diminished patient satisfaction."
          />
          <DashBorderBox
            title="The Solution"
            description=" Evervent's RCM Software offers a comprehensive solution by automating billing, collections, and insurance claims. It seamlessly integrates with existing systems, maximizes insurance utilization, heightens patient satisfaction, and ensures unwavering compliance with the most rigorous data security standards. The result is elevated revenue, minimized administrative overhead, and a renewed emphasis on patient care, underpinning financial success for healthcare providers."
          />

          {/* case study box */}
          <div className="ctaBtn">
            <CustomButton
              text_name="View Details"
              class_name="whiteSmBtn"
              onClickFunction={() => {
                setOpenCaseModal(true);
                setClickedCase(caseStudies[0]);
              }}
            />
          </div>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={3}
        flexDirection="row-reverse"
        marginBottom={"80px"}
        className="mb-40-m"
      >
        <Grid xs={12} md={5}>
          <img src="./images/healthcare-work.svg" width="100%" alt="img" />
        </Grid>
        <Grid xs={12} md={7} display="flex" flexDirection="column" gap="24px">
          <h2>
            Innovative EMR System for Vector Borne Disease Control & Management
          </h2>
          <h5 style={{ fontWeight: "600" }}>
            In what manner can healthcare providers attain superior management
            of Vector Borne Diseases, streamline administrative procedures,
            enrich patient experiences, and optimize resource allocation?
          </h5>
          <DashBorderBox
            title="The Challenge"
            description="Healthcare providers dealing with Vector Borne Diseases encounter complex data management, delayed responses, and administrative inefficiencies. Traditional record-keeping methods hinder rapid disease control and compromise patient care."
          />
          <DashBorderBox
            title="The Solution"
            description="The innovative EMR System offers automated administrative tasks, seamless integration, patient-centric features, optimized insurance utilization, advanced data analytics, and AI-driven predictive analytics. This redefines disease control and management, amplifies patient satisfaction, and ensures efficient resource allocation, ultimately leading to a healthier and more efficient healthcare system."
          />
          {/* case study box */}
          <div className="ctaBtn">
            <CustomButton
              text_name="View Details"
              class_name="whiteSmBtn"
              onClickFunction={() => {
                setOpenCaseModal(true);
                setClickedCase(caseStudies[1]);
              }}
            />
          </div>
        </Grid>
      </Grid>

      <Box className="design_quotation">
        <RequestQuotationComponent />
      </Box>

      <Footer />
    </Box>
  );
}

export default HealthCare;
