import AttachFileIcon from "@mui/icons-material/AttachFile";
import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { useEffect, useRef, useState } from "react";
import "../../../CommonSCSS/common.scss";
import CustomButton from "../../../Components/Common/CustomButton/CustomButton";
import Footer from "../../../Components/Desktop/Footer/Footer";
import Navbar from "../../../Components/Desktop/Navbar/Navbar";
import MNavbar from "../../../Components/Mobile/Navbar/MNavbar";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import "../../CmsPages/CmsPages.scss";
import NumberBox from "../../HealthCare/NumberBox/NumberBox";

function Career() {
  const isMobile = useIsMobile();
  const [open, setOpen] = useState<boolean>(false);

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState("Upload your CV in .jpg, .png, or .pdf format");

  const handleFileSelect = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files && event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile.name);
    } else {
      setFile("Upload your CV in .jpg, .png, or .pdf format");
    }
  };
  
  return (
    <>
      {isMobile ? <MNavbar /> : <Navbar open={open} setOpen={setOpen} />}
      <Box className="cms_wrapper">
        <Box className="career_header">
          <Grid container spacing={3}>
            <Grid xs={12} textAlign={"center"} marginBottom={"28px"}>
              <h1>
                Are You <span>TroubleMaker?</span>
              </h1>
              <p>
                We are re-imagining the future of Fintech & Digital Platforms{" "}
                <span> by building Disruptive technology</span>
              </p>
            </Grid>
          </Grid>
        </Box>
        <Box className="cv_section" marginBottom={"40px"}>
          <Grid container spacing={3} height={"380px"}>
            <Grid
              xs={12}
              sm={12}
              md={6}
              lg={8}
              alignSelf={"center"}
              className="flex xl:justify-center lg:justify-center justify-center"
            >
              <Box className="share_cv">
                <span className="ev_bg">EV</span>
                <Box className="right_section">
                  <div>
                    <h5>Hey!! Share your CV</h5>
                  </div>
                  <p>Our team will contact you for further discussion.</p>
                </Box>
              </Box>
            </Grid>
            <Grid
              xs={12}
              sm={12}
              md={6}
              lg={4}
              alignSelf={"center"}
              className="flex xl:justify-center lg:justify-center justify-center"
            >
              <Box className="share_section">
                <Link>
                  <img src="./images/share_img.svg" alt="" />
                </Link>
                <p>
                  We are looking for rebels who see things differently. Share
                  your profile with us and show us what you can do.
                </p>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid xs={12} sm={12} md={7} lg={7} margin={"auto"}>
              <Box className="upload_section">
                <Grid container spacing={0}>
                  <Grid
                    xs={12}
                    alignSelf={"center"}
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={'center'}
                  >
                    <Box display={"flex"} alignItems={"center"}>
                      {" "}
                      <AttachFileIcon />
                      <Box className="fileUpload">
                        <label className="custom-file-upload">
                          <span>{file}</span>
                          <input
                            type="file"
                            ref={fileInputRef}
                            onChange={handleFileChange}
                            onClick={handleFileSelect}
                          />
                        </label>
                      </Box>
                    </Box>
                    <Box className="ctaBtn">
                      <CustomButton text_name="Send" class_name="yellowMdBtn" />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>

        {/* apply section */}

        <Box className="apply_section" marginBottom={"40px"}>
          <Grid container spacing={3}>
            <Grid xs={12} textAlign={"center"}>
              <h5>D🚫n’t apply if you</h5>
              <p>
                “We are looking for people with a Mission to join are awesome
                team of Engineers,{" "}
                <span> Marketeers and Product Enthusiasts.”</span>
              </p>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid xs={12} sm={12} md={6} lg={6}>
              <Box className="left_part">
                <Box className="boxOne">
                  <h5>Are looking for a corner to hide</h5>
                  <p>
                    Each team member at Evervent makes a visible difference in
                    the company's growth.
                  </p>
                </Box>
              </Box>
            </Grid>
            <Grid xs={12} sm={12} md={6} lg={6}>
              <Box className="right_part">
                <Box className="boxOne">
                  <h5>Have trouble imagining the future</h5>
                  <p>
                    Being able to visualize a meaningful future is the first
                    step to building it.
                  </p>
                </Box>
                <Box className="boxTwo">
                  <h5>Need a manager on your shoulders</h5>
                  <p>
                    We work best with motivated people who look up to for
                    leaders for guidance, not up to managers for micro-tracking.
                  </p>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>

        {/* why work section */}

        <Box className="why_work mb-20">
          <Grid container spacing={3}>
            <Grid xs={12} textAlign={"center"}>
              <h5>
                Why work <span>with us?</span>
              </h5>
              <p>Together we're stronger. There’s a “V” in Evervent</p>
            </Grid>
            <Grid xs={12}>
              <Box display="flex" flexWrap="wrap" gap="40px">
                <NumberBox
                  title="Insta Worthy Work Place"
                  number={1}
                  paragraph1='A pleasant and "insta-worthy" office aesthetic. Work and enjoy'
                />
                <NumberBox
                  title="Distruptive Culture"
                  number={2}
                  paragraph1="We encourage to challenge traditional norms, think outside the box, and pursue innovative approaches."
                  paragraph2=""
                />
                <NumberBox
                  title="Morning like BigBoss"
                  number={3}
                  paragraph1="Start your day with morning music session of your choice"
                />
                <NumberBox
                  title="Fri-Yay People"
                  number={4}
                  paragraph1="We celebrate our every weekend with laughter, fulfilling deadlines, snacks and games."
                />
                <NumberBox
                  title="Cross-Functional Expertise"
                  number={5}
                  paragraph1="We focus to excel and contribute effectively in multiple areas acquiring deep understanding, proficiency and experience."
                />
                <NumberBox
                  title="Transformative Environment"
                  number={6}
                  paragraph1="Cultivating a transformative work culture that nurtures innovation, growth and meaningful impact, steering away from transactional norms."
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Footer />
    </>
  );
}

export default Career;
