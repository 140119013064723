import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import ColorBoxes from "./ColorBoxes/ColorBoxes";

function WhatsHappening() {
  return (
    <Grid container spacing={3} marginBottom={window.innerWidth < 700 ? "40px" : "80px"}>
      <Grid xs={12} textAlign={"center"}>
        <h2>Excelling at Evervent in</h2>
        <p className="mb-6">
          Explore the myriad of digital opportunities for your brand through our
          extensive range of services.
        </p>
      </Grid>
      <Grid xs={12} sm={6} md={3}>
        <ColorBoxes
          class_name="bluebox"
          title="Validate & Prototype"
          paragraph="Get A Visual Model of Your Idea Before You Launch It."
          imageSrc="./images/validate.svg"
        />
      </Grid>
      <Grid xs={12} sm={6} md={3}>
        <ColorBoxes
          class_name="yellowbox"
          title="Custom App Development"
          paragraph="Crafting Custom Apps for Your Digital Triumph and Mobile Excellence."
          imageSrc="./images/custom-development.svg"
        />
      </Grid>
      <Grid xs={12} sm={6} md={6}>
        <ColorBoxes
          class_name="cyanbox"
          title="Enterprise Software Development"
          paragraph="We empower you to conquer your market & gain an edge over your competitors with customized solutions."
          imageSrc="./images/enterprise-development.svg"
        />
      </Grid>
      <Grid xs={12} sm={6} md={6}>
        <ColorBoxes
          class_name="greenbox"
          title="Blockchain Development"
          paragraph="Harness the power of decentralization & smart contracts to build enterprise-grade solutions."
          imageSrc="./images/blockchain-development.svg"
        />
      </Grid>
      <Grid xs={12} sm={6} md={3}>
        <ColorBoxes
          class_name="redbox"
          title="AI-Powered App Builder"
          paragraph="AI Means Automated Building Process That Costs 85% Less"
          imageSrc="./images/ai-builder.svg"
        />
      </Grid>
      <Grid xs={12} sm={6} md={3}>
        <ColorBoxes
          class_name="purplebox"
          title="Growth & Marketing"
          paragraph="Driving Value Through Experiences & Business Intelligence"
          imageSrc="./images/growth-marketing.svg"
        />
      </Grid>
    </Grid>
  );
}

export default WhatsHappening;
