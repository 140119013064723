import { Box } from "@mui/material";
import { useState } from "react";
import CustomButton from "../../../Components/Common/CustomButton/CustomButton";

// Define the props interface
interface CogBoxProps {
  title: string;
  description: string;
  color: string;
  onClickFunction: Function;
}

// Convert the functional component to a TypeScript component
function CaseStudyBox(props: CogBoxProps) {
  const { title, description, color, onClickFunction } = props;
  
  return (
    <>
      <Box
        className="caseStudy"
        sx={{
          borderBottomColor: color,
          borderTopColor: color,
          background: `rgba(${parseInt(color.slice(1, 3), 16)}, ${parseInt(
            color.slice(3, 5),
            16
          )}, ${parseInt(color.slice(5, 7), 16)}, 0.3)`, // Replace '0.5' with your desired alpha value
        }}
      >
        <div className="topCorner" style={{ borderColor: color }}></div>

        <h5>{title}</h5>
        <p>{description}</p>
        <div className="ctaBtn">
          <CustomButton
            text_name="View Details"
            class_name="whiteSmBtn"
            onClickFunction={() => {
              onClickFunction();
            }}
          />
        </div>
      </Box>

      {/* <div className="ctaBtn">
          <CustomButton
            text_name="View Details"
            class_name="whiteSmBtn"
            onClickFunction={() => {
              setCaseStudyStatus(true);
            }}
          />
        </div> */}
    </>
  );
}

export default CaseStudyBox;
