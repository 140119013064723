import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import "react-multi-carousel/lib/styles.css";
import ScheduleDemo from "../../Components/Common/ScheduleDemo/ScheduleDemo";
import Footer from "../../Components/Desktop/Footer/Footer";
import Navbar from "../../Components/Desktop/Navbar/Navbar";
import { EHomeSectionName } from "../../Services/Enum/EHomeSectionName";
import { HOME_SERVICES } from "../../Services/Home/HomeServices";
import { THomePage } from "../../Services/Type/Home/THomePage";
import ClientIndus from "./ClientIndus";
import FAQs from "./FAQs";
import GetInTouch from "./GetInTouch";
import "./Homepage.scss";
import Subscribe from "./Subscribe";
import Testimonials from "./Testimonials";
import WelcomeScreen from "./WelcomeScreen";
import WhatsHappening from "./WhatsHappening";
import WhyChooseUs from "./WhyChooseUs";
import useIsMobile from "../../SupportingFiles/MobileProvider";
import MNavbar from "../../Components/Mobile/Navbar/MNavbar";

function Homepage() {
  const isMobile = useIsMobile();
  const [open, setOpen] = useState<boolean>(false);
  useEffect(() => {
    load_home_page_data();
  }, []);

  const load_home_page_data = () => {
    /**
     * Handle the successful response from the API and update the state with the data.
     * @param {any} res - The response object from the API.
     */
    const onSuccess = (res: any) => {
      // Extract the data from the response
      const pre_response = res.data.data.attributes;
      const response = res.data.data.attributes.HomePage;

      const findSectionIndexByName = (sectionName: EHomeSectionName): number =>
        response.findIndex((value: any) => value.section_name === sectionName);

      const getSectionByName = (sectionName: EHomeSectionName): any => {
        const sectionIndex = findSectionIndexByName(sectionName);
        if (sectionIndex !== -1) {
          return response[sectionIndex];
        }
        return {}; // Section not found
      };

      const manipulated_data: THomePage = {
        Description: pre_response.Description,
        Header: pre_response.Header,
        clients_industries: {
          ...getSectionByName(EHomeSectionName.CLIENTS_INDUSTRIES),
          section_index: findSectionIndexByName(
            EHomeSectionName.CLIENTS_INDUSTRIES
          ),
        },
        footer: {
          ...getSectionByName(EHomeSectionName.FOOTER),
          section_index: 10,
        },
        tailored_solutions: {
          ...getSectionByName(EHomeSectionName.TAILORED_SOLUTIONS),
          section_index: findSectionIndexByName(
            EHomeSectionName.TAILORED_SOLUTIONS
          ),
        },
        welcome_screen: {
          ...getSectionByName(EHomeSectionName.WELCOME_SCREEN),
          section_index: findSectionIndexByName(
            EHomeSectionName.WELCOME_SCREEN
          ),
        },
        what_people_say: {
          ...getSectionByName(EHomeSectionName.WHAT_PEOPLE_SAY),
          section_index: findSectionIndexByName(
            EHomeSectionName.WHAT_PEOPLE_SAY
          ),
        },
        whats_happening: {
          ...getSectionByName(EHomeSectionName.WHATS_HAPPENING),
          section_index: findSectionIndexByName(
            EHomeSectionName.WHATS_HAPPENING
          ),
        },
        why_choose_us: {
          ...getSectionByName(EHomeSectionName.WHY_CHOOSE_US),
          section_index: findSectionIndexByName(EHomeSectionName.WHY_CHOOSE_US),
        },
      };

      // Update the state with the manipulated data
      // dispatch(HomeSlice.actions.setHomePageData(manipulated_data));
      // setData(manipulated_data);
    };

    const onError = (err: any) => {
      console.log("---false", err);
    };

    HOME_SERVICES.getHomePageData(onSuccess, onError);
  };

  return (
    <Box className="homeWrapper">
      <ScheduleDemo open={open} setOpen={setOpen} />
      {isMobile ? <MNavbar /> : <Navbar open={open} setOpen={setOpen} />}
      <WelcomeScreen />
      <ClientIndus />
      <GetInTouch />
      <WhatsHappening />
      <WhyChooseUs />
      <Testimonials />
      <FAQs />
      <Subscribe />
      <Footer />
    </Box>
  );
}

export default Homepage;
