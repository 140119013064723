import { Box } from "@mui/material";
import { useState } from "react";
import MNavbar from "../../Components/Mobile/Navbar/MNavbar";
import Navbar from "../../Components/Desktop/Navbar/Navbar";
import useIsMobile from "../../SupportingFiles/MobileProvider";
import Grid from "@mui/material/Unstable_Grid2";
import "../../CommonSCSS/common.scss";
import CustomButton from "../../Components/Common/CustomButton/CustomButton";
import Footer from "../../Components/Desktop/Footer/Footer";
import CaseStudyBox from "../HealthCare/CaseStudyBox/CaseStudyBox";
import RequestQuotationComponent from "../../Components/Common/RequestComponent/RequestQuotationComponent";
import CaseStudyModal, { caseType } from "../../Components/Common/CaseStudy/CaseStudyModal";
import RequestQuotation from "../../Components/Common/RequestQuotationPopup/RequestQuotation";

function ItConsulting() {
  const [open, setOpen] = useState<boolean>(false);
  const [openRequestQuotation, setOpenRequestQuotation] = useState<boolean>(false);
  const isMobile = useIsMobile();
  const [openCaseModal, setOpenCaseModal] = useState<boolean>(false);
  const [clickedCase, setClickedCase] = useState<caseType>({
    head: "", subHead: "", mainHeading: "", keywords: "", headPara: "", intro: "",
    challenge: "", solution: "", key: [], conclusion: ""
  });
  const consultingServicesArray = [
    {
      img_url: "./images/consulting_services_icon1.svg",
      heading: "Digital Transformation Consulting",
      desc: "We guide organizations in implementing user-friendly web and mobile applications, enabling a shift toward a technology-focused business model.",
    },
    {
      img_url: "./images/consulting_services_icon2.svg",
      heading: "Agile Software Development Consulting",
      desc: "By bringing together all project roles, we drive Agile product development, drastically reducing timelines for enterprise-grade projects.",
    },
    {
      img_url: "./images/consulting_services_icon3.svg",
      heading: "Software Architecture Consulting",
      desc: "Our engineering team excels in cost-effective migration of legacy software to modern cloud-native stacks, saving businesses thousands of dollars.",
    },
    {
      img_url: "./images/consulting_services_icon4.svg",
      heading: "Cloud-Native Consulting",
      desc: "We support companies in building, modernizing, and migrating applications using the cloud-native approach, enhancing scalability and performance.",
    },
    {
      img_url: "./images/consulting_services_icon5.svg",
      heading: "DevOps Consulting",
      desc: "Transition seamlessly to the cloud, optimize delivery pipelines, automate CI/CD processes, and establish more stable operating environments with our expert assistance.",
    },
    {
      img_url: "./images/consulting_services_icon6.svg",
      heading: "Test Automation Services",
      desc: " Improve your QA metrics and enhance testing efficiency, even if your company lacks experience in test automation.",
    },
  ];
  const coreGoalsArray = [
    {
      heading: "Flexible Team Scaling",
      desc: "Easily expand your team with skilled engineers specialized in your domain.",
    },
    {
      heading: "Project-Focused Developers",
      desc: " Enhance productivity with developers dedicated to individual projects.",
    },
    {
      heading: "Transparent Communication",
      desc: "Foster clear and frequent communication throughout.",
    },
    {
      heading: "Data Security & IP  Protection",
      desc: "Ensure robust data security and safeguard intellectual property.",
    },
    {
      heading: "User-Centric Focus",
      desc: "Prioritize user experience and expedite time-to-market.",
    },
    {
      heading: "Troubleshooting and Decision Support",
      desc: "Offer assistance in troubleshooting and enhance decision-making.",
    },
    {
      heading: "Built-In Fault Tolerance",
      desc: "Reduce maintenance costs with built-in fault tolerance.",
    },
  ];
  const caseStudies: caseType[] = [
    {
      head: "Agile Transformation", subHead: "Accelerating Healthcare Tech: Agile Mastery",
      keywords: "Agile Transformation, Healthcare Technology, Agile Methodologies, Scrum Masters, Iterative Development, Enhanced Collaboration",
      mainHeading: "Accelerating Healthcare Tech: Agile Mastery",
      headPara: "Empowering Healthcare Innovation Through Agile Precision",
      intro: "In the realm of healthcare technology, the need for precision and speed often defines success. This case study delves into a transformative journey, where Agile methodology became the catalyst for reshaping traditional software development paradigms.",
      challenge: "Conventional software practices posed significant challenges for our client, a prominent healthcare technology company in Canada. Delays, inefficiencies, and a lack of adaptability were hindering progress. The challenge was clear: embrace a paradigm shift to ensure timely, innovative, and tech-driven healthcare solutions.",
      solution: "We introduced an intensive Agile immersion program, leveraging the expertise of seasoned Agile specialists and adept Scrum Masters. This immersive approach encompassed a tailored blend of Agile methodologies, iterative development, and focused training, culminating in a tech-focused Agile transformation.",
      key: [
        { heading: "Agile Framework Integration", content: "Seamlessly integrating Agile methodologies into the client's existing workflow, ensuring adaptability and flexibility." },
        { heading: "Scrum Master Leadership", content: "Skilled Scrum Masters guided development teams, optimizing Agile principles for healthcare tech challenges." },
        { heading: "Iterative Prototyping", content: "Agile sprints facilitated rapid prototyping, allowing real-time feedback integration and swift iteration for tech enhancements." },
        { heading: "Data-Driven Decisions", content: "Implementing Agile metrics and analytics, enabling data-driven decisions for continual tech refinement." },
        { heading: "Customized Training", content: "Tailored Agile workshops and training modules focused on healthcare tech nuances, ensuring in-depth understanding and application." },
        { heading: "Secure Collaboration", content: "Agile-aligned collaboration tools and secure communication channels, ensuring streamlined tech discussions and issue resolution." },
        { heading: "Continuous Integration", content: "Implementing CI/CD pipelines, fostering continuous integration of tech components for seamless system upgrades and enhancements." },
      ],
      conclusion: "Through this tech-focused Agile journey, our client transcended traditional barriers. The result? A healthcare technology ecosystem pulsating with innovation, driven by cutting-edge tech solutions, and poised to redefine the future of patient care. Agile methodology not only streamlined operations but also ignited a tech-driven revolution in the heart of healthcare innovation."
    },
    {
      head: "Marketing Optimization", subHead: "Unveiling Retail Potential: Data Analytics Transformation",
      keywords: "Marketing Optimization, Data Analytics Enhancement, Retail Industry, Data Scientists, Predictive Models, Customer Insights",
      mainHeading: "Unveiling Retail Potential: Data Analytics Transformation",
      headPara: "Revolutionizing Retail Success Through Data Insights",
      intro: "The world of retail revolves around understanding consumer behavior and market dynamics. In this case study, we explore how data analytics redefined the landscape for a prominent retail company in the United Kingdom.",
      challenge: "Our client was primed for success but faced a substantial challenge. They aimed to harness the power of data analytics to unlock valuable insights into customer behavior and supercharge their marketing strategies. However, the missing piece was in-house data science expertise, a gap that needed to be addressed to unlock their data's full potential.",
      solution: "The solution was an infusion of data scientists and skilled analysts who brought with them a wealth of data analytics knowledge and expertise. Their entry into the ecosystem marked a profound transformation in the retail landscape.",
      key: [
        { heading: "Data Science Expertise", content: "Skilled data scientists seamlessly integrated into the client's operations, offering their profound data analytics acumen." },
        { heading: "Customer Insights", content: "In-depth analysis of vast customer data, unearthing hidden insights into consumer behavior, preferences, and trends." },
        { heading: "Predictive Models", content: "Implementation of predictive models for precise forecasting, enhancing the accuracy of marketing campaigns and resource allocation." },
        { heading: "Marketing Optimization", content: "Crafting actionable recommendations to optimize marketing strategies, including customer segmentation and campaign targeting." },
        { heading: "Continuous Improvement:", content: "Establishing a continuous data-driven improvement cycle, ensuring the retail company stays agile and responsive to market changes." },
        { heading: "ROI Enhancement", content: "Enabling the client to maximize their Return on Investment (ROI) in marketing, ensuring every campaign is purposeful and fruitful." },
      ],
      conclusion: "Through the power of data analytics, our client achieved a transformation that extended beyond numbers and metrics. The retail landscape was now equipped with data-driven precision, enabling the client to make informed decisions and resonate more profoundly with their customers. Data became not just information but a catalyst for growth, innovation, and retail excellence."
    },
    {
      head: "QA Team Augmentation", subHead: "Elevating Software Quality: QA Team Augmentation Triumph",
      keywords: "QA Team Augmentation, Software Testing, Quality Enhancement, Accelerated Time to Market",
      mainHeading: "Elevating Software Quality: QA Team Augmentation Triumph",
      headPara: "Perfecting Quality Assurance for Timely Excellence",
      intro: "In the dynamic realm of software development, ensuring top-notch quality is the key to success. This case study delves into the journey of a software development company in India, aiming to fortify their software testing capabilities and elevate their quality standards.",
      challenge: "Our client boasted a growing portfolio of software products, a testament to their innovation and dedication. However, as the portfolio expanded, maintaining stringent quality standards became a formidable challenge. The constraints of limited testing resources began to cast a shadow, potentially jeopardizing their commitment to quality.",
      solution: "The solution lay in the augmentation of the Quality Assurance (QA) team. A squad of experienced QA engineers, well-versed in the art of software testing, was integrated into the client's existing team.",
      key: [
        { heading: "QA Expertise Augmentation", content: "The infusion of seasoned QA engineers, complementing the client's in-house team, and offering deep expertise in quality assurance." },
        { heading: "Comprehensive Testing", content: "A thorough and comprehensive testing regimen, including functional, performance, and security testing to leave no stone unturned." },
        { heading: "Quality Enhancement", content: "A pledge to enhance software quality, ensuring that every product that left the development pipeline met the highest standards." },
        { heading: "Time to Market Acceleration", content: "The collaborative effort resulted in accelerated time to market, enabling the client to be more responsive to market demands and opportunities." },
        { heading: "Streamlined Workflows", content: "The integration of the QA augmentation team was seamless, with workflows aligned to optimize efficiency and productivity." },
        { heading: "Cost-Effective Solution:", content: "The augmentation strategy proved to be cost-effective, providing maximum quality with optimal resource utilization." },
      ],
      conclusion: "With the augmentation of the QA team, our client achieved a pivotal transformation in their software development journey. Quality assurance was not just a box to tick; it became an intrinsic part of their software development philosophy. The accelerated time to market was not a mere outcome; it was a testament to their unwavering commitment to delivering excellence in every line of code. Quality became a tradition, and timely delivery became their hallmark."
    },
    {
      head: "IT Service Management", subHead: "Revolutionizing IT Service Management: A Saga of Seamless Operations",
      keywords: "IT Service Management, ITIL Framework, Service Optimization, Incident Management, Change Management, Problem Management, Enhanced Service Delivery, Minimal Downtime, Heightened User Satisfaction",
      mainHeading: "Revolutionizing IT Service Management: A Saga of Seamless Operations",
      headPara: "Elevating IT Service Excellence through Strategic ITIL Adoption",
      intro: "In the fast-paced world of financial services, operational efficiency is paramount. This case study delves into a leading financial services firm in Australia, navigating the intricate landscape of IT Service Management (ITSM). Their challenge: a labyrinth of inefficient IT service management processes causing disruptions and diminishing user satisfaction.",
      challenge: "The client grappled with the fallout of their inefficient IT service management processes. Frequent disruptions and dwindling user satisfaction were clear indicators of a system in dire need of transformation. In the pursuit of streamlined operations, they sought expertise in the gold standard of IT service management: ITIL framework implementation.",
      solution: "Enter our IT service management consultants armed with profound knowledge and hands-on experience. Their mission: to guide the client through a comprehensive ITIL adoption journey. The focus was not just on adoption but on meticulous implementation, where existing processes were dissected, inefficiencies identified, and strategic best practices in incident management, change management, and problem management were seamlessly woven into the fabric of their operations.",
      key: [
        { heading: "Strategic ITIL Implementation", content: "A meticulous approach to ITIL adoption, tailored to the client's unique needs, ensuring a seamless integration of ITIL best practices into their service management framework." },
        { heading: "Incident Management Mastery", content: "A strategic overhaul of incident management protocols, ensuring swift identification, resolution, and documentation of incidents, minimizing operational disruptions." },
        { heading: "Change Management Excellence", content: "Implementation of rigorous change management practices, ensuring that every change, no matter how minor, was meticulously planned, tested, and executed, reducing the risk of disruptions." },
        { heading: "Problem Management Prowess", content: "A dedicated focus on problem management, delving deep into the root causes of recurring issues, eliminating them at the source, and fortifying the system against future problems." },
        { heading: "Enhanced Service Delivery", content: "The cumulative effect of streamlined incident, change, and problem management was a profound enhancement in service delivery. Operations became smoother, disruptions reduced significantly, and user satisfaction soared." },
        { heading: "Minimal Downtime", content: "The meticulous implementation of ITIL best practices translated into minimal downtime. Operations continued seamlessly, ensuring business continuity even amidst changes and challenges." },
      ],
      conclusion: "Through the strategic adoption of ITIL framework, our client not only optimized their IT service management but also ushered in a new era of operational excellence. Disruptions became a thing of the past, user satisfaction reached new heights, and the firm stood as a beacon of efficient service delivery in the competitive landscape of financial services. Their journey wasn’t just about adopting best practices; it was about redefining what excellence meant in IT service management, setting a new standard that others aspired to achieve."
    },
  ]

  return (
    <Box className="pagesWrapper">

      <RequestQuotation
        open={openRequestQuotation}
        setOpen={setOpenRequestQuotation}
        popup_heading=""
      />

      <CaseStudyModal open={openCaseModal} setOpen={setOpenCaseModal} item={clickedCase} />

      {isMobile ? <MNavbar /> : <Navbar open={open} setOpen={setOpen} />}

      <Box className="pagesHeader mb-20 mb-40-m">
        <Grid container spacing={3} alignItems="center">
          <Grid xs={12} textAlign={"center"}>
            <h1 className="mb-6">
              IT Consulting & Staff <span>Augmentation</span>
            </h1>
            <p className="mb-6">
              Discover the key to unlocking your business's full potential with
              our comprehensive IT consulting and staff augmentation services.
              Let us connect you with top IT consultants and experienced
              software engineers, enabling your growth through the right
              technology solutions.
            </p>
            <Box textAlign={"center"} className="ctaBtn">
              <CustomButton
                text_name="Schedule a Call"
                class_name="blackLgBtn"
                onClickFunction={() => setOpenRequestQuotation(true)}
              />
            </Box>
          </Grid>

          <Grid xs={12}>
            <Box className="product_header_background">
              <img
                src="./images/consulting_header_img.svg"
                alt="product-development"
                className="prod-header-img"
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box className="consulting_services  mb-20 mb-40-m">
        <Grid container columnSpacing={3} rowSpacing={0} marginBottom={"24px"}>
          <Grid xs={12} textAlign={"center"}>
            <h1>
              Consulting <span>Services</span>
            </h1>
          </Grid>
        </Grid>
        <Grid container columnSpacing={3} rowGap={"24px"}>
          <Grid xs={12}>
            <ul>
              {consultingServicesArray.map((data, index) => (
                <li>
                  <Box className="box">
                    <span>
                      <img src={data.img_url} alt="" />
                    </span>
                    <Box>
                      <h4>{data.heading}</h4>
                      <p>{data.desc}</p>
                    </Box>
                  </Box>
                </li>
              ))}
            </ul>
          </Grid>
        </Grid>
      </Box>

      <Box className="staff_augmentation_services  mb-20 mb-40-m">
        <Grid container columnSpacing={3} rowSpacing={0} marginBottom={"24px"}>
          <Grid xs={12} textAlign={"center"}>
            <h1>
              Staff <span>Augmentation Services</span>
            </h1>
          </Grid>
        </Grid>
        <Grid
          container
          columnSpacing={3}
          marginBottom={window.innerWidth > 600 ? "48px" : "0"}
          alignItems={"center"}
        >
          <Grid xs={12} sm={6}>
            <img src="./images/staff_augmentation_services_images.svg" alt="" />
          </Grid>
          <Grid xs={12} sm={6}>
            <Box className="sa_service_box mb-6">
              <h4>Requirement Scrutiny</h4>
              <p>
                A meticulous examination of your project's prerequisites,
                encompassing the who, what, when, and why to enhance our team
                effectively.
              </p>
            </Box>
            <Box className="sa_service_box">
              <h4>Skill Alignment</h4>
              <p>
                Thoroughly assessing our team's skills, competencies, and
                experience to offer you the best-fitting match for your project.
              </p>
            </Box>
          </Grid>
        </Grid>

        <Grid container columnSpacing={3} marginBottom={"24px"}>
          <Grid xs={12} sm={6} md={4}>
            <Box className="sa_service_box">
              <h4>Candidate Appraisals</h4>
              <p>
                Conducting candidate interviews on your part to ensure alignment
                with your project's culture and specific requirements.
              </p>
            </Box>
          </Grid>
          <Grid xs={12} sm={6} md={4}>
            <Box className="sa_service_box">
              <h4>Competency Evaluation</h4>
              <p>
                Providing the opportunity for skills evaluation via a test
                assignment for candidates you're uncertain about.
              </p>
            </Box>
          </Grid>
          <Grid xs={12} sm={12} md={4}>
            <Box className="sa_service_box">
              <h4>Onboarding and Integration</h4>
              <p>
                Our developers seamlessly assimilate into your organization,
                embracing your tools, processes, best practices
              </p>
            </Box>
          </Grid>
        </Grid>

        <Grid container columnSpacing={3} rowSpacing={0} marginBottom={"12px"}>
          <Grid xs={12} textAlign={"center"}>
            <h2>
              Recruitment <span>Options</span>
            </h2>
          </Grid>
        </Grid>
        <Grid container columnSpacing={3} rowSpacing={0}>
          <Grid lg={3} sm={6} xs={12} textAlign={"center"}>
            <Box className="req_option_box">
              <span>
                <img src="./images/recruitment_icon1.svg" alt="" />
              </span>
              <h4>Web Developers for Hire</h4>
              <p>Experts in React, Angular, Node.js, and JavaScript.</p>
            </Box>
          </Grid>
          <Grid lg={3} sm={6} xs={12} textAlign={"center"}>
            <Box className="req_option_box">
              <span>
                <img src="./images/recruitment_icon2.svg" alt="" />
              </span>
              <h4>Mobile App Developers for Hire</h4>
              <p> Mobile App Developers for Hire:</p>
            </Box>
          </Grid>
          <Grid lg={3} sm={6} xs={12} textAlign={"center"}>
            <Box className="req_option_box">
              <span>
                <img src="./images/recruitment_icon3.svg" alt="" />
              </span>
              <h4>DevOps Engineers for Hire</h4>
              <p>Proficient in AWS, Microsoft Azure, and Kubernetes.</p>
            </Box>
          </Grid>
          <Grid lg={3} sm={6} xs={12} textAlign={"center"}>
            <Box className="req_option_box">
              <span>
                <img src="./images/recruitment_icon4.svg" alt="" />
              </span>
              <h4>QA Specialists for Hire</h4>
              <p>Seasoned in automated QA.</p>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box className="core-goals  mb-20 mb-40-m">
        <Grid container columnSpacing={3} rowSpacing={0} marginBottom={"24px"}>
          <Grid xs={12} textAlign={"center"}>
            <h1>
              Our <span>Core Goals</span>
            </h1>
          </Grid>
        </Grid>
        <Grid container columnSpacing={3} alignItems={"center"}>
          <Grid xs={12}>
            <Box className="core-goals-section">
              <ul>
                <li>
                  <img
                    src="./images/core-goals_img.svg"
                    alt=""
                    style={{ margin: "auto" }}
                  />
                </li>

                {coreGoalsArray.map((data, index) => (
                  <li>
                    <Box className="cg_box">
                      <h4>{data.heading}</h4>
                      <p>{data.desc}</p>
                    </Box>
                  </li>
                ))}
              </ul>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Grid
        container
        spacing={3}
        justifyContent="center"
        marginBottom={"80px"}
        className="mb-40-m"
      >
        <Grid xs={12} textAlign="center" marginBottom={"16px"}>
          <h1 className="mb-6">
            Case <span>Studies</span>
          </h1>
          <p>
            In the realm of challenges, every case study is a chapter of
            solutions, each offering its unique wisdom to those who seek the
            knowledge it holds.
          </p>
        </Grid>
        {
          caseStudies?.map((item) => (
            <Grid xs={6} lg={3} textAlign="center">
              <CaseStudyBox
                color="#79D05A"
                title={item?.head}
                description={item?.subHead}
                onClickFunction={() => {
                  setOpenCaseModal(true);
                  setClickedCase(item);
                }}
              />
            </Grid>

          ))
        }

        {/* <Grid xs={6} lg={3} textAlign="center">
          <CaseStudyBox
            color="#79D05A"
            title="Marketing Optimization"
            description="Unveiling Retail Potential: Data Analytics Transformation"
            onClickFunction={() => setCaseStudyStatus2(true)}
          />
        </Grid>
        <Grid xs={6} lg={3} textAlign="center">
          <CaseStudyBox
            color="#79D05A"
            title="QA Team Augmentation"
            description="Elevating Software Quality: QA Team Augmentation Triumph"
            onClickFunction={() => setCaseStudyStatus3(true)}
          />
        </Grid>
        <Grid xs={6} lg={3} textAlign="center">
          <CaseStudyBox
            color="#79D05A"
            title="IT Service Management"
            description="Revolutionizing IT Service Management: A Saga of Seamless Operations"
            onClickFunction={() => setCaseStudyStatus4(true)}
          />
        </Grid> */}
      </Grid>

      <Box className="design_quotation">
        <RequestQuotationComponent />
      </Box>

      <Footer />
    </Box>
  );
}

export default ItConsulting;
