import { URL_CONSTANTS } from "../SupportingFiles/UrlConstant";
import { PostAPI } from "./AxiosAPIInstance/AxiosInstance";


const FormServices = (onSuccess: Function, onError: Function, dto: any) => {
  PostAPI.call(
    URL_CONSTANTS.SUBMIT_REQUEST,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const QuotationFormService = (onSuccess: Function, onError: Function, dto: any) => {
  PostAPI.call(
    URL_CONSTANTS.ENQUIRY_REQUEST,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};

export const FORM_SERVICES = {
  FormServices, QuotationFormService
};
