import { Box } from "@mui/material";
import React, { useState } from "react";
import MNavbar from "../../Components/Mobile/Navbar/MNavbar";
import Navbar from "../../Components/Desktop/Navbar/Navbar";
import useIsMobile from "../../SupportingFiles/MobileProvider";
import Grid from "@mui/material/Unstable_Grid2";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "../../CommonSCSS/common.scss";
import CustomButton from "../../Components/Common/CustomButton/CustomButton";
import Footer from "../../Components/Desktop/Footer/Footer";
import CogBox from "../HealthCare/CogBox/CogBox";
import ContentList from "../HealthCare/ContentList/ContentList";
import NumberBox from "../HealthCare/NumberBox/NumberBox";
import DashBorderBox from "../HealthCare/DashBorderBox/DashBorderBox";
import RequestQuotationComponent from "../../Components/Common/RequestComponent/RequestQuotationComponent";
import RequestQuotation from "../../Components/Common/RequestQuotationPopup/RequestQuotation";
import CaseStudyModal, { caseType } from "../../Components/Common/CaseStudy/CaseStudyModal";

function WellnessandFitness() {
  const [open, setOpen] = useState<boolean>(false);
  const isMobile = useIsMobile();
  const responsive = {
    desktop: {
      breakpoint: { max: 4000, min: 1200 },
      items: 3,
      paritialVisibilityGutter: 60,
    },
    tablet: {
      breakpoint: { max: 1199, min: 821 },
      items: 2,
      paritialVisibilityGutter: 40,
    },
    mobile: {
      breakpoint: { max: 820, min: 0 },
      items: 1,
      paritialVisibilityGutter: 40,
    },
  };
  const tailoredHealthcare = {
    mobile: {
      breakpoint: { max: 4000, min: 0 },
      items: 1,
    },
  };
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const tabdata = [
    {
      label: "Responsive Websites & Mobile Apps/Web Portals",
      imgsrc: "./images/user-icon.svg",
      content: {
        imgsrc: "./images/laptop.svg",
        description:
          "Manage medical data with ease. Our custom healthcare software solutions allow you to manage patient profiles, medical histories, treatment plans, prescriptions, and client appointments.",
        items: [
          "Secure medical document management",
          "Patient profiles and medical histories",
          "E-prescriptions and treatment plans",
          "Automated appointment scheduling",
          "Medical billing, CRM, and HIS integrations",
          "Robust reporting, analytics, and offline access",
        ],
      },
    },
    {
      label: "Enterprise Web Applications",
      imgsrc: "./images/call-icon.svg",
      content: {
        imgsrc: "./images/laptop.svg",
        description:
          "This stage is about ideation and planning. We look into the market and study key players. Then we round up the features you want to add and plan all the work. After this stage we are ready for any possible challenges.",
        items: [
          "HIPAA-compliant video consultations",
          "Virtual visits for non-emergency concerns",
          "Instant messaging for quick medical advice",
          "E-prescriptions",
          "Automated appointment scheduling",
          "Integration with EMR systems",
        ],
      },
    },
    {
      label: "Data Analytics",
      imgsrc: "./images/doctor-icon.svg",
      content: {
        imgsrc: "./images/laptop.svg",
        description:
          "Ensure high-quality patient care no matter the location. We specialize in implementing secure and HIPAA-compliant video consultations, instant messaging, and remote monitoring. Enhance convenience with offline charting, automated patient scheduling, and seamless EMR integration.",
        items: [
          "On-demand appointments for urgent needs",
          "Remote video consultations",
          "Location-based search for nearby doctors",
          "Medical billing and in-app payments",
          "E-prescriptions",
          "Doctor ratings & feedback",
        ],
      },
    },
    {
      label: "AI Tools and ChatBots",
      imgsrc: "./images/mobiles-icon.svg",
      content: {
        imgsrc: "./images/laptop.svg",
        description:
          "Provide healthcare and wellness solutions wherever you are. Our healthcare app development company specializes in creating various mobile health (mHealth) apps. These apps offer users swift and convenient access to essential healthcare services, enable fitness tracking, medication management with timely reminders, and foster engagement within health communities.",
        items: [
          "Fitness and activity tracking",
          "Medical charting",
          "Telemedicine",
          "Appointments scheduling",
          "Mental health and meditations",
          "Remote patient monitoring",
        ],
      },
    },
    {
      label: "IOT Sensors",
      imgsrc: "./images/signin-icon.svg",
      content: {
        imgsrc: "./images/laptop.svg",
        description:
          "Enable users to conveniently access their complete personal health information from a single location. We prioritize secure storage for health records, prescriptions, test results, and doctor visit data. Additionally, we offer the option to seamlessly integrate your portal with medical billing, insurance management, patient scheduling, real-time chat functionality, and eLearning resources.",
        items: [
          "Personal health records",
          "Test results and doctor's notes",
          "Appointment scheduling",
          "Telemedicine integrations",
          "Built-in chat",
          "Billing and insurance",
        ],
      },
    },
    {
      label: "UI/UX Design",
      imgsrc: "./images/database-icon.svg",
      content: {
        imgsrc: "./images/laptop.svg",
        description:
          "Create a tailored medical solution with a flexible and expandable modular structure. We'll assist you in choosing the most suitable pre-built modules, integrating existing legacy systems, and crafting personalized dashboards. You can count on robust data security, compliance, and seamless data sharing through data warehouse and Master Data Management (MDM) solutions.",
        items: [
          "HIPAA-compliant video consultations",
          "Data interoperability",
          "HIPAA compliance",
          "Third-party integrations",
          "Business process automation",
          "AI, and LLM",
          "MDM & data warehousing",
        ],
      },
    },
  ];
  const [openRequestQuotation, setOpenRequestQuotation] = useState<boolean>(false);
  const [openCaseModal, setOpenCaseModal] = useState<boolean>(false);
  const [clickedCase, setClickedCase] = useState<caseType>({
    head: "", subHead: "", mainHeading: "", keywords: "", headPara: "", intro: "",
    challenge: "", solution: "", key: [], conclusion: ""
  });
  const caseStudies: caseType[] = [
    {
      head: "", subHead: "",
      keywords: "Wellness and Fitness App Development, Personalized Healthcare Solutions, AI ML Data Analytics, Tailored Workout Plans, Scalable Solutions, Security Measures, Personalized Diet Monitoring, Guided Meditation Courses, Cutting-edge Technologies,",
      mainHeading: "Evervent's Breakthrough: Transforming Wellness App Development with Personalized Wellness & Lifestyle Solutions",
      headPara: "Dive into Evervent's Success Story: Crafting Custom Fitness Plans, Smart Diet Monitoring, and Serene Guided Meditation using AI, ML, and Data Analytics for an Unmatched User Experience.",
      intro: "At Evervent, as a competitive wellness and fitness app development company, we address the rising demand for personalized healthcare solutions. Our industry-specific expertise encompasses features like custom fitness plans, diet monitoring, and guided meditation. Leveraging AI, ML, and Data Analytics, we surpass expectations, ensuring optimal performance and a seamless user experience.",
      challenge: "At Evervent, we understand the intricacies of wellness and fitness app development. Navigating challenges becomes a seamless process when you prioritize integrating features that bring lasting satisfaction to users. Tailoring personalized workout plans to individual needs is our commitment, fostering a genuine connection to help users achieve their wellness and fitness goals. With a diverse set of features, including those driven by AI and user-centric design, we not only stand out but also pave the way for the success of your wellness and fitness app.",
      solution: "At Evervent, our wellness and fitness app solutions go beyond expectations. We offer tailored, secure, and scalable solutions designed to meet your specific needs. With a commitment to the latest technology and robust security measures, our solutions ensure a seamless and protected wellness and fitness experience for users and organizations alike.",
      key: [
        { heading: "Optimized Scalability", content: "We recognize the ever-changing wellness and fitness needs and place a strong emphasis on creating highly scalable applications. With our adaptable approach, our solutions can easily adjust to your changing requirements and incorporate the latest technology updates. Rest assured, our wellness and fitness software application services are designed to cater specifically to your individual needs." },
        { heading: "Unrivaled Security", content: "As a distinguished wellness and fitness mobile app development company, we prioritize the utmost security by implementing next-level data protection measures. Our tailor-made solutions cater to various wellness and fitness institutions, guaranteeing the protection of sensitive information. We deploy strong data encryption, multi-factor authentication, and conduct regular security audits to ensure the safety of your wellness and fitness app." },
        { heading: "Personalized Workout Plans", content: "These plans consider factors such as fitness levels, health conditions, and individual goals. Exercises are chosen to suit the user's preferences and gradually adapt based on progress." },
        { heading: "Seamless Diet Monitoring", content: "The feature involves easy input of dietary choices, tracking calorie intake, nutritional content, and providing insights into areas for improvement. Users can set dietary goals and receive personalized recommendations." },
        { heading: "Guided Meditation Courses", content: "Structured sessions with guided audio or visual elements, offering meditation practices for stress relief, focus enhancement, and emotional well-being. Users can choose sessions based on their needs and preferences." },
        { heading: "Cutting-edge Technologies (AI, ML, Data Analytics)", content: "Utilizes AI for personalized recommendations, ML for adapting workout plans based on user behavior, and Data Analytics for deriving insights into user habits to enhance the overall effectiveness of the wellness app." },
        { heading: "Holistic and Intelligent Wellness Solution", content: "Integrates physical fitness, nutrition, and mental well-being into a cohesive approach. The app intelligently analyzes user data to offer tailored recommendations, fostering a holistic approach to wellness." },
        { heading: "User Satisfaction", content: "Constantly seeks user feedback for improvements, ensures a responsive customer support system, and evolves based on user preferences to maintain a high level of satisfaction." },
        { heading: "User-Centric Design", content: "Prioritizes user experience through intuitive interfaces, easy navigation, and aesthetically pleasing visuals, creating an app that users find enjoyable and easy to use." },
      ],
      conclusion: "The wellness and fitness industry has undergone a transformation through app development services, granting users unparalleled access to resources and providing organizations with fresh revenue streams and engagement prospects. As technology advances, the future of wellness and fitness apps holds the promise of continued innovation and digital transformation, solidifying their role as a vital component of the modern wellness and fitness experience. Exploring the realm of wellness and fitness application development can profoundly impact your organization"
    },
    {
      head: "", subHead: "",
      keywords: "Innovation in Wellness Apps, Cutting-edge Technologies, Holistic Wellness Approach, User Satisfaction, User-Centric Design, Digital Transformation",
      mainHeading: "Tech Wellness 360: A Paradigm Shift in Fitness Management",
      headPara: "Unleashing Next-Gen Technology for Personalized Fitness and Holistic Well-Being",
      intro: "In the realm of wellness and fitness, individuals are increasingly turning to technology to enhance their health journeys. Our case study revolves around a scenario where a wellness center faced challenges in efficiently managing their operations, client engagement, and personalized fitness programs. Recognizing the need for a comprehensive tech solution, they approached us for assistance.",
      challenge: "The wellness center was grappling with manual and time-consuming processes for client onboarding, scheduling, and progress tracking. They were also struggling to foster meaningful engagement between clients and fitness trainers, hindering the overall effectiveness of their programs. The absence of a centralized system made it difficult to analyze data and tailor fitness plans to individual needs.",
      solution: "To address these challenges, we implemented a robust tech service, tailored to the specific needs of the wellness center. Our solution consisted of a holistic fitness management platform that integrated seamlessly into their existing workflows.",
      key: [
        { heading: "Client Management System", content: "We introduced a centralized database that streamlined client onboarding, making it efficient and error-free. This system allowed the wellness center to maintain detailed records of each client's health history, preferences, and progress." },
        { heading: "Automated Scheduling", content: "Our solution incorporated an automated scheduling feature that simplified the process of booking fitness sessions. Clients could easily book slots, and trainers received real-time updates, minimizing scheduling conflicts." },
        { heading: "Personalized Fitness Plans", content: "Leveraging data analytics, our system provided insights into each client's fitness journey. Trainers could access personalized data, enabling them to tailor workouts and nutritional advice based on individual progress and goals." },
        { heading: "Engagement Portal", content: "To enhance client-trainer communication, we implemented an interactive portal. Clients could track their progress, receive real-time feedback, and communicate with trainers, fostering a sense of accountability and motivation." },
        { heading: "Integration with Wearables", content: "Recognizing the popularity of fitness wearables, our solution seamlessly integrated with various devices. This allowed clients to sync their data effortlessly, providing trainers with accurate insights into daily activities and health metrics." },
      ],
      conclusion: "The implementation of our tech solution revolutionized the wellness center's operations. Efficiency improved as manual tasks were automated, and the personalized approach to fitness plans significantly enhanced client satisfaction. The engagement portal not only strengthened the client-trainer relationship but also empowered individuals to take charge of their well-being. In conclusion, our tech service not only addressed the immediate challenges but set the foundation for a tech-driven, client-centric approach to wellness and fitness."
    },
  ]

  return (
    <Box className="pagesWrapper">

      <CaseStudyModal open={openCaseModal} setOpen={setOpenCaseModal} item={clickedCase} />

      <RequestQuotation open={openRequestQuotation} setOpen={setOpenRequestQuotation} popup_heading="" />

      {isMobile ? <MNavbar /> : <Navbar open={open} setOpen={setOpen} />}

      <Box className="pagesHeader mb-20 mb-40-m">
        <Grid container spacing={3} alignItems="center">
          <Grid xs={12} md={6}>
            <h6>WELLNESS & FITNESS</h6>
            <h1 className="my-6">
              Evervent: Your one-stop solution for seamless fitness app
              development, web design. Transform your idea into reality
              effortlessly.
            </h1>
            <p>
              A Company Dedicated to Developing Fitness & Wellness Software for
              You and Your Users.
            </p>
            <div className="ctaBtn mt-6">
              <CustomButton text_name="Get Started" class_name="blackLgBtn" onClickFunction={()=>setOpenRequestQuotation(true)} />
            </div>
          </Grid>
          <Grid xs={12} md={6}>
            <img
              src="./images/wellnessFitness.svg"
              alt="healthcare"
              style={{ margin: "auto" }}
            />
          </Grid>
        </Grid>
      </Box>

      <Grid container spacing={3} marginBottom={"24px"}>
        <Grid xs={12}>
          <h2>Fitness App Development that Motivates and Engages Users</h2>
          <p>
            Lead users in the direction of living healthier lifestyles. Utilize
            our fitness app development services to design tailored apps that
            transform your ideas into reality. Enhance them with cutting-edge
            features and functionalities!
          </p>
        </Grid>
      </Grid>

      <Box className="partialSlider mb-20 mb-40-m">
        <Carousel
          partialVisbile
          responsive={responsive}
          renderArrowsWhenDisabled={true}
          swipeable={true}
          draggable={true}
          customTransition={"transform 800ms ease-in-out"}
        >
          <div className="px-4">
            <CogBox
              color="#2F81DD"
              title="Fitness Tracking Apps"
              description="We create the best fitness tracking app with motion trackers to measure running, walking, cycling, and swimming and get all info on distance travelled, speed, and amount of calories burned."
            />
          </div>
          <div className="px-4">
            <CogBox
              color="#5BAC3F"
              title="Nutrition and Diet Apps"
              description="We build the best nutrition and diet apps that provide wide information on various nutrients and supplements and help people track to keep records of their diet and supplements."
            />
          </div>
          <div className="px-4">
            <CogBox
              color="#F6A13C"
              title="AI-based Personal Trainer Apps"
              description="We build AI-based personal trainer apps that provide virtual trainers powered by artificial intelligence and help achieve your fitness goals in the right way."
            />
          </div>
          <div className="px-4">
            <CogBox
              color="#FF725E"
              title="Fitness Gamification Apps"
              description="We design and develop the best fitness gamification apps that make the gym more interesting and fun where no one will ever skip going to the gym."
            />
          </div>
        </Carousel>
      </Box>

      <Grid container spacing={3} marginBottom={"80px"} className="mb-40-m">
        <Grid xs={12} textAlign="center">
          <h2>
            WHY DO YOU NEED FITNESS AND WELLNESS APP DEVELOPMENT SERVICES?
          </h2>
        </Grid>
        <Grid
          xs={12}
          display={"flex"}
          flexWrap={"wrap"}
          gap={"24px"}
          alignItems="flex-start"
          alignSelf="stretch"
          marginTop="24px"
        >
          <ContentList>Reach more customers</ContentList>
          <ContentList>
            Enter a new market or broaden your market segment
          </ContentList>
          <ContentList>Earn larger revenue</ContentList>
          <ContentList>Provide better services to your clients</ContentList>
          <ContentList>Remain relevant</ContentList>
          <ContentList>An adored mobility solution</ContentList>
          <ContentList>Secured user health data</ContentList>
          <ContentList>
            Enhanced branding for superior marketing outcomes
          </ContentList>
        </Grid>
      </Grid>

      {!isMobile ? (
        <Box className="verticalTabs py-20 mb-20 mb-40-m">
          <Grid container spacing={3}>
            <Grid xs={12} textAlign="center">
              <h2>Deliverables</h2>
              <p className="mb-10">
                We design the most beautiful, feature-rich, and futuristic
                smartwatches and fitness apps with the use of modern technology
                on various platforms, like.
              </p>
            </Grid>
            <Grid xs={12} display="flex" gap="24px">
              <ul
                className="tabul"
                style={{
                  maxWidth: "420px",
                  flex: "1 0 0",
                  alignSelf: "flex-start",
                }}
              >
                {tabdata.map((data, index) => (
                  <li
                    onClick={() => {
                      setSelectedTab(index);
                    }}
                  >
                    <div className="tabs">
                      <div className="icon">
                        <div>
                          <img src={data.imgsrc} />
                        </div>
                      </div>
                      <div
                        className={
                          selectedTab === index ? "title active" : "title"
                        }
                      >
                        <h5>{data.label}</h5>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
              <div className="contents">
                <img src={tabdata[selectedTab].content.imgsrc} />
                <p>{tabdata[selectedTab].content.description}</p>
                <ul>
                  {tabdata[selectedTab].content.items.map((data, index) => (
                    <li>{data}</li>
                  ))}
                </ul>
                <Box className="ctaBtn mt-8">
                  <CustomButton
                    text_name={"Inquire"}
                    class_name="blueMdBtn"
                    onClickFunction={() => setOpenRequestQuotation(true)}
                  />
                </Box>
              </div>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box className="verticalTabs py-10 mb-10 ">
          <Grid container spacing={3}>
            <Grid xs={12} textAlign="center">
              <h2>Deliverables</h2>
              <p>
                We design the most beautiful, feature-rich, and futuristic
                smartwatches and fitness apps with the use of modern technology
                on various platforms, like.
              </p>
            </Grid>
            <Grid xs={12}>
              <Box className="partialSlider mt-6">
                <Carousel
                  responsive={tailoredHealthcare}
                  renderArrowsWhenDisabled={true}
                  autoPlay={true}
                >
                  {tabdata.map((data) => (
                    <div className="contents">
                      <img src={data.content.imgsrc} />
                      <h4>{data.label}</h4>
                      <p>{data.content.description}</p>
                      <ul>
                        {data.content.items.map((data, index) => (
                          <li>{data}</li>
                        ))}
                      </ul>
                      <Box className="ctaBtn mt-8">
                        <CustomButton
                          text_name={"Inquire"}
                          class_name="blueMdBtn"
                          onClickFunction={() => setOpenRequestQuotation(true)}
                        />
                      </Box>
                    </div>
                  ))}
                </Carousel>
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}

      <Grid container spacing={3} marginBottom={"80px"}>
        <Grid xs={12} textAlign={"center"}>
          <h2>
            Exploring Excellence: A Glimpse into Our Impressive Fitness App
            Portfolio
          </h2>
          <p>
            Have a look at our web and mobile solutions for the sport & wellness
            industry.
          </p>
        </Grid>
        <Grid xs={12}>
          <Box display="flex" flexWrap="wrap" gap="40px">
            <NumberBox
              title="ProFit Innovate Trainer App"
              number={1}
              listitem1="Hosts bodyweight workout videos"
              listitem2="Allows activity and goal tracking"
              listitem3="Enables social sharing for fitness updates"
            />
            <NumberBox
              title="NutriNosh Navigator"
              number={2}
              listitem1="Create grocery lists based on healthy meal plans."
              listitem2="Find nearby restaurants that offer alternative meal options."
              listitem3="Provides coach info and schedules meal alarms"
              listitem4="The system provides information about coaches and schedules alarms for meals."
            />
            <NumberBox
              title="Create an OCR-powered Nutrition App."
              number={3}
              listitem1="Websites and photos of recipes are scanned."
              listitem2="Extracts nutritional information from every cooked meal."
              listitem3="Combine the nutritional value of ingredients."
            />
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={3} marginBottom={"80px"}>
        <Grid xs={12} md={5}>
          <img src="./images/fitness_img.svg" width="100%" alt="img" />
        </Grid>
        <Grid xs={12} md={7} display="flex" flexDirection="column" gap="24px">
          <h2>Best Fitness App Development Services.</h2>
          <h5 style={{ fontWeight: "600" }}>
            Help your users better themselves by recording their achievements
            and tracking their habits.
          </h5>
          <DashBorderBox
            title="Project assessment"
            description="This stage is about ideation and planning. We look into the market and study key players. Then we round up the features you want to add and plan all the work. After this stage we are ready for any possible challenges."
          />
          <DashBorderBox
            title="Development"
            description="We code in sprints. Those are 2 week long and after each one you can see the results of development and give feedback. We assess the actual app performance by beta testing in TestFlight or Google Play."
          />
          {/* case study box */}
          <div className="ctaBtn">
            <CustomButton
              text_name="View Details"
              class_name="whiteSmBtn"
              onClickFunction={() => {
                setOpenCaseModal(true);
                setClickedCase(caseStudies[0]);
              }}
            />
          </div>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={3}
        flexDirection="row-reverse"
        marginBottom={"80px"}
        className="mb-40-m"
      >
        <Grid xs={12} md={5}>
          <img src="./images/fitness_work.svg" width="100%" alt="img" />
        </Grid>
        <Grid xs={12} md={7} display="flex" flexDirection="column" gap="24px">
          <h2>Tech Wellness 360: A Paradigm Shift in Fitness Management</h2>
          <h5 style={{ fontWeight: "600" }}>
            Unleashing Next-Gen Technology for Personalized Fitness and Holistic
            Well-Being
          </h5>
          <DashBorderBox
            title="Project assessment"
            description="The wellness center was grappling with manual and time-consuming processes for client onboarding, scheduling, and progress tracking. They were also struggling to foster meaningful engagement between clients and fitness trainers, hindering the overall effectiveness of their programs. The absence of a centralized system made it difficult to analyze data and tailor fitness plans to individual needs."
          />
          <DashBorderBox
            title="Development"
            description="To address these challenges, we implemented a robust tech service, tailored to the specific needs of the wellness center. Our solution consisted of a holistic fitness management platform that integrated seamlessly into their existing workflows."
          />
          {/* case study box */}
          <div className="ctaBtn">
            <CustomButton
              text_name="View Details"
              class_name="whiteSmBtn"
              onClickFunction={() => {
                setOpenCaseModal(true);
                setClickedCase(caseStudies[1]);
              }}
            />
          </div>
        </Grid>
      </Grid>

      <Box className="design_quotation">
        <RequestQuotationComponent />
      </Box>

      <Footer />
    </Box>
  );
}

export default WellnessandFitness;
