import { Box, Link, Modal } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import "./CaseStudy.scss";
import InsetBox from "./InsetBox";

export type caseType = {
    head: string, subHead: string, keywords: string, mainHeading: string, headPara: string, intro: string, challenge: string,
    solution: string, key: { heading: string, content: string }[], conclusion: string
}

function CaseStudyModal({ open, setOpen, item }: { open: boolean; setOpen: Function, item: caseType }) {
    return (
        <Modal
            className="caseStudy"
            open={open}
            // onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className="innerWrapper">
                <Box className="content">
                    <Link
                        className="closePopup"
                        onClick={() => {
                            setOpen(false);
                        }}
                    />
                    <Box
                        sx={{ padding: "48px", textAlign: "center" }}
                        className="pageTitle"
                    >
                        <h3
                            style={{
                                display: "inline-block",
                                borderBottom: "1px solid #ccc",
                                padding: "24px 40px 12px",
                            }}
                        >
                            Case Study
                        </h3>
                    </Box>

                    <Box
                        className="innerContent"
                        sx={{
                            background: "#fff",
                            borderRadius: "0px 0px 60px 60px",
                            overflow: "hidden",
                            display: "flex",
                            flexDirection: "column",
                            gap: "40px",
                            paddingBottom: "40px",
                        }}
                    >
                        <Grid container spacing={3}>
                            <Grid xs={12} sm={8}>
                                <h5
                                    style={{
                                        fontSize: "20px",
                                        fontWeight: "400",
                                        color: "#7165E4",
                                        marginBottom: "24px",
                                    }}
                                >
                                    <span style={{ fontWeight: "700", }} >{item?.keywords}</span>
                                </h5>
                                <h2>{item?.mainHeading}</h2>
                                <p>{item?.headPara}</p>
                            </Grid>
                            <Grid xs={12} sm={4}>
                                <img src="./images/case-study.svg" width="100%" />
                            </Grid>
                        </Grid>

                        <Box
                            className="greyBox" sx={{ display: "flex", gap: "24px", }}>
                            <Box
                                sx={{
                                    background: "#fff",
                                    borderRadius: "12px",
                                    padding: "24px",
                                    width: "50%",
                                    flex: "1 0 0",
                                    // minWidth: "280px",
                                }}
                                className="left-prt"
                            >
                                <h5
                                    style={{
                                        fontSize: "20px",
                                        fontWeight: "bold",
                                        color: "#7165E4",
                                        marginBottom: "12px",
                                    }}
                                >
                                    Introduction
                                </h5>
                                <p>{item?.intro}</p>
                            </Box>
                            <Box
                                sx={{
                                    background: "#fff",
                                    borderRadius: "12px",
                                    padding: "24px",
                                    boxShadow: "-8px 8px 24px 0px rgba(113, 101, 228, 0.20)",
                                    width: "50%",
                                    flex: "1 0 0",
                                    // minWidth: "280px",
                                }}
                                className="right-prt"
                            >
                                <h5
                                    style={{
                                        fontSize: "20px",
                                        fontWeight: "bold",
                                        color: "#7165E4",
                                        marginBottom: "12px",
                                    }}
                                >
                                    The Challenge
                                </h5>
                                <p>{item?.challenge}</p>
                            </Box>
                        </Box>

                        <Grid container spacing={3}>
                            <Grid xs={12} lg={12}>
                                <h3>Our Solution </h3>
                                <p>{item?.solution}</p>
                            </Grid>
                        </Grid>

                        <Grid container spacing={3} justifyContent="center">
                            <Grid xs={12}>
                                <h3>Key Features </h3>
                                {/* <p>
                  Our tailored digital solution brought forth numerous
                  advantages for Rowley Autos:
                </p> */}
                            </Grid>
                            {
                                item?.key?.map((data) => (
                                    <Grid xs={12} md={6} lg={4}>
                                        <InsetBox
                                            title={data?.heading}
                                            description={data?.content}
                                        />
                                    </Grid>
                                ))
                            }
                        </Grid>

                        <Grid container spacing={3}>
                            <Grid xs={12}>
                                <h3>Conclusion </h3>
                                <p>{item?.conclusion}</p>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </Modal>
    );
}

export default CaseStudyModal;
