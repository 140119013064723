import { Box } from "@mui/material";
import { useState, useRef, useEffect } from "react";
import MNavbar from "../../Components/Mobile/Navbar/MNavbar";
import Navbar from "../../Components/Desktop/Navbar/Navbar";
import useIsMobile from "../../SupportingFiles/MobileProvider";
import Grid from "@mui/material/Unstable_Grid2";
import "../../CommonSCSS/common.scss";
import CustomButton from "../../Components/Common/CustomButton/CustomButton";
import Footer from "../../Components/Desktop/Footer/Footer";
import DoneIcon from "@mui/icons-material/Done";
import CaseStudyBox from "../HealthCare/CaseStudyBox/CaseStudyBox";
import RequestQuotation from "../../Components/Common/RequestQuotationPopup/RequestQuotation";
import RequestQuotationComponent from "../../Components/Common/RequestComponent/RequestQuotationComponent";
import CaseStudyModal, { caseType } from "../../Components/Common/CaseStudy/CaseStudyModal";

function ProductDevelopment() {
  const [open, setOpen] = useState<boolean>(false);
  const isMobile = useIsMobile();
  const csdRef = useRef(null);
  const srsRef = useRef(null);
  const pcsRef = useRef(null);
  const bdRef = useRef(null);
  const masRef = useRef(null);
  const fdRef = useRef(null);

  const handleScrollToSection = (ref: any) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };
  const developmentSpectrumArray = [
    {
      img_url: "./images/customer_software_development_icon.svg",
      heading: "Custom Software Development",
      desc: "Crafting bespoke software solutions using Agile and DevOps methodologies for rapid deployment and seamless scalability.",
      reff: csdRef,
    },
    {
      img_url: "./images/Software_Re-engg_Support.svg",
      heading: "Software Re-engineering & Support",
      desc: " Modernizing legacy codebases through cloud-native optimization, continuous integration, and robust support.",
      reff: srsRef,
    },
    {
      img_url: "./images/Premium_Custom_Solutions.svg",
      heading: "Premium Custom Solutions",
      desc: "Developing cutting-edge software solutions integrating AI, IoT, and machine learning to address intricate business requirements with state-of-the-art technologies.",
      reff: pcsRef,
    },
    {
      img_url: "./images/Blockchain_Development.svg",
      heading: "Blockchain Development",
      desc: " Building decentralized applications on distributed ledgers, implementing smart contracts for autonomous transactions, and ensuring cryptographic security and data immutability.",
      reff: bdRef,
    },
    {
      img_url: "./images/Mobile_App_Solution.svg",
      heading: "Mobile App Solution",
      desc: "Creating cross-platform mobile applications with native and hybrid frameworks, guaranteeing responsive design, intuitive user experiences, and cloud service integration.",
      reff: masRef,
    },
    {
      img_url: "./images/Fintech_Development.svg",
      heading: "Fintech Development",
      desc: " Innovating financial technology solutions encompassing blockchain-based cryptocurrencies, digital wallets, and robo-advisors for optimized financial management and disruptive market solutions.",
      reff: fdRef,
    },
  ];
  const pdApproachArray = [
    {
      img_url: "./images/pd_approach_icon1.svg",
      heading: "Ideation and Market Research",
      desc: "Begin with innovative technology-driven ideas and comprehensive market research to address emerging trends and customer needs.",
    },
    {
      img_url: "./images/pd_approach_icon2.svg",
      heading: "Proof of Concept (PoC):",
      desc: "Develop a PoC to validate technical feasibility, ensuring core tech elements work.",
    },
    {
      img_url: "./images/pd_approach_icon3.svg",
      heading: "UX Design and Feedback",
      desc: "Prioritize user experience and gather feedback for continuous product refinement.",
    },
    {
      img_url: "./images/pd_approach_icon4.svg",
      heading: "Agile Development",
      desc: "Employ Agile methodologies for flexible, iterative development, and quick adaptation to changing requirements.",
    },
    {
      img_url: "./images/pd_approach_icon5.svg",
      heading: "Technical Design and Architecture",
      desc: "Design the tech stack, infrastructure, and ensure scalability and robustness.",
    },
    {
      img_url: "./images/pd_approach_icon6.svg",
      heading: "Quality Assurance and Security",
      desc: "Rigorously test for technical issues and ensure security and regulatory compliance.",
    },
    {
      img_url: "./images/pd_approach_icon7.svg",
      heading: "DevOps and Continuous Integration",
      desc: "Implement DevOps practices for automation, CI/CD, and streamlined development processes.",
    },
    {
      img_url: "./images/pd_approach_icon8.svg",
      heading: "MVP Development",
      desc: "Get an operational set of features in front of customers to gather invaluable feedback. Develop a minimum viable product, reducing the risk of building unnecessary features.",
    },
    {
      img_url: "./images/pd_approach_icon9.svg",
      heading: "Launch, AI Integration, and Lifecycle Management:",
      desc: "Plan the product launch, integrate AI capabilities, and establish a clear strategy for the product's lifecycle.",
    },
  ];
  const dPhaseArray = [
    {
      img_url: "./images/dp_icon1.svg",
      heading: "Value Risk",
      desc: "Ensuring your product is valuable to users and profitable.",
    },
    {
      img_url: "./images/dp_icon2.svg",
      heading: "Usability Risk",
      desc: "Guaranteeing your audience can effectively use your product.",
    },
    {
      img_url: "./images/dp_icon3.svg",
      heading: "Feasibility Risk",
      desc: "Assessing technical feasibility and available tools.",
    },
    {
      img_url: "./images/dp_icon4.svg",
      heading: "Business Viability Risk",
      desc: "Ensuring your product aligns with your budget and business goals.",
    },
  ];
  const [openRequestQuotation, setOpenRequestQuotation] = useState<boolean>(false);
  useEffect(() => {
    if (openRequestQuotation) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [openRequestQuotation]);
  const [openCaseModal, setOpenCaseModal] = useState<boolean>(false);
  const [clickedCase, setClickedCase] = useState<caseType>({
    head: "", subHead: "", mainHeading: "", keywords: "", headPara: "", intro: "",
    challenge: "", solution: "", key: [], conclusion: ""
  });
  const caseStudies: caseType[] = [
    {
      head: "Innovative E-Learning", subHead: `"Digital Learning Redefined" - The Interactive Online Education Platform`,
      keywords: "Tech-Driven E-learning, Interactive Courseware, Student-Centric Onboarding, Robust Assessment Integration",
      mainHeading: `"Digital Learning Redefined" - The Interactive Online Education Platform`,
      headPara: `"Empowering Minds, Transforming Futures with Cutting-Edge EdTech"`,
      intro: "In a dynamic digital era, at Evervent, our mission was to rekindle the excitement of learning. We envisioned a groundbreaking e-learning platform that would not only engage but also empower students. Our aim was to set a new standard for digital education, where technology redefines the classroom.",
      challenge: "We were tasked with liberating education from the confines of traditional models. Our challenge was to harness technology to captivate, facilitate seamless onboarding, and effortlessly integrate assessments. The goal was not just learning; it was cultivating an enduring passion for knowledge.",
      solution: "Our solution heralds a paradigm shift in online education, driven by innovative UI/UX design, interactive courseware, and student-centric onboarding. But it doesn't stop there. At its core lies an advanced tech stack that forms the backbone of the digital learning revolution.",
      key: [
        { heading: "Immersive Courseware", content: "Our platform leverages the power of HTML5 and CSS3 to create course materials that transcend the ordinary. Learning is no longer a chore but a thrilling exploration." },
        { heading: "Student-Centric Onboarding", content: "With personalized algorithms, we've redefined how students embark on their learning journey. It's not just education; it's education tailored for you." },
        { heading: "Robust Assessment Integration", content: "Thanks to cutting-edge tech like Node.js and MongoDB, our platform provides students with an intuitive way to gauge their progress and offers educators a powerful tool for evaluation." },
        { heading: "Real-Time Collaboration", content: "Powered by WebSockets and WebRTC, our platform enables students to collaborate in real time on projects, fostering a sense of community and shared learning experiences." },
        { heading: "Adaptive Learning Algorithms", content: "Utilizing machine learning libraries like TensorFlow, we've developed adaptive algorithms that adjust the difficulty of course materials based on individual progress, ensuring optimal learning outcomes." },
        { heading: "Cloud-Based Content Storage", content: "We've embraced cloud solutions like Amazon S3 and Google Cloud Storage to provide seamless access to course materials from any device, anywhere, making learning more flexible and convenient." },
        { heading: "Interactive Assessments", content: "Thanks to JavaScript frameworks such as React and Vue.js, we've created interactive quizzes and assessments that make testing not only efficient but also engaging, providing instant feedback to students and educators." },
        { heading: "Cross-Platform Compatibility", content: "Our platform is accessible across various devices and operating systems, thanks to responsive design built on HTML5, CSS3, and Bootstrap, ensuring an optimal user experience." },
      ],
      conclusion: "The result is a digital learning landscape where the synergy of technology and education empowers students. We've rekindled the flame of curiosity, fostered personalized learning, and offered a comprehensive journey. With a state-of-the-art tech stack at its core, our platform is not just redefining education; it's rewriting its future. This is tech-infused learning at its finest, with students as its ultimate beneficiaries."
    },
  ]
  return (
    <Box className="pagesWrapper">

      <CaseStudyModal open={openCaseModal} setOpen={setOpenCaseModal} item={clickedCase} />

      <RequestQuotation open={openRequestQuotation} setOpen={setOpenRequestQuotation} popup_heading="" />

      {isMobile ? <MNavbar /> : <Navbar open={open} setOpen={setOpen} />}

      <Box className="pagesHeader mb-20 mb-40-m">
        <Grid container spacing={3} alignItems="center">
          <Grid
            xs={12}
            textAlign={"center"}
            marginBottom={window.innerWidth < 768 ? "" : "40px"}
          >
            <h1 className="mb-6">
              Product <span>Development</span>
            </h1>
            <p>
              “In the realm of technology, the journey of product development is
              the path to endless possibilities and groundbreaking solutions.
              Turning Vision into Reality: Where Innovation and Expertise Meet
              to Create Tomorrow's Technology Solutions"
            </p>
          </Grid>

          {isMobile ? (
            <Grid xs={12} marginBottom={"40px"}>
              <Box className="product_header_background_mobile">
                <Box
                  display={"flex"}
                  gap={"10px"}
                  alignItems={"center"}
                  marginBottom={"24px"}
                >
                  <Box sx={{ width: "calc(50% - 5px)" }}>
                    <img src="./images/ph_mobile_img1.svg" alt="" />
                  </Box>
                  <Box sx={{ width: "calc(50% - 5px)" }}>
                    <Box
                      className="spec_box csd"
                      onClick={() => handleScrollToSection(csdRef)}
                    >
                      <p>
                        Custom Software
                        <br /> Development
                      </p>
                      <span>
                        <DoneIcon />
                      </span>
                    </Box>
                    <Box
                      className="spec_box mas"
                      onClick={() => handleScrollToSection(masRef)}
                    >
                      <p>
                        Mobile App
                        <br /> Solution
                      </p>
                      <span>
                        <DoneIcon />
                      </span>
                    </Box>
                    <Box
                      className="spec_box ses"
                      onClick={() => handleScrollToSection(srsRef)}
                    >
                      <p>
                        Software Re-engineering
                        <br /> & Support
                      </p>
                      <span>
                        <DoneIcon />
                      </span>
                    </Box>
                  </Box>
                </Box>
                <Box display={"flex"} gap={"24px"} alignItems={"center"}>
                  <Box sx={{ width: "calc(50% - 5px)" }}>
                    <Box
                      className="spec_box pcs "
                      onClick={() => handleScrollToSection(pcsRef)}
                    >
                      <p>
                        Premium Custom
                        <br /> Solution
                      </p>
                      <span>
                        <DoneIcon />
                      </span>
                    </Box>
                    <Box
                      className="spec_box fd"
                      onClick={() => handleScrollToSection(fdRef)}
                    >
                      <p>
                        Fintech
                        <br /> Development
                      </p>
                      <span>
                        <DoneIcon />
                      </span>
                    </Box>
                    <Box
                      className="spec_box bd"
                      onClick={() => handleScrollToSection(bdRef)}
                    >
                      <p>
                        Blockchain
                        <br /> Development
                      </p>
                      <span>
                        <DoneIcon />
                      </span>
                    </Box>
                  </Box>
                  <Box sx={{ width: "calc(50% - 5px)" }}>
                    <img src="./images/ph_mobile_img2.svg" alt="" />
                  </Box>
                </Box>
              </Box>
            </Grid>
          ) : (
            <Grid xs={12} marginBottom={"40px"}>
              <Box className="product_header_background">
                <img
                  src="./images/product_dev_header1.svg"
                  alt="product-development"
                  className="prod-header-img"
                />
                <Box
                  className="spec_box mas"
                  onClick={() => handleScrollToSection(masRef)}
                >
                  <p>
                    Mobile App
                    <br /> Solution
                  </p>
                  <span>
                    <DoneIcon />
                  </span>
                </Box>
                <Box
                  className="spec_box csd"
                  onClick={() => handleScrollToSection(csdRef)}
                >
                  <p>
                    Custom Software
                    <br /> Development
                  </p>
                  <span>
                    <DoneIcon />
                  </span>
                </Box>
                <Box
                  className="spec_box ses"
                  onClick={() => handleScrollToSection(srsRef)}
                >
                  <p>
                    Software Re-engineering
                    <br /> & Support
                  </p>
                  <span>
                    <DoneIcon />
                  </span>
                </Box>
                <Box
                  className="spec_box pcs "
                  onClick={() => handleScrollToSection(pcsRef)}
                >
                  <p>
                    Premium Custom
                    <br /> Solution
                  </p>
                  <span>
                    <DoneIcon />
                  </span>
                </Box>
                <Box
                  className="spec_box fd"
                  onClick={() => handleScrollToSection(fdRef)}
                >
                  <p>
                    Fintech
                    <br /> Development
                  </p>
                  <span>
                    <DoneIcon />
                  </span>
                </Box>
                <Box
                  className="spec_box bd"
                  onClick={() => handleScrollToSection(bdRef)}
                >
                  <p>
                    Blockchain
                    <br /> Development
                  </p>
                  <span>
                    <DoneIcon />
                  </span>
                </Box>
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>

      <Box className="development_spectrum  mb-20 mb-40-m">
        <Grid container columnSpacing={3} rowSpacing={0} marginBottom={"40px"}>
          <Grid xs={12} textAlign={"center"}>
            <h1 className="mb-6">
              {" "}
              Our Development Expertise has a{" "}
              <span>broad spectrum of services</span>{" "}
            </h1>
            <p>
              Delivering a winning product requires a meticulous journey,
              orchestrated by our high-performing product team. Technology, when
              combined with user experience, is the catalyst for groundbreaking
              product development
            </p>
          </Grid>
        </Grid>
        <Grid container columnSpacing={3} rowGap={"24px"}>
          {developmentSpectrumArray.map((data, index) => (
            <Grid xs={12} sm={6} lg={4} textAlign={"center"}>
              <Box className="ds_box" ref={data.reff}>
                <img src={data.img_url} alt="" />
                <h5>{data.heading}</h5>
                <p>{data.desc}</p>
                <Box className="ctaBtn mt-6">
                  <CustomButton
                    text_name={"Inquire"}
                    class_name="blueMdBtn"
                    onClickFunction={() => setOpenRequestQuotation(true)}
                  />
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>

      <Box className="our_approach  mb-20 mb-40-m">
        <Grid container columnSpacing={3} rowSpacing={0} marginBottom={"40px"}>
          <Grid xs={12} textAlign={"center"}>
            <h1 className="mb-6">
              Aboard the <span>Development Vehicle</span>
            </h1>
            <p>
              "In the vibrant tapestry of circuits and screens, we discover the
              spectrum of innovation, where every color of technology is a
              stroke on the canvas of progress, painting the future in vivid
              hues."
            </p>
          </Grid>
        </Grid>
        <Grid container columnSpacing={3} rowGap={"24px"}>
          <Grid xs={12}>
            <Box className="sd_approach">
              {pdApproachArray.map((data, index) => (
                <Box className={`sda_box ${index % 2 !== 0 ? "even" : ""}`}>
                  <Box className="desc_box">
                    <Box
                      className={`desc_box_inner ${index % 2 === 0 ? "text-right" : "ml-auto"
                        }`}
                    >
                      <h6>{data.heading}</h6>
                      <p>{data.desc}</p>
                    </Box>
                  </Box>
                  <span className={`box-${index + 1}`}>{index + 1}</span>
                  <Box className="icon_box">
                    <img src={data.img_url} alt="" />
                  </Box>
                </Box>
              ))}
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box className="discovery_phase  mb-20 mb-40-m">
        <Grid container columnSpacing={3} rowSpacing={0} marginBottom={"40px"}>
          <Grid xs={12} textAlign={"center"}>
            <h1>
              Our product discovery phase is designed to identify and{" "}
              <span>mitigate risks before coding begins, addressing!</span>
            </h1>
          </Grid>
        </Grid>
        <Grid container columnSpacing={3} justifyContent={"center"}>
          <Grid xs={12} lg={6} textAlign={"center"}>
            <img
              src="./images/discover_phase_img.svg"
              alt=""
              style={{ margin: "auto" }}
              className="dp_img"
            />
          </Grid>
          <Grid xs={12} lg={6}>
            <ul className="discovery_phase_list">
              {dPhaseArray.map((data, index) => (
                <li>
                  <img src={data.img_url} alt="" />
                  <h5>{data.heading}</h5>
                  <hr />
                  <p>{data.desc}</p>
                </li>
              ))}
            </ul>
          </Grid>
        </Grid>
      </Box>

      <Grid
        container
        spacing={3}
        justifyContent="center"
        marginBottom={"80px"}
        className="mb-40-m"
      >
        <Grid xs={12} textAlign="center" marginBottom={"16px"}>
          <h1 className="mb-6">
            Case <span>Studies</span>
          </h1>
          <p>
            A case study is a research method that involves an in-depth and
            comprehensive examination of a specific subject, which can be an
            individual, a group, an organization, an event, or a particular
            phenomenon. The primary goal of a case study is to gain a detailed
            and contextual understanding of the subject under investigation.
          </p>
        </Grid>
        {
          caseStudies?.map((item) => (
            <Grid xs={12} lg={3} textAlign="center">
              <CaseStudyBox
                color="#79D05A"
                title={item?.head}
                description={item?.subHead}
                onClickFunction={() => {
                  setOpenCaseModal(true);
                  setClickedCase(item);
                }}
              />
            </Grid>
          ))
        }
      </Grid>

      <Box className="design_quotation">
        <RequestQuotationComponent />
      </Box>
      <Footer />
    </Box>
  );
}

export default ProductDevelopment;
