import { Box, Link } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Navbar.scss";
import { ROUTE } from "../../../Services/Route/Route";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";

const MNavbar = () => {
  const navigate = useNavigate();
  const [sideBarStatus, setSideBarStatus] = useState(false);

  if (sideBarStatus === true) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "";
  }

  return (
    <Box className="navbarMobile">
      <img
        src="./images/evervent-logo.svg"
        className="logo"
        alt="evervent"
        onClick={() => {
          navigate(ROUTE.HOME);
        }}
      />
      <Link
        className="menuBars"
        onClick={() => {
          setSideBarStatus(true);
        }}
      >
        <span />
      </Link>
      {sideBarStatus ? (
        <Box className="menuWrapper" onClick={() => setSideBarStatus(false)}>
          <Box
            className="innerWrapper"
            onClick={(e: React.MouseEvent) => e.stopPropagation()}
          >
            <Link
              className="back"
              onClick={() => {
                setSideBarStatus(false);
              }}
              sx={{ position: "fixed" }}
            >
              <ArrowBackIosRoundedIcon />
              Back
            </Link>
            <Box
              sx={{
                overflow: "auto",
                height: "100%",
                marginTop: "30px",
                paddingRight: "12px",
              }}
            >
              <ul>
                <li>
                  <Link
                    onClick={() => {
                      navigate(ROUTE.HOME);
                    }}
                  >
                    Home
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => {
                      navigate(ROUTE.ABOUT);
                    }}
                  >
                    About us
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => {
                      navigate(ROUTE.CONTACT);
                    }}
                  >
                    Contact
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => {
                      navigate(ROUTE.CAREER);
                    }}
                  >
                    Career
                  </Link>
                </li>
                <li>
                  <Link>Blog</Link>
                </li>
              </ul>

              {/* industry menus */}
              <h5>Industry</h5>
              <ul>
                <li>
                  <Link
                    onClick={() => {
                      navigate(ROUTE.HEALTH_CARE);
                    }}
                  >
                    Health Care
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => {
                      navigate(ROUTE.WELLNESS_FITNESS);
                    }}
                  >
                    Wellness & Fitness
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => {
                      navigate(ROUTE.RETAIL);
                    }}
                  >
                    Retail & E-Commerce
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => {
                      navigate(ROUTE.TRAVEL_HOSPITALITY);
                    }}
                  >
                    Travel & Hospitality
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => {
                      navigate(ROUTE.TRANSPORTATION);
                    }}
                  >
                    Transportation & Logistics
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => {
                      navigate(ROUTE.AUTOMOTIVE);
                    }}
                  >
                    Automotive
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => {
                      navigate(ROUTE.MEDIA_ENTERTAINMENT);
                    }}
                  >
                    Media and Entertainment
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() =>
                      window.open("https://insurvent.com/", "_blank")
                    }
                  >
                    Insurance
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => {
                      navigate(ROUTE.EDUCATION);
                    }}
                  >
                    Education & E-Learning
                  </Link>
                </li>
              </ul>

              {/* products menu */}
              <h5>Products</h5>
              <ul className="mb-6">
                <li>
                  <Link onClick={()=>navigate(ROUTE.ERP)}>ERP</Link>
                </li>
                <li>
                  <Link>LMS</Link>
                </li>
              </ul>

              {/* expertise menu */}
              <h5>Expertise</h5>
              <ul className="mb-6">
                <li>
                  <Link>Blockchain Development</Link>
                </li>
                <li>
                  <Link>AI-Powered App Builder</Link>
                </li>
                <li>
                  <Link onClick={() => navigate(ROUTE.GROWTH_MARKETING)}>
                    Growth & Marketing
                  </Link>
                </li>
                <li>
                  <Link onClick={() => navigate(ROUTE.DESIGN_STUDIO)}>
                    Design Studio
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => {
                      navigate(ROUTE.PRODUCT_DEVELOPMENT);
                    }}
                  >
                    Product Development
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => {
                      navigate(ROUTE.IT_CONSULTING);
                    }}
                  >
                    IT Consulting & Staff Augmentation
                  </Link>
                </li>
              </ul>
            </Box>
          </Box>
        </Box>
      ) : null}
    </Box>
  );
};

export default MNavbar;
