import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import "../../CommonSCSS/common.scss";
import Footer from "../../Components/Desktop/Footer/Footer";
import Navbar from "../../Components/Desktop/Navbar/Navbar";
import MNavbar from "../../Components/Mobile/Navbar/MNavbar";
import useIsMobile from "../../SupportingFiles/MobileProvider";
import CustomButton from "../../Components/Common/CustomButton/CustomButton";
import CaseStudyBox from "../HealthCare/CaseStudyBox/CaseStudyBox";
import RequestQuotationComponent from "../../Components/Common/RequestComponent/RequestQuotationComponent";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import RequestQuotation from "../../Components/Common/RequestQuotationPopup/RequestQuotation";
import Masonry from '@mui/lab/Masonry';
import CaseStudyModal, { caseType } from "../../Components/Common/CaseStudy/CaseStudyModal";

function Retail() {
    const isMobile = useIsMobile();
    const [open, setOpen] = useState<boolean>(false);
    const [openRequestQuotation, setOpenRequestQuotation] = useState<boolean>(false);
    const products = [
        {
            id: 1,
            heading: "Microservices-based eCommerce Development",
            content: "Unleash omnichannel experiences with our Microservices, integrating seamless user journeys and boosting conversions through advanced eCommerce development services.",
            image: "/images/retail-product1.svg",
        },
        {
            id: 2,
            heading: "Backend, Frontend & CRM Excellence",
            content: "Elevate global shopping experiences with robust cloud-based backend architecture, engaging frontends, and CRM solutions tailored for effective marketing and sales scalability.",
            image: "/images/retail-product2.svg",
        },
        {
            id: 3,
            heading: "PWA eCommerce Expertise",
            content: "Redefine online shopping without app downloads. Our Progressive Web Apps (PWAs) bring intricate design and unmatched user experiences directly to your customers.",
            image: "/images/retail-product3.svg",
        },
        {
            id: 4,
            heading: "Marketplaces Architectures",
            content: "Designing platforms supporting countless buyers and sellers, our architectures redefine online marketplaces with thoughtful web and mobile portal integration.",
            image: "/images/retail-product4.svg",
        },
        {
            id: 5,
            heading: "Aggregator Portals Mastery",
            content: "As a leading custom eCommerce software developer, we create aggregator apps facilitating seamless connections between consumers and service providers, ensuring profitable transactions.",
            image: "/images/retail-product5.svg",
        },
        {
            id: 6,
            heading: "M-Commerce Innovation",
            content: "In a mobile-centric world, we create mobile versions from scratch, acknowledging the critical importance of mobile commerce and enhancing user experiences.",
            image: "/images/retail-product6.svg",
        },
        {
            id: 7,
            heading: "B2B Trade Portals Leadership",
            content: "Our robust B2B portals unite business entities, providing impeccable technical solutions for bulk orders, partnerships, and secure trade operations.",
            image: "/images/retail-product7.svg",
        },
        {
            id: 8,
            heading: "Auction Platforms Expertise",
            content: "Revolutionize trading experiences with our auction platforms, managing bid items, diverse auction types, audio-video streaming, and versatile payment options.",
            image: "/images/retail-product8.svg",
        },
        {
            id: 9,
            heading: "Inventory Warehousing & Logistics Mastery",
            content: "Integrate disciplined inventory management, from mobile app barcoding to data-driven forecasting, providing real-time solutions for routing, tracking, cargo operations, and stock optimization.",
            image: "/images/retail-product9.svg",
        },

    ]
    const responsive = {
        desktop: {
            breakpoint: { max: 4000, min: 1200 },
            items: 4,
            paritialVisibilityGutter: 60,
        },
        tablet: {
            breakpoint: { max: 1199, min: 901 },
            items: 3,
            paritialVisibilityGutter: 40,
        },
        tablet2: {
            breakpoint: { max: 900, min: 701 },
            items: 2,
            paritialVisibilityGutter: 40,
        },
        mobile: {
            breakpoint: { max: 700, min: 0 },
            items: 1,
            paritialVisibilityGutter: 40,
        },
    };
    useEffect(() => {
        if (openRequestQuotation) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "";
        }
    }, [openRequestQuotation]);
    const paths = [
        {
            id: 1,
            heading: "Project Kick-off Precision",
            content: "Initiate projects with detailed discussions, providing time estimates and budgets, ensuring readiness for an impactful eCommerce platform.",
            image: "/images/path1.svg",
        },
        {
            id: 2,
            heading: "UX & UI Design Excellence",
            content: "Conduct in-depth market research, predict user interactions through wireframes in Figma, and refine UI concepts for visually appealing interfaces.   - Finalize UI concepts, fine-tune visual aspects, and create efficient UI kits for streamlined coding and feature addition.",
            image: "/images/path2.svg",
        },
        {
            id: 3,
            heading: "Agile Development Prowess",
            content: "Utilize Agile methodologies for timely development, providing regular updates and demos for client feedback.",
            image: "/images/path3.svg",
        },
        {
            id: 4,
            heading: "Quality Assurance Leadership",
            content: "Rigorous testing to ensure a flawless final version, delivering a seamless shopping experience without interruptions.",
            image: "/images/path4.svg",
        },
        {
            id: 5,
            heading: "Strategic Release Planning",
            content: "Add finishing touches, prepare platforms for deployment across devices and platforms, ensuring a smooth and impactful launch.",
            image: "/images/path5.svg",
        },
        {
            id: 6,
            heading: "Post-Launch Optimization",
            content: "Continuously review customer feedback, integrate impactful ideas, and enhance the platform's appeal for increased user engagement.",
            image: "/images/path6.svg",
        },
        {
            id: 7,
            heading: "Ongoing Support and Maintenance",
            content: "Ensure your eCommerce platform stays ahead with ongoing support, addressing technical challenges, and assisting in global scaling.",
            image: "/images/path7.svg",
        },
    ]
    const [openCaseModal, setOpenCaseModal] = useState<boolean>(false);
    const [clickedCase, setClickedCase] = useState<caseType>({
        head: "", subHead: "", mainHeading: "", keywords: "", headPara: "", intro: "",
        challenge: "", solution: "", key: [], conclusion: ""
    });
    const caseStudies: caseType[] = [
        {
            head: "Digital Transformation", subHead: "Reinventing eCommerce for Building Sector",
            keywords: "Digital Transformation, eCommerce Experience, Building and Construction, Mobile-Friendly Redesign",
            mainHeading: "Reinventing eCommerce for Building Sector",
            headPara: "Elevating Digital Customer Experiences Across Properties",
            intro: "Evervent played a pivotal role in reinventing the cross-platform eCommerce experience for a trusted name in building and construction sector. This case study explores the journey of how technology and a mobile-friendly architecture redesign elevated digital customer experiences.",
            challenge: "The challenge lay in reshaping the eCommerce experience for a sector known for its specificity and diversity. Evervent aimed to address issues related to mobile usability, legacy systems, and overall user satisfaction. The goal was to create a solution that seamlessly integrated into the sector's workflow while providing an intuitive and engaging experience.",
            solution: "Through a meticulous process of mobile-friendly architecture redesign and legacy system upgrades, Evervent successfully transformed the eCommerce experience for the building and construction sector in Australia. The focus was on enhancing usability, improving engagement, and creating a platform that aligned with the unique needs of the industry.",
            key: [
                { heading: "Mobile-Friendly Redesign", content: "Overhauling the platform to ensure optimal performance and usability on mobile devices." },
                { heading: "Cross-Platform Integration", content: "Seamlessly integrating the eCommerce experience across multiple properties for a cohesive digital presence." },
                { heading: "Legacy System Upgrades", content: "Implementing strategic upgrades to existing systems for enhanced functionality and user satisfaction." },
                { heading: "Intuitive User Experience", content: "Prioritizing user experience in the design, ensuring ease of navigation and engagement." },
            ],
            conclusion: "Evervent's involvement in the digital transformation of Australia's building sector exemplifies the impact of technology in revitalizing traditional industries. The success of this endeavor stands as a testament to the effectiveness of tech-driven solutions in improving digital customer experiences."
        },
        {
            head: "Proximity Marketing Evolution", subHead: "Transforming Customer Interactions with Proximity Marketing",
            keywords: "Proximity Marketing, Location-Based Interactions, Retail Intelligence",
            mainHeading: "Transforming Customer Interactions with Proximity Marketing",
            headPara: "Revolutionizing Customer Interactions through Proximity Marketing",
            intro: "Evervent took on the challenge of revolutionizing customer interactions through proximity marketing for a leading retail brand. This case study delves into how technology was harnessed to provide customers with relevant, personalized interactions based on their location.",
            challenge: "The challenge was to create a seamless and targeted marketing strategy that leveraged location-based data. Evervent aimed to enhance the customer experience by providing real-time, personalized promotions and offers through proximity marketing. The goal was to engage customers at the right place and time, fostering a deeper connection with the brand.",
            solution: "Evervent's solution involved the implementation of a comprehensive proximity marketing system based on GPS, NFC, BLE beacons, and QR codes. This enabled the retail brand to deliver smart retail intelligence, feeding sales through personalized interactions that were both relevant and timely.",
            key: [
                { heading: "Location-Aware Solutions", content: "Utilizing GPS, NFC, BLE beacons, and QR codes for precise location-based interactions." },
                { heading: "Real-Time Promotions", content: "Offering customers personalized promotions and offers in real-time." },
                { heading: "Retail Intelligence", content: "Providing the brand with valuable insights into customer behavior and preferences." },
            ],
            conclusion: "Through the adoption of proximity marketing, Evervent empowered the retail brand to redefine customer interactions. The success of this case study highlights the potential of technology in creating meaningful and personalized engagements in the retail sector."
        },
        {
            head: "eCommerce Analytics", subHead: "Optimizing eCommerce Analytics for Growth",
            keywords: "eCommerce Analytics, Data-Driven Insights, Technology Optimization",
            mainHeading: "Optimizing eCommerce Analytics for Growth",
            headPara: "Maximizing Engagement, Conversions, and Sales through Analytics",
            intro: "Evervent's expertise in eCommerce analytics came to the forefront in optimizing growth for a dynamic online retail platform. This case study explores how data-driven insights were harnessed to maximize engagement, conversions, and sales.",
            challenge: "The challenge was to keep pace with technological advancements and leverage analytics for continuous improvement. Evervent aimed to proactively detect issues, upgrade applications, and uncover data-driven insights to enhance the overall eCommerce experience. The goal was to ensure that every advance in technology translated into a better customer experience.",
            solution: "Evervent's solution involved a comprehensive approach to eCommerce analytics, utilizing a team of QA experts, skilled designers, eCommerce developers, and data analysis specialists. This multifaceted team worked collaboratively to identify opportunities for improvement, upgrade processes, and enhance the platform's overall performance.",
            key: [
                { heading: "Proactive Issue Detection", content: "Employing QA experts to proactively identify and address issues in real-time." },
                { heading: "Data-Driven Insights", content: "Uncovering actionable insights through advanced data analysis for continuous improvement." },
                { heading: "Data-Driven Insights", content: "Ensuring that technological advancements translated into a better customer experience." },
            ],
            conclusion: "Evervent's role in optimizing eCommerce analytics exemplifies the transformative power of technology in maximizing engagement, conversions, and sales. This case study underscores the importance of staying ahead in the ever-evolving landscape of digital retail."
        },
    ]
    
    return (
      <Box className="pagesWrapper">
        <RequestQuotation
          open={openRequestQuotation}
          setOpen={setOpenRequestQuotation}
          popup_heading=""
        />

        <CaseStudyModal
          open={openCaseModal}
          setOpen={setOpenCaseModal}
          item={clickedCase}
        />

        {isMobile ? <MNavbar /> : <Navbar open={open} setOpen={setOpen} />}

        <Box className="edu_header retail_header">
          <Grid container columnSpacing={3} rowSpacing={5}>
            <Grid xs={12} md={6} className="head">
              <h5>Retail & E-Commerce Services</h5>
              <h1>
                Revolutionizing Commerce{" "}
                <span>through Technological Mastery</span>
              </h1>
              <p>
                Elevate your eCommerce presence with Evervent, our expertise
                lies in crafting cohesive digital experiences, featuring a
                resilient backend and frontend. This not only reduces
                abandonment rates but significantly enhances conversions, builds
                customer loyalty, and ensures unparalleled satisfaction.
              </p>
              <Box className="ctaBtn">
                <CustomButton
                  text_name={"Get Started"}
                  class_name="blackLgBtn"
                  onClickFunction={()=>setOpenRequestQuotation(true)}
                />
              </Box>
            </Grid>
            <Grid xs={12} md={6} className="bottom_content">
              <img src="/images/retail_header.svg" alt="con" />
            </Grid>
          </Grid>
        </Box>

        <Box className="retail_products">
          <Grid container columnSpacing={3}>
            <Grid xs={12} className="head mb-6" textAlign={"center"}>
              <h2 className="mb-3">
                <span>Products That </span>We Curate
              </h2>
              <p>
                Transforming Merchandise, Retail, & Wholesale with Digital
                Innovation at its Core.
              </p>
            </Grid>
          </Grid>
          <Grid container>
            <Grid xs={12}>
              <Carousel
                responsive={responsive}
                renderArrowsWhenDisabled={true}
                swipeable={true}
                draggable={true}
                customTransition={"transform 800ms ease-in-out"}
                className="retail_carousel"
              >
                {products?.map((item) => (
                  <div className="main_box">
                    <div className="img_box">
                      <img src={item?.image} alt="service" />
                    </div>
                    {/* <h5>0{item?.id}</h5> */}
                    <h5>{item?.heading}</h5>
                    <p className="mb-8">{item?.content}</p>
                    <Box className="ctaBtn">
                      <CustomButton
                        text_name={"Inquire"}
                        class_name="blueMdBtn"
                        onClickFunction={() => setOpenRequestQuotation(true)}
                      />
                    </Box>
                  </div>
                ))}
              </Carousel>
            </Grid>
          </Grid>
        </Box>

        <Box className="retail_services">
          <Grid container columnSpacing={3}>
            <Grid xs={12} className="head mb-6" textAlign={"center"}>
              <h2 className="mb-3">
                Services<span> that We Excel at</span>
              </h2>
              <p>
                Evervent: Crafting Digital Excellence, One Tailored Strategy at
                a Time.
              </p>
            </Grid>
            <Grid xs={12} lg={6} className="service_image">
              <img src="/images/retail_service.svg" alt="" />
            </Grid>
            <Grid lg={6} className="services">
              <Box className="service_box">
                <Box display={"flex"}>
                  <img src="/images/retail_service1.svg" alt="" />
                </Box>
                <Box className="right_section">
                  <h5>Proximity Marketing/Sales Solutions</h5>
                  <p>
                    Propel your business with location-aware solutions based on
                    GPS, NFC, BLE beacons, and QR codes, driving smart retail
                    intelligence and boosting sales.
                  </p>
                </Box>
              </Box>
              <Box className="service_box">
                <Box display={"flex"}>
                  <img src="/images/retail_service2.svg" alt="" />
                </Box>
                <Box className="right_section">
                  <h5>E-Commerce Analytics & Optimization Services</h5>
                  <p>
                    Stay ahead with proactive issue detection, application
                    upgrades, and data-driven insights, maximizing engagement,
                    conversions, and sales.
                  </p>
                </Box>
              </Box>
              <Box className="service_box">
                <Box display={"flex"}>
                  <img src="/images/retail_service3.svg" alt="" />
                </Box>
                <Box className="right_section">
                  <h5>E-Commerce Maintenance & Support Services</h5>
                  <p>
                    Ensure uninterrupted shopping experiences with
                    performance-focused maintenance and support services, from
                    updates to strategic upgrades.
                  </p>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box className="retail_path">
          <Grid container columnSpacing={3} rowSpacing={7}>
            <Grid xs={12}>
              <Masonry columns={isMobile ? 1 : 2} spacing={isMobile ? 3 : 0}>
                <Grid className="head">
                  <h2 className="mb-3">
                    Evervent's Path
                    <span> to Digitize & Revolutionize Commerce</span>
                  </h2>
                  <p>
                    Crafting Tomorrow's Shopping Experiences with Today's
                    Technology.
                  </p>
                </Grid>
                {paths?.map((item, i) => (
                  <Grid className="content">
                    <div className="border_div"></div>
                    <span>{i + 1}</span>
                    <Box className="content_box">
                      <img src={item?.image} alt="" />
                      <h5>{item?.heading}</h5>
                      <p>{item?.content}</p>
                    </Box>
                  </Grid>
                ))}
              </Masonry>
            </Grid>
          </Grid>
        </Box>

        <Box className="case_study">
          <Grid container columnSpacing={3}>
            <Grid xs={12} className="mb-10" textAlign={"center"}>
              <h2 className="mb-3">Case Studies </h2>
              <p>
                In the realm of challenges, every case study is a chapter of
                solutions, each offering its unique wisdom to those who seek the
                knowledge it holds.
              </p>
            </Grid>
          </Grid>
          <Box className="case_bottom">
            <Grid
              container
              columnSpacing={3}
              justifyContent={"center"}
              rowGap={"24px"}
            >
              {caseStudies?.map((item) => (
                <Grid xs={6} sm={6} md={4} lg={3}>
                  <CaseStudyBox
                    color="#79D05A"
                    title={item?.head}
                    description={item?.subHead}
                    onClickFunction={() => {
                      setOpenCaseModal(true);
                      setClickedCase(item);
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>

        <Box className="edu_quotation">
          <RequestQuotationComponent />
        </Box>

        <Footer />
      </Box>
    );
}

export default Retail;


