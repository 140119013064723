import React, { ReactNode } from "react";
import { Box } from "@mui/material";

interface ContentListProps {
  children: ReactNode; // The content you want to pass as a prop
}

function ContentList({ children }: ContentListProps) {
  return (
    <Box
      sx={{
        borderTop: "1px solid #FED500",
        borderBottom: "1px solid #FED500",
        borderRadius: "12px",
        display: "flex",
        padding: "24px",
        gap: "12px",
        fontSize: "16px",
        fontWeight: "700",
        alignItems: "center",
        alignSelf: "stretch",
        flex: "1 0 0",
        minWidth: "270px",
        paddingLeft: "20px", // Add some padding to make space for the pseudo-element
      }}
    >
      <span
        style={{
          border: "6px solid #FFF2B2",
          display: "inline-block",
          width: "20px",
          height: "20px",
          background: "#000000",
          borderRadius: "50%", // To make it a circle
        }}
      />
      {children}
    </Box>
  );
}

export default ContentList;
