// const DOMAIN = process.env.REACT_APP_BACKEND_URL;
const STRAPI_DOMAIN = "https://amazebackendstrapi.evervent.in";

export const URLs = {
  // GENERATE_TOKEN: `${DOMAIN}/api/users/generate-token.json`,

  //strAPI
  LOAD_HOMEPAGE_DATA: `${STRAPI_DOMAIN}/api/homepage?populate=deep`,
  CMS_DATA: `${STRAPI_DOMAIN}/api/cms-pag?populate=deep`,
};
