import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useState, useEffect } from "react";
import "../../CommonSCSS/common.scss";
import Footer from "../../Components/Desktop/Footer/Footer";
import Navbar from "../../Components/Desktop/Navbar/Navbar";
import MNavbar from "../../Components/Mobile/Navbar/MNavbar";
import useIsMobile from "../../SupportingFiles/MobileProvider";
import CaseStudyBox from "../HealthCare/CaseStudyBox/CaseStudyBox";
import RequestQuotationComponent from "../../Components/Common/RequestComponent/RequestQuotationComponent";
import CaseStudyModal, { caseType } from "../../Components/Common/CaseStudy/CaseStudyModal";

function DesignStudio() {
    const [open, setOpen] = useState<boolean>(false);
    const isMobile = useIsMobile();
    const [isVisible, setIsVisible] = useState(false);
    const [displayedText, setDisplayedText] = useState("");
    useEffect(() => {
        // Use a timeout to update the state after 3 seconds
        const timeoutId = setTimeout(() => {
            setIsVisible(true);
        }, 3000);

        // Clear the timeout to prevent memory leaks
        return () => clearTimeout(timeoutId);
    }, []); // Empty dependency array means this effect runs once when the component mounts
    useEffect(() => {
        if (isVisible) {
            let currentIndex = 0;
            const textToType = "UI+UX= Pixels meet emotions";
            setDisplayedText(textToType[0]); // Initialize with the first character

            const intervalId = setInterval(() => {
                currentIndex++;

                // Check if there are characters left
                if (currentIndex < textToType.length) {
                    setDisplayedText((prevText) => prevText + textToType[currentIndex]);
                } else {
                    clearInterval(intervalId);
                }
            }, 200); // Adjust the interval as needed

            return () => clearInterval(intervalId);
        }
    }, [isVisible]);
    const [openCaseModal, setOpenCaseModal] = useState<boolean>(false);
    const [clickedCase, setClickedCase] = useState<caseType>({
        head: "", subHead: "", mainHeading: "", keywords: "", headPara: "", intro: "",
        challenge: "", solution: "", key: [], conclusion: ""
    });
    const caseStudies: caseType[] = [
        {
            head: "Financial Data Visualization", subHead: "Revolutionizing Investments with Tech-Driven Robo-Advisory Platform",
            keywords: "Robo-Advisory, UI/UX Design, Financial Data Visualization, User Onboarding, Secure Payment Integration, Fintech",
            mainHeading: "Revolutionizing Investments with Tech-Driven Robo-Advisory Platform",
            headPara: "Transforming Financial Landscapes Through Innovative Technology",
            intro: "In the dynamic realm of financial technology, we introduce a groundbreaking Robo-Advisory platform designed to leverage cutting-edge technology. Our emphasis on Fintech, UI/UX excellence, financial data visualization, seamless user onboarding, and secure payment integration positions us as pioneers in redefining the tech-driven investment landscape.",
            challenge: "The intricacies and security concerns inherent in financial markets prompted our quest to create a tech-savvy platform. The challenge was to engineer a solution that not only simplifies investments but also harnesses the power of technology for intuitive design, data-rich visualization, smooth onboarding, and fortified transaction security.",
            solution: "Our solution involves the strategic amalgamation of advanced technology to birth the Robo-Advisory Investment Platform. By infusing intelligent UI/UX design with state-of-the-art financial data visualization tools, our objective is to revolutionize how users interact with and comprehend financial investments. The aim is not just to provide information but to empower users with a technologically sophisticated platform that adapts to their needs, simplifying and enhancing the entire investment journey.",
            key: [
                { heading: "Innovative UI/UX Design", content: "Engineered a technologically advanced user interface for seamless navigation and an immersive user experience." },
                { heading: "Advanced Financial Data Visualization", content: "Utilized cutting-edge tools to visually represent intricate financial data, empowering users with actionable insights for informed investment decisions." },
                { heading: "Tech-Streamlined User Onboarding", content: "Redefined onboarding through technological efficiencies, making it swift, personalized, and aligned with individual user profiles." },
                { heading: "Fintech-Driven Secure Payment Integration", content: "Implemented robust Fintech-driven security measures to ensure transactions are not only frictionless but also fortified against cyber threats, establishing trust and confidence." },
                { heading: "Algorithmic Investment Strategy", content: "Implementing advanced algorithms for data analysis and market trends, our platform employs a robust algorithmic investment strategy. This ensures that user investments are guided by intelligent data-driven decisions, optimizing returns." },
                { heading: "Real-time Data Processing", content: "Leveraging cutting-edge technology, our platform performs real-time processing of financial data. This allows users to access up-to-the-minute market information, enabling them to make informed investment decisions based on the latest trends." },
                { heading: "Machine Learning Insights", content: "Introducing machine learning capabilities, our platform continuously learns and adapts to user preferences and market dynamics. This results in personalized investment insights and recommendations that evolve over time, enhancing user engagement and satisfaction." },
                { heading: "API Integration for Seamless Connectivity", content: "Our platform is designed with extensive API integration capabilities, allowing seamless connectivity with external financial services, market data sources, and other relevant platforms. This ensures a comprehensive and interconnected financial ecosystem for users." },
                { heading: "Scalable Cloud Infrastructure", content: "The platform operates on a scalable cloud infrastructure, ensuring high performance and availability even during peak usage. This guarantees a smooth user experience, regardless of the volume of transactions or data processing demands." },
                { heading: "Smart Notifications and Alerts", content: "Utilizing smart notification systems powered by AI, our platform keeps users informed about market changes, portfolio performance, and relevant news. This proactive approach ensures that users are always updated and can make timely decisions." },
                { heading: "Intuitive API for Developers", content: "For developers seeking integration capabilities, our platform offers an intuitive API, allowing for seamless integration with third-party applications and services. This fosters a collaborative ecosystem and encourages innovation within the broader financial technology landscape." },
                { heading: "Continuous Software Updates and Enhancements", content: "Emphasizing a commitment to technological excellence, our platform undergoes continuous software updates and enhancements. This ensures that users benefit from the latest technological advancements, features, and security protocols." },
            ],
            conclusion: "In conclusion, our Tech-Driven Robo-Advisory Investment Platform epitomizes our dedication to utilizing technology as a catalyst for reshaping financial investments. By merging user-centric design principles with cutting-edge Fintech innovations, we envision a future where investing is seamlessly integrated with technology, making it accessible, secure, and empowering for all. Step into the future of finance with us."
        },
        {
            head: "Patient Portal", subHead: "Revolutionizing Healthcare: A Tech-Infused Telemedicine and Health Records Portal",
            keywords: "UI/UX Design, Patient Portal, Telehealth Features, EHR Integration, Appointment Scheduling, Healthcare Technology",
            mainHeading: "Revolutionizing Healthcare: A Tech-Infused Telemedicine and Health Records Portal",
            headPara: "Empowering Patients and Healthcare Providers through Seamless Connectivity",
            intro: "In the realm of healthcare technology, our Telemedicine and Health Records Portal stands as a beacon of innovation. This transformative platform seamlessly integrates cutting-edge UI/UX design, patient-centric features, and advanced telehealth capabilities, redefining the way patients access their health records and connect with healthcare providers.",
            challenge: "The challenge was clear: create a comprehensive solution that simplifies the patient experience, ensures the security of telemedicine interactions, and streamlines appointment scheduling. The goal was to bridge the gap between patients and healthcare providers in the digital age while maintaining a user-friendly interface.",
            solution: "Our solution was to craft a web portal that goes beyond conventional healthcare platforms. With a focus on UI/UX excellence, we designed a patient portal enriched with telehealth features, seamless EHR integration, and an intuitive appointment scheduling system. The aim was to provide not just a portal but a holistic healthcare experience accessible to all.",
            key: [
                { heading: "UI/UX Excellence", content: "The portal boasts a user-friendly interface designed to enhance the overall experience for both patients and healthcare providers." },
                { heading: "Patient Portal", content: "Empowering patients with easy access to their health records, fostering a sense of ownership and involvement in their healthcare journey." },
                { heading: "Telehealth Features", content: "Secure and HIPAA-compliant telehealth capabilities facilitate virtual consultations, bringing healthcare to the comfort of patients' homes." },
                { heading: "EHR Integration", content: "Seamless integration with Electronic Health Records ensures that healthcare providers have comprehensive and up-to-date information during telemedicine appointments." },
                { heading: "Appointment Scheduling", content: "An intelligent and intuitive appointment scheduling system optimizes the allocation of healthcare provider time, improving overall efficiency." },
                { heading: "Decentralized Data Storage", content: "Implementing decentralized storage solutions using blockchain technology, ensuring distributed data storage with enhanced security, integrity, and resilience against cyber threats." },
                { heading: "Smart Health Contracts", content: "Introducing smart contracts powered by blockchain to automate and secure health data transactions, enabling transparent and trustless interactions between patients and healthcare providers." },
                { heading: "Predictive Analytics Engine", content: "Incorporating an advanced predictive analytics engine driven by machine learning algorithms, offering healthcare providers insights into potential health trends and risks for proactive patient care." },
                { heading: "Continuous Monitoring APIs", content: "Offering APIs for continuous health parameter monitoring, facilitating real-time data updates for healthcare providers to monitor patients remotely and intervene promptly when necessary." },
            ],
            conclusion: ""
        },
        {
            head: "Nutrition Planning", subHead: "Revolutionizing Fitness Journeys: An Online Fitness Coaching and Nutrition Planning Platform",
            keywords: "Fitness Coaching, Nutrition Planning, UI/UX Design, Interactive Workout Planner, Nutrition Tracker, Progress Tracking, Online Platform",
            mainHeading: "Revolutionizing Fitness Journeys: An Online Fitness Coaching and Nutrition Planning Platform",
            headPara: "Empowering Your Fitness Transformation Journey Through Innovative Online Coaching and Nutrition Planning",
            intro: "Welcome to a transformative fitness experience tailored for enthusiasts seeking the perfect blend of expert coaching and personalized nutrition planning. Our Online Fitness Coaching and Nutrition Planning Platform are designed to elevate your fitness journey, providing a seamless and interactive environment to achieve your health and wellness goals.",
            challenge: "In a world filled with fitness options, finding a comprehensive platform that seamlessly integrates coaching, nutrition planning, and progress tracking can be challenging. The need for a user-friendly, all-encompassing solution led us to embark on a mission to redefine online fitness experiences.",
            solution: "Our solution goes beyond conventional approaches. We've developed a web platform that not only features cutting-edge UI/UX design but also houses an interactive workout planner, a robust nutrition tracking system, and tools to visually track your progress. Our aim is to make fitness accessible, engaging, and tailored to your unique needs.",
            key: [
                { heading: "AI-Driven Workout Recommendations", content: "Leverage artificial intelligence for personalized workout recommendations based on user goals, fitness levels, and progress." },
                { heading: "Real-Time Workout Analytics", content: "Enable real-time analytics of workout sessions, allowing users and coaches to track performance metrics, analyze trends, and make data-driven adjustments." },
                { heading: "Cloud-Based Progress Storage", content: "Utilize cloud-based storage for progress data, ensuring seamless access across devices and providing a scalable and reliable solution for data storage." },
                { heading: "Machine Learning-Powered Nutrition Insights", content: "Implement machine learning algorithms to analyze nutrition data, providing users with actionable insights into dietary patterns and personalized suggestions for improvement." },
                { heading: "Biometric Authentication", content: "Implement biometric authentication methods, such as fingerprint or facial recognition, to ensure secure access to user profiles and sensitive health information." },
                { heading: "APIs for Third-Party Integration", content: "Provide APIs for seamless integration with third-party health and fitness apps, allowing users to consolidate data from various sources for a comprehensive view of their well-being." },
                { heading: "Cybersecurity Measures", content: "Prioritize cybersecurity with robust measures such as end-to-end encryption, regular security audits, and adherence to industry best practices to safeguard user data and privacy." },
                { heading: "Automated Progress Reports", content: "Utilize automation to generate regular progress reports, offering users and coaches a detailed analysis of achievements, areas for improvement, and personalized goal-setting." },
                { heading: "Biometric Feedback Integration", content: "Incorporate biometric feedback into workouts, enabling users to receive real-time insights into their physiological responses and make informed adjustments to their routines." },
            ],
            conclusion: "Embark on a fitness journey like never before. Our Online Fitness Coaching and Nutrition Planning Platform is not just a platform; it's a companion in your pursuit of a healthier and fitter life. Join us and experience the synergy of expert coaching, personalized nutrition planning, and advanced progress tracking. Your transformation begins here."
        },
    ]

    return (
        <Box className="pagesWrapper">

            <CaseStudyModal open={openCaseModal} setOpen={setOpenCaseModal} item={clickedCase} />

            {isMobile ? <MNavbar /> : <Navbar open={open} setOpen={setOpen} />}

            <Box className="digital_studio">
                <Grid container spacing={3} position="relative">
                    <Grid lg={12} className="container_grid">
                        <Box className="animateObject">
                            <img src="/images/black_round1.svg" alt="pp" className="circle" />
                            {isVisible && (
                                <img src="/images/bulb1.svg" alt="pp" className="infinity" />
                            )}
                            <img src="/images/comb1.svg" alt="pp" className="scale" />
                            <div className="pencil">
                                <img src="/images/pencil.svg" alt="pp" className="" />
                            </div>
                        </Box>
                        <h2 className="typing-heading">{displayedText}</h2>

                        {isVisible && (
                            <p>
                                "Design is not just what it looks like and feels like. Design is
                                how it works." <span>- Steve Jobs</span>
                            </p>
                        )}
                    </Grid>
                </Grid>
            </Box>

            <Box className="Which_dir">
                <Grid container spacing={3}>
                    <Grid xs={12} lg={12} className="head mb-10" textAlign={'center'}>
                        <h2 className="mb-3">Which direction are <span>you ready to take?</span></h2>
                        <p>We are ready with that whatever you want</p>
                    </Grid>
                    <Grid lg={4} className="concept">
                        <Box className="img_sec mb-6">
                            <img src="/images/concept.svg" alt="con" width={'250px'} />
                        </Box>
                        <Box className="content_sec" textAlign={'center'}>
                            <h4 className="mb-3">Concept to Reality</h4>
                            <p>If you're embarking on a new product venture, our design services will help you establish a solid foundation. We'll work with you from ideation to implementation, ensuring your product not only meets industry standards but also stands out with innovative and user-friendly design elements. This option is ideal for startups and businesses launching a brand-new product into the market.
                            </p>
                        </Box>
                    </Grid>
                    <Grid xs={12} lg={4} className="concept_visual">
                        <Box className="img_sec">
                            <img src="/images/concept_visual.svg" alt="con" height={'639px'} />
                        </Box>
                    </Grid>
                    <Grid lg={4} className="concept">
                        <Box className="img_sec mb-6">
                            <img src="/images/visual.svg" alt="con" width={'365px'} />
                        </Box>
                        <Box className="content_sec" textAlign={'center'}>
                            <h4 className="mb-3">Visual Re-transform</h4>
                            <p>For those with an existing product looking to enhance its appeal, functionality, and user experience, a product revamp is the way to go. Our UI/UX experts will analyse your current design, identify pain points, and rejuvenate your product with a fresh, modern look and improved usability. This option is perfect for businesses aiming to breathe new life into their established products and cater to evolving user needs.
                            </p>
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            <Box className="detailed_approach">
                <Grid container columnSpacing={3}>
                    <Grid xs={12} lg={12} className="head mb-10" textAlign={'center'}>
                        <h2 className="mb-3">Our simple yet <span>detailed approach</span></h2>
                        <p>We follow a meticulous approach to ensure your project's success:</p>
                    </Grid>
                    <Grid xs={12} >
                        <ul className="approach_ul">
                            <li>
                                <Box className="approach_box" >
                                    <img src="/images/li-analysis.svg" alt="li" />
                                    <Box className="content_section">
                                        <h4>Analysis & Planning</h4>
                                        <Box className="content">
                                            <h6 className="mb-3">User research (or UX research) is a critical first step in the UX design process. It involves gathering insights and understanding the needs, preferences, and behaviors of the target users.</h6>
                                            <h6>User research helps you make informed design decisions based on real user data rather than assumptions or personal preferences.</h6>
                                        </Box>
                                    </Box>
                                </Box>
                            </li>
                            <li>
                                <Box className="approach_box" >
                                    <img src="/images/li-wireframing.svg" alt="li" />
                                    <Box className="content_section">
                                        <h4>Wireframing</h4>
                                        <Box className="content">
                                            <h6 className="mb-3">User research (or UX research) is a critical first step in the UX design process. It involves gathering insights and understanding the needs, preferences, and behaviors of the target users.</h6>
                                            <h6>User research helps you make informed design decisions based on real user data rather than assumptions or personal preferences.</h6>
                                        </Box>
                                    </Box>
                                </Box>
                            </li>
                            <li>
                                <Box className="approach_box" >
                                    <img src="/images/li-prototyping.svg" alt="li" />
                                    <Box className="content_section">
                                        <h4>Prototyping</h4>
                                        <Box className="content">
                                            <h6 className="mb-3">User research (or UX research) is a critical first step in the UX design process. It involves gathering insights and understanding the needs, preferences, and behaviors of the target users.</h6>
                                            <h6>User research helps you make informed design decisions based on real user data rather than assumptions or personal preferences.</h6>
                                        </Box>
                                    </Box>
                                </Box>
                            </li>
                            <li>
                                <Box className="approach_box" >
                                    <img src="/images/li-design.svg" alt="li" />
                                    <Box className="content_section">
                                        <h4>Design</h4>
                                        <div className="content">
                                            <h6 className="mb-3">User research (or UX research) is a critical first step in the UX design process. It involves gathering insights and understanding the needs, preferences, and behaviors of the target users.</h6>
                                            <h6>User research helps you make informed design decisions based on real user data rather than assumptions or personal preferences.</h6>
                                        </div>
                                    </Box>
                                </Box>
                            </li>
                        </ul>
                    </Grid>
                </Grid>
            </Box>

            <Box className="believe">
                <Grid container columnSpacing={3}>
                    <Grid xs={12} className="head" textAlign={'center'}>
                        <h2 className="mb-3">Believe in Seamless Experiences, <span>Our UI/UX Capabilities Speak Volumes</span></h2>
                        <p>We follow a meticulous approach to ensure your project's success:</p>
                    </Grid>
                </Grid>
                <Box className="content">
                    <Grid container columnSpacing={3} className="content_container">
                        <Grid sm={12} md={6} lg={6}>
                            <h3 className="mb-7">Responsive Design Development-</h3>
                            <Box className="points mb-7">
                                <span>1</span>
                                <Box>
                                    <h5>Experience Without Boundaries</h5>
                                    <p>We ensure your design is accessible and captivating on all platforms, from desktop to mobile and beyond.</p>
                                </Box>
                            </Box>
                            <Box className="points">
                                <span>2</span>
                                <Box>
                                    <h5>Effortless Navigation:</h5>
                                    <p>With the latest design standards, we make sure users seamlessly engage with your content, no matter the device.      </p>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid sm={12} md={6} lg={6} display={'flex'} justifyContent={'center'}>
                            <img src="/images/frame-design.svg" alt="frame" />
                        </Grid>
                        <Grid sm={12} md={6} lg={6} display={'flex'} justifyContent={'center'}>
                            <img src="images/frame-figma.svg" alt="frame" />
                        </Grid>
                        <Grid sm={12} md={6} lg={6}>
                            <h3 className="mb-7">Figma, PSD, Sketch, Adobe XD To HTML Development-</h3>
                            <Box className="points mb-7">
                                <span>1</span>
                                <Box>
                                    <h5>From Design to Reality:</h5>
                                    <p>We have the expertise to turn your beautiful design layout in Figma, PSD, Sketch, or Adobe XD into responsive, interactive web solutions.</p>
                                </Box>
                            </Box>
                            <Box className="points">
                                <span>2</span>
                                <Box>
                                    <h5>Pixel-Perfect Precision:</h5>
                                    <p>Every design element is carefully translated into HTML to maintain the aesthetic and functionality of your vision.</p>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>

            </Box>

            <Box className="we_create">
                <Grid container columnSpacing={3}>
                    <Grid xs={12} className="head" textAlign={'center'}>
                        <h2 className="mb-3">We create a tapestry of Innovation, <span>A Poetic Symphony of User Interaction</span></h2>
                        <p>We follow a meticulous approach to ensure your project's success:</p>
                    </Grid>
                    <Grid xs={12}>
                        <Box className="bottom_sec">
                            <img src="/images/spec.svg" alt="spec" />
                            <Box className="spec_box">
                                <h6 className="spec_heading mb-2">Input Control Evolution</h6>
                                <p className="spec_msg">From checkboxes and radio buttons to the dynamic dance of dropdowns and the versatile melody of toggles, we redefine control, crafting an interactive sonnet.</p>
                            </Box>
                            <Box className="spec_box">
                                <h6 className="spec_heading mb-2">Structured Containers</h6>
                                <p className="spec_msg">Accordion keys unlock creative realms, letting you mold your view and experience in this poetic dance of digital evolution.</p>
                            </Box>
                            <Box className="spec_box">
                                <h6 className="spec_heading mb-2">Navigational Mastery</h6>
                                <p className="spec_msg">Breadcrumbs lead your digital odyssey, sliders paint responsive landscapes, intuitive search fields unveil destinations, and icons whisper stories on seamless pages of exploration.</p>
                            </Box>
                            <Box className="spec_box">
                                <h6 className="spec_heading mb-2">Informational Brilliance</h6>
                                <p className="spec_msg">Tooltips enlighten like constellations, icons shine brightly, progress bars flow with dynamic data, notifications herald real-time events, and modal windows house interactive tales.</p>
                            </Box>
                        </Box>
                    </Grid>

                </Grid>
            </Box>

            <Box className="case_study">
                <Grid container columnSpacing={3}>
                    <Grid xs={12} className="mb-10" textAlign={'center'}>
                        <h2 className="mb-3">Case Studies</h2>
                        <p>In the realm of challenges, every case study is a chapter of solutions, each offering its unique wisdom to those who seek the knowledge it holds.</p>
                    </Grid>
                </Grid>
                <Box className="case_bottom">
                    <Grid container columnSpacing={3} justifyContent={'center'} rowGap={'24px'}>
                        {
                            caseStudies?.map((item) => (
                                <Grid xs={6} sm={6} md={4} lg={3}>
                                    <CaseStudyBox
                                        color="#79D05A"
                                        title={item?.head}
                                        description={item?.subHead}
                                        onClickFunction={() => {
                                            setOpenCaseModal(true);
                                            setClickedCase(item);
                                        }}
                                    />
                                </Grid>
                            ))
                        }
                    </Grid>
                </Box>
            </Box>

            <Box className="design_quotation">
                <RequestQuotationComponent />
            </Box>
            <Footer />
        </Box >
    );
}

export default DesignStudio;
