import { Box, Tab } from "@mui/material";
import React, { useState } from "react";
import MNavbar from "../../Components/Mobile/Navbar/MNavbar";
import Navbar from "../../Components/Desktop/Navbar/Navbar";
import useIsMobile from "../../SupportingFiles/MobileProvider";
import Grid from "@mui/material/Unstable_Grid2";
import "../../CommonSCSS/common.scss";
import CustomButton from "../../Components/Common/CustomButton/CustomButton";
import Footer from "../../Components/Desktop/Footer/Footer";
import CaseStudyBox from "../HealthCare/CaseStudyBox/CaseStudyBox";
import RequestQuotationComponent from "../../Components/Common/RequestComponent/RequestQuotationComponent";
import CaseStudyModal, {
  caseType,
} from "../../Components/Common/CaseStudy/CaseStudyModal";
import RequestQuotation from "../../Components/Common/RequestQuotationPopup/RequestQuotation";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import TabList from "@mui/lab/TabList";

function ERP() {
  const [open, setOpen] = useState<boolean>(false);
  const [openRequestQuotation, setOpenRequestQuotation] =
    useState<boolean>(false);
  const isMobile = useIsMobile();
  const [openCaseModal, setOpenCaseModal] = useState<boolean>(false);
  const [clickedCase, setClickedCase] = useState<caseType>({
    head: "",
    subHead: "",
    mainHeading: "",
    keywords: "",
    headPara: "",
    intro: "",
    challenge: "",
    solution: "",
    key: [],
    conclusion: "",
  });
  const [value, setValue] = React.useState("1");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const erpOfferingPointsArray = [
    {
      imgurl: "./images/Property_Healthcare.svg",
      heading: "Healthcare",
      pointsBoxArray: [
        {
          pointHeading: "Patient Management:",
          pointDesc:
            "Streamline patient records, appointments, and medical history, ensuring accurate and efficient healthcare delivery.",
        },
        {
          pointHeading: "Medical Billing:",
          pointDesc:
            "Automate billing processes, reducing errors and ensuring timely reimbursement for healthcare services.",
        },
        {
          pointHeading: "Appointment Scheduling:",
          pointDesc:
            "Enhance patient experience with a user-friendly appointment system that optimizes healthcare providers' schedules.",
        },
        {
          pointHeading: "Inventory and Pharmacy Management:",
          pointDesc:
            "Manage medical inventory, track pharmaceuticals, and streamline pharmacy operations for improved patient care.",
        },
        {
          pointHeading: "Compliance with Healthcare Regulations:",
          pointDesc:
            "Ensure adherence to healthcare regulations, promoting data security and compliance in patient care.",
        },
        {
          pointHeading: "Telehealth Solutions:",
          pointDesc:
            "Implement telehealth features for remote consultations, ensuring accessibility to healthcare services.",
        },
        {
          pointHeading: "Electronic Health Records (EHR) Integration:",
          pointDesc:
            "Integrate EHR systems for comprehensive patient information management and interoperability.",
        },
        {
          pointHeading: "Clinical Decision Support Systems:",
          pointDesc:
            "Utilize AI-driven decision support systems to assist healthcare professionals in diagnosis and treatment planning.",
        },
        {
          pointHeading: "Patient Engagement Apps:",
          pointDesc:
            "Develop mobile apps for patients to actively participate in their healthcare journey and access relevant information.",
        },
      ],
    },
    {
      imgurl: "./images/Property_Wellness&Fitness.svg",
      heading: "Wellness & Fitness",
      pointsBoxArray: [
        {
          pointHeading: "Personalized Fitness Plans:",
          pointDesc:
            "Offer personalized fitness plans based on individual goals, preferences, and health conditions.",
        },
        {
          pointHeading: "Nutrition Tracking:",
          pointDesc:
            "Include features for tracking and analyzing nutritional intake, promoting holistic wellness.",
        },
        {
          pointHeading: "Performance Analytics:",
          pointDesc:
            "Provide users with insights into their fitness performance, progress, and areas for improvement.",
        },
        {
          pointHeading: "Virtual Fitness Classes:",
          pointDesc:
            "Integrate virtual fitness classes for users to participate in workouts from the comfort of their homes.",
        },
        {
          pointHeading: "Wellness Challenges and Rewards:",
          pointDesc:
            "Implement challenges and rewards systems to motivate members and promote consistent engagement.",
        },
        {
          pointHeading: "Member Management:",
          pointDesc:
            "Effortlessly manage member profiles, subscriptions, and engagement to enhance the fitness center experience.",
        },
        {
          pointHeading: "Appointment Booking:",
          pointDesc:
            "Enable members to book appointments seamlessly, optimizing the utilization of wellness and fitness services.",
        },
        {
          pointHeading: "Billing and Invoicing:",
          pointDesc:
            "Automate billing processes, providing transparent and accurate financial transactions for members.",
        },
        {
          pointHeading: "Inventory and Equipment Management:",
          pointDesc:
            "Track and manage fitness equipment, ensuring their availability and proper maintenance.",
        },
        {
          pointHeading: "Class Scheduling:",
          pointDesc:
            "Facilitate easy scheduling of fitness classes, enhancing member participation and satisfaction.",
        },
      ],
    },
    {
      imgurl: "./images/Property_Retail_E-Commerce.svg",
      heading: "Retail & E-Commerce",
      pointsBoxArray: [
        {
          pointHeading: "Inventory Management:",
          pointDesc:
            "Optimize stock levels, reduce carrying costs, and ensure products are readily available to meet customer demand.",
        },
        {
          pointHeading: "Point of Sale (POS):",
          pointDesc:
            "Streamline the checkout process with an efficient POS system, providing a seamless shopping experience.",
        },
        {
          pointHeading: "Order and Delivery Management:",
          pointDesc:
            "Manage orders, track deliveries, and provide real-time updates to enhance the overall customer experience.",
        },
        {
          pointHeading: "CRM for Customer Engagement:",
          pointDesc:
            "Build and maintain customer relationships through personalized engagement and targeted marketing.",
        },
        {
          pointHeading: "E-Commerce Integration:",
          pointDesc:
            "Seamlessly integrate with e-commerce platforms, expanding the reach and accessibility of products and services.",
        },
        {
          pointHeading: "Promotion and Discount Management:",
          pointDesc:
            "Facilitate the creation and management of promotional offers and discounts to drive sales.",
        },
        {
          pointHeading: "Customer Loyalty Programs:",
          pointDesc:
            "Develop loyalty programs to reward and retain customers, encouraging repeat business.",
        },
        {
          pointHeading: "Returns and Refunds Processing:",
          pointDesc:
            "Streamline returns and refunds processes, ensuring a hassle-free experience for customers.",
        },
        {
          pointHeading: "Multi-Channel Selling:",
          pointDesc:
            "Expand sales channels by integrating with various online platforms and marketplaces.",
        },
      ],
    },
    {
      imgurl: "./images/Property_Travel_Hospitality.svg",
      heading: "Travel & Hospitality",
      pointsBoxArray: [
        {
          pointHeading: "Event Management:",
          pointDesc:
            "Offer features for managing and organizing events within hotels or travel destinations.",
        },
        {
          pointHeading: "Concierge Services Integration:",
          pointDesc:
            "Integrate digital concierge services for personalized recommendations and assistance.",
        },
        {
          pointHeading: "Contactless Check-In/Check-Out:",
          pointDesc:
            "Implement contactless processes for check-in and check-out, enhancing safety and efficiency.",
        },
        {
          pointHeading: "Language Translation Services:",
          pointDesc:
            "Include language translation features to cater to a diverse range of international guests.",
        },
        {
          pointHeading: "Local Experience Recommendations:",
          pointDesc:
            "Provide guests with recommendations for local attractions, dining, and cultural experiences.",
        },
      ],
    },
    {
      imgurl: "./images/Property_Transportation_Logistics.svg",
      heading: "Transportation & Logistics",
      pointsBoxArray: [
        {
          pointHeading: "Fleet Management:",
          pointDesc:
            "Optimize fleet operations, track vehicles, and enhance overall efficiency in transportation logistics.",
        },
        {
          pointHeading: "Route Optimization:",
          pointDesc:
            "Plan and optimize delivery routes, minimizing costs and ensuring timely deliveries.",
        },
        {
          pointHeading: "Billing and Invoicing:",
          pointDesc:
            "Streamline billing processes, ensuring accuracy and transparency in financial transactions.",
        },
        {
          pointHeading: "Inventory and Warehouse Management:",
          pointDesc:
            "Efficiently manage inventory and warehouse operations, improving overall logistics efficiency.",
        },
        {
          pointHeading: "Compliance Tracking:",
          pointDesc:
            "Monitor and ensure compliance with industry regulations, reducing risks and improving overall logistics management.",
        },
        {
          pointHeading: "Temperature-Controlled Logistics:",
          pointDesc:
            "Implement solutions for monitoring and maintaining temperature-sensitive shipments.",
        },
        {
          pointHeading: "Predictive Maintenance:",
          pointDesc:
            "Utilize predictive analytics to schedule maintenance tasks and reduce vehicle downtime.",
        },
        {
          pointHeading: "Supply Chain Visibility:",
          pointDesc:
            "Enhance transparency in the supply chain by providing real-time visibility into shipments and inventory.",
        },
        {
          pointHeading: "Smart Containers and Packaging:",
          pointDesc:
            "Implement IoT-enabled containers for tracking and monitoring the condition of goods during transit.",
        },
        {
          pointHeading: "Customs Compliance Automation:",
          pointDesc:
            "Streamline customs compliance processes to expedite the movement of goods across borders.1",
        },
      ],
    },
    {
      imgurl: "./images/Property_Automotive.svg",
      heading: "Automotive",
      pointsBoxArray: [
        {
          pointHeading: "Consignment Tracking:",
          pointDesc:
            "Effectively track and manage consignments, ensuring transparency and timely deliveries.",
        },
        {
          pointHeading: "Warehouse Management:",
          pointDesc:
            "Streamline inventory, shipping, and distribution processes for efficient warehouse management.",
        },
        {
          pointHeading: "Truck Direction Routing:",
          pointDesc:
            "Optimize truck routes, avoid traffic, and enhance overall transportation efficiency.",
        },
        {
          pointHeading: "Supply Chain Software Solutions:",
          pointDesc:
            "Execute supply chain transactions, maintain supplier relationships, and automate operations.",
        },
        {
          pointHeading: "Logistics Software Automation:",
          pointDesc:
            "Automate logistics processes, coordinate documents, invoices, and real-time tracking for streamlined operations.",
        },
        {
          pointHeading: "Fleet Management Software:",
          pointDesc:
            "Track fleet vehicles, monitor real-time performance, and enhance overall fleet efficiency.",
        },
        {
          pointHeading: "RFID and Beacon Solutions:",
          pointDesc:
            "Implement RFID and Beacon technology for tracking goods and inventories throughout the supply chain.",
        },
        {
          pointHeading: "Traffic Management Software:",
          pointDesc:
            "Implement real-time traffic management systems powered by advanced geo-mapping and location technologies.",
        },
        {
          pointHeading: "Dealer and Service Center Locator:",
          pointDesc:
            "Simplify the process for customers to find dealers and service centers, enhancing convenience and driving qualified leads.",
        },
        {
          pointHeading: "Vehicle Maintenance Reporting:",
          pointDesc:
            "Streamline reporting on vehicle maintenance, ensuring timely updates and efficient management.",
        },
        {
          pointHeading: "Order Booking Management:",
          pointDesc:
            "Manage vehicle orders seamlessly, optimizing the booking process for customers.",
        },
        {
          pointHeading: "Fuel Management and Consumption:",
          pointDesc:
            "Monitor and manage fuel consumption efficiently, ensuring cost-effective operations.",
        },
        {
          pointHeading: "Breakdown Management:",
          pointDesc:
            "Address breakdowns effectively, minimizing downtime and ensuring prompt assistance.",
        },
      ],
    },
    {
      imgurl: "./images/Property_Media_Entertainment.svg",
      heading: "Media and Entertainment",
      pointsBoxArray: [
        {
          pointHeading: "Big Data for Content Personalization:",
          pointDesc:
            "Utilize big data to personalize content, creating engaging and tailored experiences for users.",
        },
        {
          pointHeading: "Artificial Intelligence for Content Creation:",
          pointDesc:
            "Incorporate AI to enhance content creation, targeting advertising, and improving content discovery.",
        },
        {
          pointHeading: "IoT for Enhanced User Engagement:",
          pointDesc:
            "Implement IoT solutions to enhance user engagement, offering innovative and interactive experiences.",
        },
        {
          pointHeading: "ERP for Media Operations:",
          pointDesc:
            "Streamline back-office operations with ERP solutions tailored to the media and entertainment industry.",
        },
        {
          pointHeading: "CRM for Audience Connection:",
          pointDesc:
            "Build strong connections with the audience through effective CRM tools, ensuring personalized experiences.",
        },
        {
          pointHeading: "ERP for Content Distribution:",
          pointDesc:
            "Manage content distribution processes efficiently, ensuring timely delivery to various channels.",
        },
        {
          pointHeading: "Cybersecurity for Media Protection:",
          pointDesc:
            "Implement robust cybersecurity measures to protect sensitive media data and ensure data integrity.",
        },
        {
          pointHeading: "Social Media Platform Development:",
          pointDesc:
            "Create user-friendly web and mobile solutions with the latest trends in media and entertainment.",
        },
        {
          pointHeading: "Weather Forecast Integration:",
          pointDesc:
            "Integrate weather forecast data for more accurate and personalized content delivery.",
        },
        {
          pointHeading: "Currency Converter Integration:",
          pointDesc:
            "Include currency converter functionality for global audiences, enhancing user experience.",
        },
        {
          pointHeading: "GPS-Based Location Services:",
          pointDesc:
            "Utilize GPS technology to provide location-based services and enhance user engagement.",
        },
      ],
    },
    {
      imgurl: "./images/Property_Education_E-Learning.svg",
      heading: "Education & E-Learning",
      pointsBoxArray: [
        {
          pointHeading: "Online Examination Integration:",
          pointDesc:
            "Integrate online platform for conducting examinations securely and efficiently.",
        },
        {
          pointHeading: "Virtual Classroom Integration:",
          pointDesc:
            "Integrate virtual classrooms for interactive and engaging online learning experiences.",
        },
        {
          pointHeading: "AI-based Tutoring System:",
          pointDesc:
            "Utilize AI for personalized tutoring, adapting to individual student needs and learning styles.",
        },
        {
          pointHeading: "Student Performance Analytics:",
          pointDesc:
            "Use data analytics to track and analyze student performance, providing valuable insights for improvement.",
        },
        {
          pointHeading: "Student Information System:",
          pointDesc:
            "Manage student information seamlessly, optimizing administrative processes in educational institutions.",
        },
        {
          pointHeading: "Course Management:",
          pointDesc:
            "Streamline course creation, management, and delivery, enhancing the overall learning experience.",
        },
        {
          pointHeading: "E-Learning Platform Integration:",
          pointDesc:
            "Integrate with e-learning platforms, expanding reach and providing accessible education.",
        },
        {
          pointHeading: "CRM for Student Engagement:",
          pointDesc:
            "Foster student engagement through effective CRM tools, ensuring personalized support and communication.",
        },
      ],
    },
  ];

  const erpExperienceArray = [
    {
      imgUrl: "/images/travelKey1.svg",
      heading: "Innovative Tech Integration",
      desc: "We're at the forefront of transforming industries with cutting-edge technology, seamlessly integrating innovative solutions to redefine how you operate.",
    },
    {
      imgUrl: "/images/travelKey2.svg",
      heading: "Tech-Driven Ideation",
      desc: " Our unique approach takes your business concepts and turns them into engaging, interactive, and technology-infused experiences within our ERP systems.",
    },
    {
      imgUrl: "/images/travelKey3.svg",
      heading: "Tailored Tech Solutions",
      desc: "We specialize in crafting ERP products that are not just solutions but tailored experiences, precisely designed to meet the distinctive needs of your industry.",
    },
    {
      imgUrl: "/images/travelKey4.svg",
      heading: "Scalability for You",
      desc: "At our core is a commitment to prioritize your business needs, delivering ERP solutions that scale with you, no matter the size of your enterprise.",
    },
    {
      imgUrl: "/images/travelKey5.svg",
      heading: "Tech-Infused Efficiency",
      desc: "We bring a personal touch to efficiency, strategically applying advanced technological solutions across ERP systems to enhance your unique operations.",
    },
    {
      imgUrl: "/images/travelKey6.svg",
      heading: "Automation for Your Success",
      desc: "Our unwavering dedication to automation and technological progress propels your ERP experience to unparalleled heights, ensuring optimal performance across every facet of your business.",
    },
  ];
  const caseStudies: caseType[] = [
    {
      head: "InsureTech Revolution Unleashed",
      subHead: "Tech-Infused Insurance Mastery",
      keywords:
        "InsurTech Innovation, Digital Transformation, Strategic Growth in Insurance, Cutting-edge InsurTech Marketing",
      mainHeading:
        "Insurance Broker's Digital Odyssey: A Transformation Unveiled",
      headPara: "",
      intro:
        "Embarking on a digital frontier, our partnership with an insurance broker on a transformative journey illustrates the profound impact of InsureTech branding, strategic digital initiatives, and technologically driven marketing.",
      challenge:
        "The traditional insurance landscape posed challenges of adaptation and growth in a digital era. Our task was not just to navigate this transition but to position the broker as a digital pioneer, seamlessly merging tech advancements with insurance expertise.",
      solution:
        "Our collaboration involved more than rebranding; it was a systematic transformation. From leveraging data analytics for market insights to executing cutting-edge growth strategies, each move was calibrated for a tech-forward ascent in the competitive insurance domain.",
      key: [
        {
          heading: "InsureTech Branding Matrix",
          content:
            "Forged a brand identity that embraced InsureTech innovation, portraying the broker as a digital authority in insurance services.",
        },
        {
          heading: "Digital Growth Alchemy",
          content:
            "Orchestrated growth initiatives that harnessed data analytics, ensuring the broker's expansion was not just strategic but informed by technology.",
        },
        {
          heading: "Precision InsurTech Marketing Blitz",
          content:
            "Executed a digitally charged marketing campaign, utilizing AI-driven targeting and immersive experiences to captivate a tech-savvy insurance audience.",
        },
        {
          heading: "Innovative UI/UX Paradigm",
          content:
            "Revolutionized client interactions with an innovative UI/UX design, ensuring digital platforms were as cutting-edge as the insurance solutions they offered.",
        },
        {
          heading: "AI-Enhanced Policy Recommendations",
          content:
            "Implemented an AI-driven system for personalized policy recommendations, analyzing customer profiles and market trends for tailored insurance solutions.",
        },
        {
          heading: "InsurTech Community Engagement",
          content:
            "Fostered community engagement through social media and digital platforms, creating a space for clients to share experiences, ask questions, and build a sense of trust.",
        },
      ],
      conclusion:
        "In the dynamic intersection of insurance and technology, our collaboration with this broker showcases the art of transforming a traditional service into a digital powerhouse. Witness the convergence of insurance and tech in a groundbreaking ascent to digital insurance mastery!",
    },
    {
      head: "Elevating Insurance through Digital Alliances",
      subHead: "Digital-First B2B2C Insurance Excellenc",
      keywords:
        "B2B2C Insurance Solutions, Lead Generation, Digital Transformation in Insurance, InsurTech Marketing, Strategic Alliances",
      mainHeading: "Revolutionizing Insurance: A Digital B2B2C Journey",
      headPara: "",
      intro:
        "In the realm of insurance evolution, our partnership with a forward-thinking broker organization unfolds a narrative of digital reinvention, strategic B2B2C collaborations, and a relentless focus on lead generation in the digital landscape.",
      challenge:
        "The insurance sector demanded more than a mere transition; it sought a complete digital metamorphosis. The challenge was not only to position the broker as a digital authority but also to forge impactful B2B2C alliances while prioritizing lead generation through cutting-edge digital strategies.",
      solution:
        "Our journey with the broker was not just about embracing digital; it was a strategic shift. From conceptualizing innovative B2B2C solutions to executing lead generation strategies, every step was aimed at propelling the broker into a digital-first era of insurance.",
      key: [
        {
          heading: "Digital-First B2B2C Solutions",
          content:
            "Engineered insurance solutions that seamlessly integrated B2B2C dynamics, positioning the broker as a digital facilitator for businesses and end consumers.",
        },
        {
          heading: "Strategic Digital Alliances",
          content:
            "Forged partnerships with businesses and digital platforms, expanding the broker's reach and enhancing lead generation channels through strategic collaborations.",
        },
        {
          heading: "Lead Generation Acceleration",
          content:
            "Implemented advanced lead generation strategies, utilizing data analytics, AI-driven targeting, and personalized customer journeys to drive engagement and conversion.",
        },
        {
          heading: "InsurTech Marketing Blitz",
          content:
            "Executed a comprehensive InsurTech marketing campaign, leveraging digital channels and analytics to optimize outreach, foster brand awareness, and drive lead acquisition.",
        },
        {
          heading: "Innovative UI/UX Paradigm",
          content:
            "Redefined client interactions with a user-centric design, ensuring digital touchpoints were intuitive and engaging, contributing to enhanced lead generation and conversion.",
        },
        {
          heading: "AI-Enhanced Customer Engagement",
          content:
            "Enabling the client to maximize their Return on Investment (ROI) in marketing, ensuring every campaign is purposeful and fruitful.",
        },
      ],
      conclusion:
        "Through the power of data analytics, our client achieved a transformation that extended beyond numbers and metrics. The retail landscape was now equipped with data-driven precision, enabling the client to make informed decisions and resonate more profoundly with their customers. Data became not just information but a catalyst for growth, innovation, and retail excellence.",
    },
    {
      head: "QA Team Augmentation",
      subHead: "Elevating Software Quality: QA Team Augmentation Triumph",
      keywords:
        "QA Team Augmentation, Software Testing, Quality Enhancement, Accelerated Time to Market",
      mainHeading: "Elevating Software Quality: QA Team Augmentation Triumph",
      headPara: "Perfecting Quality Assurance for Timely Excellence",
      intro:
        "In the dynamic realm of software development, ensuring top-notch quality is the key to success. This case study delves into the journey of a software development company in India, aiming to fortify their software testing capabilities and elevate their quality standards.",
      challenge:
        "Our client boasted a growing portfolio of software products, a testament to their innovation and dedication. However, as the portfolio expanded, maintaining stringent quality standards became a formidable challenge. The constraints of limited testing resources began to cast a shadow, potentially jeopardizing their commitment to quality.",
      solution:
        "The solution lay in the augmentation of the Quality Assurance (QA) team. A squad of experienced QA engineers, well-versed in the art of software testing, was integrated into the client's existing team.",
      key: [
        {
          heading: "QA Expertise Augmentation",
          content:
            "The infusion of seasoned QA engineers, complementing the client's in-house team, and offering deep expertise in quality assurance.",
        },
        {
          heading: "Comprehensive Testing",
          content:
            "A thorough and comprehensive testing regimen, including functional, performance, and security testing to leave no stone unturned.",
        },
        {
          heading: "Quality Enhancement",
          content:
            "A pledge to enhance software quality, ensuring that every product that left the development pipeline met the highest standards.",
        },
        {
          heading: "Time to Market Acceleration",
          content:
            "The collaborative effort resulted in accelerated time to market, enabling the client to be more responsive to market demands and opportunities.",
        },
        {
          heading: "Streamlined Workflows",
          content:
            "The integration of the QA augmentation team was seamless, with workflows aligned to optimize efficiency and productivity.",
        },
        {
          heading: "Cost-Effective Solution:",
          content:
            "The augmentation strategy proved to be cost-effective, providing maximum quality with optimal resource utilization.",
        },
      ],
      conclusion:
        "With the augmentation of the QA team, our client achieved a pivotal transformation in their software development journey. Quality assurance was not just a box to tick; it became an intrinsic part of their software development philosophy. The accelerated time to market was not a mere outcome; it was a testament to their unwavering commitment to delivering excellence in every line of code. Quality became a tradition, and timely delivery became their hallmark.",
    },
  ];

  return (
    <Box className="pagesWrapper">
      <RequestQuotation
        open={openRequestQuotation}
        setOpen={setOpenRequestQuotation}
        popup_heading=""
      />

      <CaseStudyModal
        open={openCaseModal}
        setOpen={setOpenCaseModal}
        item={clickedCase}
      />

      {isMobile ? <MNavbar /> : <Navbar open={open} setOpen={setOpen} />}

      <Box className="erpHeader mb-20 mb-40-m">
        <Grid container spacing={3} alignItems="center">
          <Grid xs={12} textAlign={"center"}>
            <h1 className="mb-6">
              Powering Diverse <span>Industries</span> with{" "}
              <span>Tailored Solutions!</span>
            </h1>
            <p className="mb-4">
              Embark on a journey of business transformation with our ERP – a
              dynamic and robust enterprise solution meticulously engineered to
              tackle the specific challenges prevalent in various industries.
              Delve into tailor-made modules designed for each sector, harness
              the unique strengths of our ERP, and witness a steadfast
              commitment to unwavering product performance and seamless
              delivery. Elevate your operations and unlock the true potential of
              your enterprise with ERP.
            </p>
          </Grid>

          <Grid xs={12}>
            <Box className="erp_header_background">
              <img
                src="./images/erp_header_img.svg"
                alt="erp"
                className="erp-header-img"
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box className="erp_offerings ">
        <Grid container columnSpacing={3} rowSpacing={0} marginBottom={"50px"}>
          <Grid xs={12} textAlign={"center"}>
            <h1 className="mb-6">
              Our Limitless <span>Offerings Expand to</span>
            </h1>
            <p>
              Tech-Infused Triumph: Elevate, Propel, Transform – Your Digital
              Odyssey Unleashed.
            </p>
          </Grid>
        </Grid>
        <TabContext value={value}>
          <Grid
            container
            columnSpacing={3}
            className="offerings_tab_sec"
            alignItems={"center"}
          >
            <Grid xs={12} md={5}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab label={"Healthcare"} value="1" />
                <Tab label={"Wellness & Fitness"} value="2" />
                <Tab label={"Retail & E-Commerce"} value="3" />
                <Tab label={"Travel & Hospitality"} value="4" />
                <Tab label={"Transportation & Logistics"} value="5" />
                <Tab label={"Automotive"} value="6" />
                <Tab label={"Media and Entertainment"} value="7" />
                <Tab label={"Education & E-Learning"} value="8" />
              </TabList>
            </Grid>
            <Grid xs={12} md={7} className="mt-40-m">
              {erpOfferingPointsArray?.map((data, index) => (
                <TabPanel value={`${index + 1}`}>
                  <img src={data.imgurl} alt="" />
                  <h2 className="mt-7 mb-6">{data.heading}</h2>
                  <Box className="points_box">
                    <ul>
                      {data.pointsBoxArray.map((subdata, index) => (
                        <li>
                          <span>{index + 1}</span>
                          <Box className="point_desc">
                            <h4>{subdata.pointHeading}</h4>
                            <p>{subdata.pointDesc}</p>
                          </Box>
                        </li>
                      ))}
                    </ul>
                  </Box>
                  <Box className="ctaBtn mt-6">
                    <CustomButton
                      text_name="Inquire"
                      class_name="blueMdBtn"
                      onClickFunction={() => setOpenRequestQuotation(true)}
                    />
                  </Box>
                </TabPanel>
              ))}
            </Grid>
          </Grid>
        </TabContext>
      </Box>

      {/* <Box className="erp_expertise_sec mb-20 mb-40-m">
        <Grid container columnSpacing={3}>
          <Grid xs={12} className="head mb-10" textAlign={"center"}>
            <h2 className="mb-3">How We Elevate Your ERP Experience?</h2>
            <p>We are ready with that whatever you want</p>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          {erpExperienceArray?.map((data, index) => (
            <Grid xs={12} sm={6} lg={4}>
              <Box className="content">
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <div>
                    <span>{index + 1}</span>
                    <h5>{data.heading}</h5>
                  </div>
                  <img src={data.imgUrl} alt="" />
                </Box>
                <div className="separator"></div>
                <p>{data.desc}</p>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box> */}

      <Box className="trans_path">
        <Grid container rowSpacing={3}>
          <Grid md={6} lg={6}>
            <Box>
              <Grid container spacing={3} className="section1">
                <Grid xs={12} className="head mb-6">
                  <h2 className="mb-3">How We Elevate Your ERP Experience?</h2>
                  <p>
                    Periodic Maintenance & Inspection App: Designed to
                    streamline maintenance processes, enhance equipment
                    performance, and ensure compliance, our app is a
                    comprehensive solution for businesses seeking efficiency in
                    their maintenance routines.
                  </p>
                </Grid>
                <Grid xs={12} sm={6} md={6} lg={6} className="main_box">
                  <Box className="trans_box">
                    <h5>Innovative Tech Integration</h5>
                    <p>
                      We're at the forefront of transforming industries with
                      cutting-edge technology, seamlessly integrating innovative
                      solutions to redefine how you operate.
                    </p>
                  </Box>
                </Grid>
                <Grid xs={12} sm={6} md={6} lg={6} className="main_box">
                  <Box className="trans_box">
                    <h5>Tech-Driven Ideation</h5>
                    <p>
                      Our unique approach takes your business concepts and turns
                      them into engaging, interactive, and technology-infused
                      experiences within our ERP systems.
                    </p>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid
            sm={12}
            md={6}
            lg={6}
            display={"flex"}
            alignItems={"end"}
            justifyContent={"center"}
          >
            <img src="/images/elevate_erp_bg.svg" alt="" />
          </Grid>
          <Grid lg={12}>
            <Box>
              <Grid container spacing={3} className="section2 section1">
                <Grid xs={12} sm={6} md={3} className="main_box">
                  <Box className="trans_box">
                    <h5>Tailored Tech Solutions</h5>
                    <p>
                      We specialize in crafting ERP products that are not just
                      solutions but tailored experiences, precisely designed to
                      meet the distinctive needs of your industry.
                    </p>
                  </Box>
                </Grid>
                <Grid xs={12} sm={6} md={3} className="main_box">
                  <Box className="trans_box">
                    <h5>Scalability for You</h5>
                    <p>
                      At our core is a commitment to prioritize your business
                      needs, delivering ERP solutions that scale with you, no
                      matter the size of your enterprise.
                    </p>
                  </Box>
                </Grid>
                <Grid xs={12} sm={6} md={3} className="main_box">
                  <Box className="trans_box">
                    <h5>Tech-Infused Efficiency</h5>
                    <p>
                      We bring a personal touch to efficiency, strategically
                      applying advanced technological solutions across ERP
                      systems to enhance your unique operations.
                    </p>
                  </Box>
                </Grid>
                <Grid xs={12} sm={6} md={3} className="main_box">
                  <Box className="trans_box">
                    <h5>Automation for Your Success</h5>
                    <p>
                      Our unwavering dedication to automation and technological
                      progress propels your ERP experience to unparalleled
                      heights, ensuring optimal performance across every facet
                      of your business.
                    </p>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* <Grid
        container
        spacing={3}
        justifyContent="center"
        marginBottom={"80px"}
        className="mb-40-m"
      >
        <Grid xs={12} textAlign="center" marginBottom={"16px"}>
          <h1 className="mb-6">
            Case <span>Studies</span>
          </h1>
          <p>
            In the realm of challenges, every case study is a chapter of
            solutions, each offering its unique wisdom to those who seek the
            knowledge it holds.
          </p>
        </Grid>
        {caseStudies?.map((item) => (
          <Grid xs={6} lg={3} textAlign="center">
            <CaseStudyBox
              color="#79D05A"
              title={item?.head}
              description={item?.subHead}
              onClickFunction={() => {
                setOpenCaseModal(true);
                setClickedCase(item);
              }}
            />
          </Grid>
        ))}

      </Grid> */}

      <Box className="design_quotation">
        <RequestQuotationComponent />
      </Box>

      <Footer />
    </Box>
  );
}
export default ERP;
