import { InlineWidget } from "react-calendly";
import { PopupWidget } from "react-calendly";
import "../FieldTypes.scss";

const Calendly = () => {
    return (
        <div className="calendly_head">
            <InlineWidget url="https://calendly.com/evervent" />
            {/* <PopupWidget
                url="https://calendly.com/evervent"
                rootElement={document.getElementById("root") as HTMLElement}
                text="Click here to schedule!"
                textColor="#ffffff"
                color="#00a2ff"
            /> */}
        </div>
    )
}

export default Calendly