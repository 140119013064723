import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { Typography } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import "./FAQ.scss";

const FAQ = ({
  data,
}: {
  data: { expanded: string; title: string; description: any }[];
}) => {
  const [expanded, setExpanded] = React.useState<string | false>("panel1");

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <div className="faq">
      {data.map((data, index) => (
        <Accordion
          key={index}
          expanded={expanded === data.expanded}
          onChange={handleChange(data.expanded)}
        >
          <AccordionSummary
            expandIcon={
              expanded === data.expanded ? (
                <KeyboardArrowUpIcon />
              ) : (
                <KeyboardArrowDownIcon />
              )
            }
            aria-controls={data.expanded + "bh-content"}
            id={data.expanded + "bh-header"}
          >
            <Typography>{data.title}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{data.description}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default FAQ;
