import React from "react";
import { Box } from "@mui/material";

interface InsetBoxProps {
  title: string;
  description: string;
}

function InsetBox(props: InsetBoxProps) {
  const { title, description } = props;

  return (
    <Box
      sx={{
        borderRadius: "12px",
        boxShadow: "0px 4px 8px 0px rgba(113, 101, 228, 0.20) inset",
        borderBottom: "1px dashed #7165E4",
        padding: "12px",
      }}
    >
      <h6
        style={{
          fontSize: "16px",
          fontWeight: "bold",
          marginBottom: "12px",
        }}
      >
        {title}
      </h6>
      <p>{description}</p>
    </Box>
  );
}

export default InsetBox;
