import React, { useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import ChooseBox from "./ChooseBox/ChooseBox";

function WhyChooseUs() {
  const [mouseEnterStatus, setMouseEnterStatus] = useState<boolean>(false);
  return (
    <>
      <Grid container spacing={3}>
        <Grid xs={12} textAlign={"center"}>
          <h2>Why Choose Us</h2>
          <p className="mb-6">
            Our array of groundbreaking technologies is geared towards crafting
            unparalleled mobile experiences for the future of your business.
          </p>
        </Grid>
      </Grid>
      <div
        onMouseEnter={() => {
          setMouseEnterStatus(true);
        }}
        onMouseLeave={() => {
          setMouseEnterStatus(false);
        }}
      >
        <Grid container spacing={3}>
          <Grid xs={12} md={6}>
            <ChooseBox
              isActive={mouseEnterStatus}
              imageSrc="./images/designs.svg"
              title="Create Engaging Designs"
              description="Allurive, our in-house design suite, employs a distinctive approach to craft outstanding customer experiences tailored for your business."
            />
          </Grid>
          <Grid xs={12} md={6}>
            <ChooseBox
              imageSrc="./images/business.svg"
              title="Enhance Your Business Decision-Making"
              description="Discover a hidden gem, Retainlytics, for collecting precise data and propelling customer-centric marketing tailored to your brand."
            />
          </Grid>
          <Grid xs={12} md={6}>
            <ChooseBox
              imageSrc="./images/personalize.svg"
              title="Experience the Freedom to Personalize"
              description="Generate Exceptional Business Value with a Customized App Designed to Meet Your Specific Business Requirements."
            />
          </Grid>
          <Grid xs={12} md={6}>
            <ChooseBox
              imageSrc="./images/application.svg"
              title="A Sturdy and Expandable Application"
              description="Enhanced scalability opens doors to capture and oversee emerging opportunities for your business in the future."
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
}

export default WhyChooseUs;
