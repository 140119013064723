import React, { useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Input } from "@mui/material";
import CustomButton from "../../Components/Common/CustomButton/CustomButton";
import ScheduleDemo from "../../Components/Common/ScheduleDemo/ScheduleDemo";
import { validateEmail } from "../../SupportingFiles/HelpingFunctions";
import { toast } from "react-toastify";
import { FORM_SERVICES } from "../../Services/FormServices";

function Subscribe() {
  const [open, setOpen] = useState<boolean>(false);
  const [emailValue, setEmailValue] = useState<{
    value: string;
    warning: boolean;
  }>({ value: "", warning: false });

  const onSubmitSubscriber = () => {
    if (!validateEmail(emailValue.value)) {
      setEmailValue({ ...emailValue, warning: true });
    } else {
      FormSubmit();
    }
  };

  const FormSubmit = () => {
    const onSuccess = (res: any) => {
      const results = res.results;
      const error = results.error;
      if (error === false) {
        const response = results.response;
        console.log("enteddd");
        toast.success("Request Submitted Successfully");
        setEmailValue({ value: "", warning: false });
      }
    };

    const onError = (err: any) => {
      console.log("updatefalse", err);
    };
    // SELECTED_QUOTE_DATA.product_code;
    let dto = {
      name: "Subscriber",

      mobile: "9999999999",

      email: emailValue.value,

      description: "N/A",

      productname: "N/A",
    };

    FORM_SERVICES.FormServices(onSuccess, onError, dto);
  };

  return (
    <>
      <ScheduleDemo open={open} setOpen={setOpen} />

      <Box sx={{ maxWidth: "1400px", margin: "auto", padding: "0px 12px" }}>
        <Grid
          container
          columnSpacing={5}
          rowSpacing={3}
          alignItems="center"
          border="1px dashed #6458D8"
          borderRadius="20px"
          marginTop="80px"
          marginBottom="80px"
          overflow="hidden"
        >
          <Grid
            xs={12}
            md={6}
            sx={{
              background: "#6458D8",
              padding: "20px",
            }}
          >
            <h3 className="mb-4" style={{ color: "#ffffff" }}>
              Stay current with our latest insights
            </h3>
            <Input
              placeholder="Write your email here..."
              sx={{
                background: "#ffffff",
                borderRadius: "8px",
                height: "60px",
                maxWidth: "400px",
                width: "100%",
                paddingLeft: "16px",
                marginBottom: "24px",
                "&:after": {
                  display: "none",
                },
                "&:before": {
                  display: "none",
                },
              }}
              value={emailValue.value}
              onChange={(e) => {
                setEmailValue({ warning: false, value: e.target.value });
              }}
            />
            {emailValue.warning && <div>Please Enter valid Email</div>}
            <div className="ctaBtn">
              <CustomButton
                text_name="Subscribe"
                class_name="yellowLgBtn"
                onClickFunction={() => {
                  onSubmitSubscriber();
                }}
              />
            </div>
          </Grid>
          <Grid xs={12} md={6}>
            <h3 className="mb-4">Start Creating Your Brand with us</h3>
            <div className="ctaBtn">
              <CustomButton
                text_name="Estimate Your Project"
                class_name="blackLgBtn"
                onClickFunction={() => {
                  setOpen(true);
                }}
              />
            </div>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default Subscribe;
