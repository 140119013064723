import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useState } from "react";
import CustomButton from "../../Components/Common/CustomButton/CustomButton";
import RequestQuotation from "../../Components/Common/RequestQuotationPopup/RequestQuotation";

function GetInTouch() {
  const [openRQuote, setOpenRquote] = useState(false);

  return (
    <>
      <RequestQuotation
        open={openRQuote}
        setOpen={setOpenRquote}
      />
      <Grid container spacing={3}>
        <Grid xs={12}>
          <Box
            className="greyBox my-20 my-40-m"
            borderRadius={"20px"}
            padding={"24px"}
            display={"flex"}
            flexWrap={"wrap"}
            alignSelf={"stretch"}
            alignItems={"center"}
          >
            <Box
              sx={{
                minWidth: "320px",
                alignItems: "flex-start",
                flex: "1 0 0",
              }}
            >
              <h2>
                We Provide Your Business with Innovative Digital Solutions
                Beyond the Ordinary.
              </h2>
              <div className="ctaBtn mt-4">
                <CustomButton
                  text_name="Get in Touch"
                  class_name="blackLgBtn"
                  onClickFunction={() => {
                    setOpenRquote(true);
                  }}
                />
              </div>
            </Box>
            <img src="./images/getintouch.svg" />
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default GetInTouch;
