import React, { ReactNode } from "react";
import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

interface NumberBoxProps {
  title: string;
  number: number;
  paragraph1?: string;
  paragraph2?: string;
  listitem1?: string;
  listitem2?: string;
  listitem3?: string;
  listitem4?: string;
}

function NumberBox({
  title,
  number,
  paragraph1,
  paragraph2,
  listitem1,
  listitem2,
  listitem3,
  listitem4,
}: NumberBoxProps) {
  return (
    <Box className="numberBox">
      <div className="heading">
        <h4>{title}</h4>
        <div className="numberIcon">{number}</div>
      </div>
      {paragraph1 && <p>{paragraph1}</p>}
      {paragraph2 && <p>{paragraph2}</p>}
      <ul>
        {listitem1 && <li>{listitem1}</li>}
        {listitem2 && <li>{listitem2}</li>}
        {listitem3 && <li>{listitem3}</li>}
        {listitem4 && <li>{listitem4}</li>}
      </ul>
    </Box>
  );
}

export default NumberBox;
