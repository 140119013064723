import React, { ReactNode } from "react";
import { Box } from "@mui/material";

interface DashBorderBoxProps {
  title: string;
  description: string;
}

function DashBorderBox({ title, description }: DashBorderBoxProps) {
  return (
    <Box className="dashBorderBox">
      <h6>{title}</h6>
      <p>{description}</p>
    </Box>
  );
}

export default DashBorderBox;
