import { URLs } from "../../Shared/URL";
import { GetAPI } from "../AxiosAPIInstance/AxiosInstance";

const getHomePageData = (onSuccess: Function, onError: Function) => {
  GetAPI.call(URLs.LOAD_HOMEPAGE_DATA, (res: any) => onSuccess(res), onError);
};

const getCMSPageData = (onSuccess: Function, onError: Function) => {
  GetAPI.call(URLs.CMS_DATA, (res: any) => onSuccess(res), onError);
};

export const HOME_SERVICES = {
  getHomePageData,
  getCMSPageData,
};
