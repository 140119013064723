import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Box, Button, Tab } from "@mui/material";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import "../../../CommonSCSS/ModalPopup.scss";
import {
  internationalMobileNumber,
  isEmpty,
  validateEmail,
} from "../../../SupportingFiles/HelpingFunctions";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import CustomButton from "../CustomButton/CustomButton";
import Calendly from "../FieldTypes/Calendly/Calendly";
import RKTextField from "../FieldTypes/RKTextField/RKTextField";
import TextArea from "../FieldTypes/TextArea/TextArea";
import { FORM_SERVICES } from "../../../Services/FormServices";
import { toast } from "react-toastify";
import Loader from "../Loader/Loader";
import SearchDropdown from "../FieldTypes/SearchDropdown/SearchDropdown";
import { countryCodeOption } from "../../../Services/Type/Home/THomePage";

const RequestQuotation = ({
  open,
  setOpen,
  popup_heading,
}: {
  open: boolean;
  setOpen: Function;
  popup_heading?: string;
}) => {
  const isMobile = useIsMobile();
  const [tabsVal, setTabsVal] = useState<"inquire" | "meeting">("inquire");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formFields, setFormFields] = useState<{
    name: { value: string; warning: boolean };
    title: { value: string; warning: boolean };
    organization: { value: string; warning: boolean };
    email: { value: string; warning: boolean };
    mobile: { value: string; warning: boolean };
    message: { value: string; warning: boolean };
    country: { value: string; warning: boolean };
  }>({
    name: { value: "", warning: false },
    title: { value: "", warning: false },
    email: { value: "", warning: false },
    organization: { value: "", warning: false },
    mobile: { value: "", warning: false },
    message: { value: "", warning: false },
    country: { value: "", warning: false },
  });
  const fieldsUpdateState = (attrName: any, value: any) => {
    setFormFields({
      ...formFields,
      [attrName[0]]: {
        value: value,
        warning: isEmpty(value),
      },
    });
  };

  const validateForm = () => {
    let data = { ...formFields };
    data = {
      ...data,
      name: { ...data.name, warning: isEmpty(data.name.value) },
      title: { ...data.title, warning: isEmpty(data.title.value) },
      organization: { ...data.organization, warning: isEmpty(data.organization.value) },
      email: { ...data.email, warning: !validateEmail(data.email.value) },
      country: { ...data.country, warning: isEmpty(data.country.value) },
      mobile: { ...data.mobile, warning: !internationalMobileNumber(data.mobile.value, data.country.value) },
      message: { ...data.message, warning: isEmpty(data.message.value) },
    }
    setFormFields({ ...data });

    let hasError = false;
    Object.values(data)?.map((item) => {
      if (item.warning) hasError = true;
    });
    if (!hasError) {
      FormSubmit();
    }
  };
  const FormSubmit = () => {
    const onSuccess = (res: any) => {
      const results = res.results;
      if (results.error === false) {
        toast.success(results.response);
        setFormFields({
          name: { value: "", warning: false },
          title: { value: "", warning: false },
          email: { value: "", warning: false },
          organization: { value: "", warning: false },
          mobile: { value: "", warning: false },
          message: { value: "", warning: false },
          country: { value: "", warning: false },
        });
      } else {
        // console.log(results)
        toast.error(results.message);
      }
    };
    const onError = (err: any) => {
      console.log("err", err);
    };
    let dto = {
      name: formFields.name.value,
      mobile: formFields.mobile.value,
      title: formFields.title.value,
      company_name: formFields.organization.value,
      company_email: formFields.email.value,
      description: formFields.message.value,
      country_code: formFields.country.value,
    };
    FORM_SERVICES.QuotationFormService(onSuccess, onError, dto);
  };
  const closeModal = () => {
    setOpen(false);
    setTabsVal("inquire");
    setFormFields({
      name: { value: "", warning: false },
      title: { value: "", warning: false },
      email: { value: "", warning: false },
      organization: { value: "", warning: false },
      mobile: { value: "", warning: false },
      message: { value: "", warning: false },
      country: { value: "", warning: false },
    });
  };
  useEffect(() => {
    if (tabsVal === "meeting") {
      setTimeout(() => {
        setIsLoading(false);
      }, 3000);
    }
  }, [tabsVal]);

  return (
    <Modal open={open}>
      <Box className="modalWrapper">
        <Box className="modalContent">
          <Grid container>
            <Button
              className="closePopup"
              onClick={() => {
                closeModal();
              }}
            />
            {isMobile ? (
              <>
                <Grid xs={12}>
                  <TabContext value={tabsVal}>
                    <Box className="tabBox">
                      <TabList
                        className=""
                        onChange={(e, a: "inquire" | "meeting") => {
                          setTabsVal(a);
                          if (a === "meeting") {
                            setIsLoading(true);
                          }
                        }}
                      >
                        <Tab
                          className={tabsVal === "inquire" ? "active" : ""}
                          label="Inquire"
                          value="inquire"
                        />
                        <Tab
                          className={tabsVal === "meeting" ? "active" : ""}
                          label="Schedule a meeting"
                          value="meeting"
                        />
                      </TabList>
                    </Box>
                    {/* INQUIRE TAB  */}
                    <TabPanel className="tabPanel" value="inquire">
                      <Grid
                        xs={12}
                        display={"flex"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        marginBottom={"20px"}
                      >
                        <Box>
                          <h3>
                            {popup_heading
                              ? popup_heading
                              : "Request a Quotation"}
                          </h3>
                          <p>We are here for you. how can we help?</p>
                        </Box>

                        <Box>
                          <img src="./images/requestQuotation.svg" alt="" />
                        </Box>
                      </Grid>

                      <Grid xs={12} paddingTop={"12px"}>
                        <RKTextField
                          class_name="inputField mb-4"
                          title={"Name"}
                          value={formFields.name.value}
                          attrName={["name"]}
                          value_update={fieldsUpdateState}
                          warn_status={formFields.name.warning}
                          error_messg="Please enter name"
                        />
                        <RKTextField
                          class_name="inputField mb-4"
                          title={"Title"}
                          value={formFields.title.value}
                          attrName={["title"]}
                          value_update={fieldsUpdateState}
                          warn_status={formFields.title.warning}
                          error_messg="Please enter title"
                        />
                        <RKTextField
                          class_name="inputField mb-4"
                          title={"Company or Organization Name"}
                          value={formFields.organization.value}
                          attrName={["organization"]}
                          value_update={fieldsUpdateState}
                          warn_status={formFields.organization.warning}
                          error_messg="Please enter organization"
                        />
                        <RKTextField
                          class_name="inputField mb-4"
                          title={"Company Email"}
                          value={formFields.email.value}
                          attrName={["email"]}
                          value_update={fieldsUpdateState}
                          warn_status={formFields.email.warning}
                          error_messg={
                            formFields.email.value?.length
                              ? " Please enter valid email"
                              : " Please enter email"
                          }
                        />
                        <SearchDropdown
                          class_name="inputField mb-4"
                          title={"Country"}
                          value={formFields.country.value}
                          attrName={["country"]}
                          value_update={fieldsUpdateState}
                          warn_status={formFields.country.warning}
                          error_message={" Please select country code"}
                          data={countryCodeOption}
                        />
                        <RKTextField
                          class_name="inputField mb-4"
                          title={"Mobile Number"}
                          value={formFields.mobile.value}
                          attrName={["mobile"]}
                          value_update={fieldsUpdateState}
                          warn_status={formFields.mobile.warning}
                          error_messg={
                            formFields.mobile.value?.length
                              ? " Please enter valid mobile number"
                              : " Please enter mobile number"
                          }
                          max_length={15}
                          validation_type="NUMBER"
                        />
                        <TextArea
                          class_name="inputField mb-4"
                          title={"How can we help?"}
                          value={formFields.message.value}
                          placeholder={"Enter description here"}
                          attrName={[
                            "message",
                            "value",
                            formFields,
                            setFormFields,
                          ]}
                          value_update={fieldsUpdateState}
                          warn_status={formFields.message.warning}
                          error_messg="Please enter message"
                        />
                        <Box className="ctaBtn request" textAlign={"center"}>
                          <CustomButton
                            text_name={"Submit"}
                            class_name="blackLgBtn"
                            onClickFunction={validateForm}
                          />
                        </Box>
                      </Grid>
                    </TabPanel>
                    {/* MEETING TAB  */}
                    <TabPanel className="tabPanel" value="meeting">
                      <Grid container rowGap={3}>
                        <Grid xs={12} textAlign={"center"}>
                          <h3>Schedule a meeting</h3>
                          <p>Let's schedule a meeting !</p>
                        </Grid>
                        <Grid xs={12} margin={"0 auto"}>
                          <img src="./images/requestQuotation.svg" alt="" />
                        </Grid>
                        <Grid xs={12}>
                          <Calendly />
                        </Grid>
                      </Grid>
                    </TabPanel>
                  </TabContext>
                </Grid>
              </>
            ) : (
              <>
                <Grid xs={12}>
                  <TabContext value={tabsVal}>
                    <Box className="tabBox">
                      <TabList
                        className=""
                        onChange={(e, a: "inquire" | "meeting") => {
                          setTabsVal(a);
                          if (a === "meeting") {
                            setIsLoading(true);
                          }
                        }}
                      >
                        <Tab
                          className={tabsVal === "inquire" ? "active" : ""}
                          label="Inquire"
                          value="inquire"
                        />
                        <Tab
                          className={tabsVal === "meeting" ? "active" : ""}
                          label="Schedule a meeting"
                          value="meeting"
                        />
                      </TabList>
                    </Box>
                    {/* INQUIRE TAB  */}
                    <TabPanel className="tabPanel" value="inquire">
                      <Grid container>
                        <Grid
                          xs={6}
                          display={"flex"}
                          flexDirection={"column"}
                          justifyContent={"space-between"}
                        >
                          <Box>
                            <h3>
                              {popup_heading
                                ? popup_heading
                                : "Request a Quotation"}
                            </h3>
                            <p>We are here for you. how can we help?</p>
                          </Box>
                          <Box>
                            <img src="./images/requestQuotation.svg" alt="" />
                          </Box>
                        </Grid>
                        <Grid xs={6}>
                          <Box paddingTop={"12px"} className="getInTouch">
                            <RKTextField
                              class_name="inputField mb-4"
                              title={"Name"}
                              value={formFields.name.value}
                              attrName={["name"]}
                              value_update={fieldsUpdateState}
                              warn_status={formFields.name.warning}
                              error_messg="Please enter name"
                            />
                            <RKTextField
                              class_name="inputField mb-4"
                              title={"Title"}
                              value={formFields.title.value}
                              attrName={["title"]}
                              value_update={fieldsUpdateState}
                              warn_status={formFields.title.warning}
                              error_messg="Please enter title"
                            />
                            <RKTextField
                              class_name="inputField mb-4"
                              title={"Company or Organization Name"}
                              value={formFields.organization.value}
                              attrName={["organization"]}
                              value_update={fieldsUpdateState}
                              warn_status={formFields.organization.warning}
                              error_messg="Please enter organization"
                            />
                            <RKTextField
                              class_name="inputField mb-4"
                              title={"Company Email"}
                              value={formFields.email.value}
                              attrName={["email"]}
                              value_update={fieldsUpdateState}
                              warn_status={formFields.email.warning}
                              error_messg={
                                formFields.email.value?.length
                                  ? " Please enter valid email"
                                  : " Please enter email"
                              }
                            />
                            <SearchDropdown
                              class_name="inputField mb-4"
                              title={"Country"}
                              value={formFields.country.value}
                              attrName={["country"]}
                              value_update={fieldsUpdateState}
                              warn_status={formFields.country.warning}
                              error_message={" Please select country code"}
                              data={countryCodeOption}
                            />
                            <RKTextField
                              class_name="inputField mb-4"
                              title={"Mobile Number"}
                              value={formFields.mobile.value}
                              attrName={["mobile"]}
                              value_update={fieldsUpdateState}
                              warn_status={formFields.mobile.warning}
                              error_messg={
                                formFields.mobile.value?.length
                                  ? " Please enter valid mobile number"
                                  : " Please enter mobile number"
                              }
                              max_length={15}
                              validation_type="NUMBER"
                            />
                            <TextArea
                              class_name="inputField mb-4"
                              title={"How can we help?"}
                              value={formFields.message.value}
                              placeholder={"Enter description here"}
                              attrName={["message"]}
                              value_update={fieldsUpdateState}
                              warn_status={formFields.message.warning}
                              error_messg="Please enter message"
                            />
                            <Box className="ctaBtn request">
                              <CustomButton
                                text_name={"Submit"}
                                class_name="blackLgBtn"
                                onClickFunction={validateForm}
                              />
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </TabPanel>
                    {/* MEETING TAB  */}
                    <TabPanel className="tabPanel" value="meeting">
                      <Grid container>
                        <Grid
                          xs={6}
                          display={"flex"}
                          flexDirection={"column"}
                          justifyContent={"space-between"}
                        >
                          <Box>
                            <h3>Schedule a meeting</h3>
                            <p>Let's schedule a meeting !</p>
                          </Box>
                          <Box>
                            <img src="./images/requestQuotation.svg" alt="" />
                          </Box>
                        </Grid>
                        <Grid xs={6}>
                          {isLoading ? <Loader /> : null}
                          <Calendly />
                        </Grid>
                      </Grid>
                    </TabPanel>
                  </TabContext>
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};

export default RequestQuotation;
