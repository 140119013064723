import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import "./Loader.scss"

const Loader = () => {
  return (

    // <Box className="quoteoverlay" >
    //   <Box className="Amaze_Loader">
    //     <CircularProgress />
    //   </Box>
    // </Box>
    <Box className="Amaze_Loader">
        <CircularProgress />
      </Box>
  )
}

export default Loader