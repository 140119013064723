import Grid from "@mui/material/Unstable_Grid2";
import { useState } from "react";
import "./ScheduleDemo.scss";
import SelectBox from "./SelectBox";
import { Box, Link, Modal } from "@mui/material";
import RKTextField from "../FieldTypes/RKTextField/RKTextField";
import SelectDropdown from "../FieldTypes/SelectDropdown/SelectDropdown";
import CustomButton from "../CustomButton/CustomButton";
import TextArea from "../FieldTypes/TextArea/TextArea";
import { internationalMobileNumber, isEmpty, validateEmail } from "../../../SupportingFiles/HelpingFunctions";
import { toast } from "react-toastify";
import { FORM_SERVICES } from "../../../Services/FormServices";
import Calendly from "../FieldTypes/Calendly/Calendly";
import SearchDropdown from "../FieldTypes/SearchDropdown/SearchDropdown";
import { countryCodeOption } from "../../../Services/Type/Home/THomePage";

type TPlatform = "MOBILE_APP" | "WEB_DEV" | "";
type TProjectType = "NEW_PROJECT" | "OLD_PROJECT" | "";
type TAppsPlatform = "IOS" | "Android" | "Both" | "Not Sure" | "";

function ScheduleDemo({ open, setOpen }: { open: boolean; setOpen: any }) {
  const [formField, setFormField] = useState<{
    platform: TPlatform;
    projectType: TProjectType;
    deviceType: TAppsPlatform;
    designRequired: boolean | null;
  }>({
    platform: "",
    projectType: "",
    deviceType: "",
    designRequired: null,
  });
  const [stepCount, setStepCount] = useState<number>(1);
  const [stepIndex, setStepIndex] = useState<any>(null);
  const [previousData, setpreviousData] = useState<any>({});
  const [meeting, setMeeting] = useState<boolean>(false);
  const [startMeeting, setStartMeeting] = useState<boolean>(false);
  const [quoteFields, setQuoteFields] = useState<{
    name: { value: string; warning: boolean };
    lastname: { value: string; warning: boolean };
    mobile: { value: string; warning: boolean };
    email: { value: string; warning: boolean };
    companyName: { value: string; warning: boolean };
    websiteUrl: { value: string; warning: boolean };
    description: { value: string; warning: boolean };
    selectProduct: { value: ""; warning: boolean };
    dateAndTime: { value: ""; warning: boolean };
    country: { value: string; warning: boolean };
  }>({
    name: { value: "", warning: false },
    lastname: { value: "", warning: false },
    mobile: { value: "", warning: false },
    email: { value: "", warning: false },
    companyName: { value: "", warning: false },
    websiteUrl: { value: "", warning: false },
    description: { value: "", warning: false },
    selectProduct: { value: "", warning: false },
    dateAndTime: { value: "", warning: false },
    country: { value: "", warning: false },
  });

  const selectProduct_data = [
    { label: "Healthcare", value: "Healthcare" },
    { label: "Wellness & Fitness", value: "Wellness & Fitness" },
    { label: "Retail & E-Commerce", value: "Retail & E-Commerce" },
    { label: "Travel & Hospitality", value: "Travel & Hospitality" },
    { label: "Transportation & Logistics", value: "Transportation & Logistics", },
    { label: "Automotive", value: "Automotive" },
    { label: "Media and Entertainment", value: "Media and Entertainment" },
    { label: "Insurance", value: "Insurance" },
    { label: "Education & E-Learning", value: "Education & E-Learning" },
    { label: "Product Development", value: "Product Development" },
    { label: "Design Studio", value: "Design Studio" },
    { label: "Growth & Marketing", value: "Growth & Marketing" },
    { label: "IT Consulting & Staff Augmentation", value: "IT Consulting & Staff Augmentation" },

  ];
  const fieldsUpdateState = (attrName: any, value: any) => {
    setQuoteFields({
      ...quoteFields, [attrName[0]]: {
        value: value, warning: isEmpty(value)
      }
    })
  };

  const validateForm = () => {
    const data: any = { ...quoteFields };
    const fieldValidations = [
      {
        field: "name",
        condition: !data.name.value,
      },
      {
        field: "mobile",
        condition:  !internationalMobileNumber(data.mobile.value, data.country.value)
      },
      {
        field: "email",
        condition: !validateEmail(data.email.value),
      },
      // {
      //   field: "description",
      //   condition: !data.description.value,
      // },
      {
        field: "selectProduct",
        condition: !data.selectProduct.value,
      },
      {
        field: "companyName",
        condition: !data.companyName.value,
      },
      {
        field: "websiteUrl",
        condition: !data.websiteUrl.value,
      },
      {
        field: "country",
        condition: !data.country.value,
      },
    ];

    fieldValidations.forEach((validation) => {
      data[validation.field].warning = validation.condition;
    });
    setQuoteFields(data);
    let hasError =
      // quoteFields.description.warning ||
      quoteFields.name.warning ||
      quoteFields.mobile.warning ||
      quoteFields.country.warning ||
      quoteFields.email.warning ||
      quoteFields.companyName.warning ||
      quoteFields.websiteUrl.warning ||
      quoteFields.selectProduct.warning;

    if (!hasError) {
      FormSubmit();
      // if (stepCount === 4) {
      //   setStepCount((prev) => prev + 1);
      //   // setStepIndex(null);  
      // }
      // setMeeting(true);
    }
  };

  const dataStep: any = {
    1: [
      {
        image: "./images/mobile-icon.svg",
        tittle: "Mobile App Development",
        description:
          "Mobile app development is the act or process by which a mobile app is developed for one or more mobile devices, which can include personal digital assistants, enterprise digital assistants, or mobile phones.",
      },
      {
        image: "./images/web-icon.svg",
        tittle: "Web Development",
        description:
          "Web application development describes the process of designing, building, testing and deploying web-based applications that will be installed on remote servers and delivered to users or customers via the internet.",
      },
      {
        image: "./images/uiux-icon.svg",
        tittle: "UI/UX Design",
        description:
          "UX design is all about identifying and solving user problems; UI design is all about creating intuitive, aesthetically-pleasing, interactive interfaces. UX design usually comes first in the product development process.",
      },
    ],
    2: [
      {
        image: "./images/new-project-icon.svg",
        tittle: "New Project",
        description:
          "New Project is a Project that has not achieved Commercial Operation on or prior to the Execution Date.",
      },
      {
        image: "./images/old-project-icon.svg",
        tittle: "Old Project",
        description:
          "An Old Project, in the context of our discussion, refers to a project that has previously achieved Commercial Operation but may require rework, updates, or improvements. ",
      },
    ],
    3: [
      {
        image: "./images/static-icon.svg",
        tittle: "Static ",
        description:
          "Static web pages are made of “fixed code,” and unless the site developer makes changes, nothing will change on the page.",
      },
      {
        image: "./images/dynamic-icon.svg",
        tittle: "Dynamic",
        description:
          "A dynamic website generates pages in real-time, adapting to factors like screen size and device type. This flexibility lets you tailor the user experience based on the browser or requests.",
      },
    ],
  };

  const FormSubmit = () => {
    const onSuccess = (res: any) => {
      const results = res.results;
      const error = results.error;
      if (error === false) {
        setQuoteFields({
          name: { value: "", warning: false },
          lastname: { value: "", warning: false },
          mobile: { value: "", warning: false },
          email: { value: "", warning: false },
          companyName: { value: "", warning: false },
          websiteUrl: { value: "", warning: false },
          description: { value: "", warning: false },
          selectProduct: { value: "", warning: false },
          dateAndTime: { value: "", warning: false },
          country: { value: "", warning: false },
        })
        toast.success("Request Submitted Successfully");
        setStepCount((prev) => prev + 1);
      }
    };

    const onError = (err: any) => {
      console.log("updatefalse", err);
    };
    // SELECTED_QUOTE_DATA.product_code;
    let dto = {
      name: quoteFields.name.value,

      mobile: quoteFields.mobile.value,

      email: quoteFields.email.value,

      description:
        previousData[1] +
        " " +
        previousData[2] +
        " " +
        previousData[3] +
        " " +
        quoteFields.companyName.value +
        " " +
        quoteFields.websiteUrl.value +
        " " +
        quoteFields.description.value,

      productname: quoteFields.selectProduct.value,
      company_name: quoteFields.companyName.value,
      website_url: quoteFields.websiteUrl.value,
      country_code: quoteFields.country.value,

    };
    console.log(dto, "dto")

    FORM_SERVICES.FormServices(onSuccess, onError, dto);
  };


  return (
    <Modal
      className="scheduleDemo"
      open={open}
      // onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className="content">
        <Box
          position="relative"
          borderBottom="1px solid #eee"
          paddingBottom="24px"
          marginBottom="40px"
        >
          <h4 className="pl-5">Estimate Your Project</h4>
          <Link
            className="closePopup"
            onClick={() => {
              setpreviousData({});
              setOpen(false);
              setStepCount(1);
            }}
          />
        </Box>
        {stepCount === 5 ? (
          <Grid container spacing={3} className="step5">
            <Grid xs={12}>
              <div className="ctaBtn mt-2">
                <CustomButton
                  text_name="Back"
                  class_name="whiteSmBtn"
                  onClickFunction={() => {
                    if (stepCount > 1) {
                      setStepCount((prev) => prev - 1);
                      setStartMeeting(false);
                      setMeeting(false);
                    } else {
                      setOpen(false);
                    }
                  }}
                />
              </div>
            </Grid>
            {startMeeting ?
              <Box className="calendly_box">
                <Calendly />
              </Box>
              : <Grid xs={12} textAlign={'center'}>
                <img className="img mb-11" src="/images/wc.svg" alt="wc" />
                <Box textAlign="center" className="ctaBtn mt-11">
                  <CustomButton
                    text_name="Schedule a meeting as well !"
                    class_name="blackLgBtn"
                    onClickFunction={
                      () => { setStartMeeting(true) }
                    }
                    disabled={false}
                  />
                </Box>
              </Grid>}
          </Grid>
        ) :
          <Grid container spacing={3}>
            <Grid xs={12} md={4} lg={3}>
              <div className="ctaBtn mt-2">
                <CustomButton
                  text_name="Back"
                  class_name="whiteSmBtn"
                  onClickFunction={() => {
                    if (stepCount > 1) {
                      setStepCount((prev) => prev - 1);
                      setStartMeeting(false);
                      setMeeting(false);
                    } else {
                      setOpen(false);
                    }
                  }}
                />
              </div>
              <img src="./images/book-demo.svg" className="leftSideImage" />
            </Grid>

            <Grid xs={12} md={8} lg={9}>
              {/* Step 1 2 3 */}
              {stepCount === 1 || 2 || 3 ? (
                <Box>
                  {stepCount === 1 && (
                    <>
                      <h2>
                        What type of project are you <span>developing?</span>
                      </h2>
                      <p>
                        What specific project or initiative are you currently in
                        the process of developing or working on?
                      </p>
                    </>
                  )}
                  {stepCount === 2 && (
                    <>
                      <h2>
                        How would you like to <span>start?</span>
                      </h2>
                      <p>
                        Please specify if your website or web application needs to
                        be developed from scratch, or if modifications are
                        required to an existing system.
                      </p>
                    </>
                  )}
                  {stepCount === 3 && (
                    <>
                      <h2>
                        What type of web solution do you <span>need?</span>
                      </h2>
                      <p>
                        Please specify if your website or web application needs to
                        be developed from scratch, or if modifications are
                        required to an existing system.
                      </p>
                    </>
                  )}
                  {stepCount === 4 && (
                    <>
                      <h2 className="px-3">
                        Fill in the below form for get <span>Estimation</span>
                      </h2>
                      <p className="px-3">
                        Please take a moment to share your contact details with us
                        so that we can better serve your needs
                      </p>
                    </>
                  )}
                  {
                    (stepCount === 1 || stepCount === 2 || stepCount === 3) ?
                      <ul className="ul">
                        {dataStep[stepCount].map((data: any, index: number) => (
                          <li
                            onClick={() => {
                              setStepIndex(index);
                              setpreviousData({
                                ...previousData,
                                [stepCount]: data.tittle,
                              });
                            }}
                          >
                            <SelectBox
                              class_name={
                                previousData[stepCount] == data.tittle
                                  ? "active"
                                  : ""
                              }
                              title={data.tittle}
                              description={data.description}
                              imageUrl={data.image}
                            />
                          </li>
                        ))}
                      </ul>
                      : null
                  }
                </Box>
              ) : null}

              {stepCount === 4 && (
                <Box margin={'40px 0'}>
                  <Grid container spacing={3} className="pb-4">
                    <Grid xs={12} md={6}>
                      <RKTextField
                        class_name="inputField"
                        title={"First Name"}
                        value={quoteFields.name.value}
                        attrName={["name"]}
                        value_update={fieldsUpdateState}
                        warn_status={quoteFields.name.warning}
                        error_messg={" Enter Name"}
                      />
                    </Grid>
                    <Grid xs={12} md={6}>
                      <RKTextField
                        class_name="inputField"
                        title={"Last Name"}
                        value={quoteFields.lastname.value}
                        attrName={["lastname"]}
                        value_update={fieldsUpdateState}
                        warn_status={quoteFields.lastname.warning}
                        error_messg={" Enter last name"}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} className="pb-4">
                    <Grid xs={12} md={2}>
                      <SearchDropdown
                        class_name="inputField"
                        title={"Country"}
                        value={quoteFields.country.value}
                        attrName={["country"]}
                        value_update={fieldsUpdateState}
                        warn_status={quoteFields.country.warning}
                        error_message={" select country"}
                        data={countryCodeOption}
                      />
                    </Grid>
                    <Grid xs={12} md={4}>
                      <RKTextField
                        class_name="inputField"
                        title={"Mobile"}
                        value={quoteFields.mobile.value}
                        attrName={["mobile"]}
                        value_update={fieldsUpdateState}
                        warn_status={quoteFields.mobile.warning}
                        error_messg={" Enter Mobile"}
                        max_length={15}
                        validation_type="NUMBER"
                      />
                    </Grid>
                    <Grid xs={12} md={6}>
                      <RKTextField
                        class_name="inputField"
                        title={"Email"}
                        value={quoteFields.email.value}
                        attrName={["email"]}
                        value_update={fieldsUpdateState}
                        warn_status={quoteFields.email.warning}
                        error_messg={" Enter Email"}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} className="pb-4">
                    <Grid xs={12} md={4}>
                      <RKTextField
                        class_name="inputField"
                        title={"Company Name"}
                        value={quoteFields.companyName.value}
                        attrName={["companyName"]}
                        value_update={fieldsUpdateState}
                        warn_status={quoteFields.companyName.warning}
                        error_messg={" Enter Company Name"}
                      />
                    </Grid>
                    <Grid xs={12} md={4}>
                      <RKTextField
                        class_name="inputField"
                        title={"Website URL"}
                        value={quoteFields.websiteUrl.value}
                        attrName={["websiteUrl"]}
                        value_update={fieldsUpdateState}
                        warn_status={quoteFields.websiteUrl.warning}
                        error_messg={" Enter Email"}
                      />
                    </Grid>
                    <Grid xs={12} md={4}>
                      <SelectDropdown
                        class_name="inputField"
                        title="Select Industry/Services"
                        value={quoteFields.selectProduct.value}
                        attrName={["selectProduct"]}
                        value_update={fieldsUpdateState}
                        dropdown_data={selectProduct_data}
                        warn_status={quoteFields.selectProduct.warning}
                        error_messg={" Select industry/services"}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid xs={12} className="ctaBtn">
                      <TextArea
                        class_name="inputField"
                        title={"Write About Your Project/Product Description"}
                        value={quoteFields.description.value}
                        attrName={["description"]}
                        value_update={fieldsUpdateState}
                        warn_status={quoteFields.description.warning}
                        error_messg={" Enter description"}
                      />
                    </Grid>
                  </Grid>
                </Box>
              )}
              {
                (stepCount === 1 || stepCount === 2 || stepCount === 3 || stepCount === 4) ?
                  <div className={`ctaBtn mt-8 ${stepCount === 4 ? "px-3" : ""}`} >
                    <CustomButton
                      text_name="Continue"
                      class_name="blackLgBtn"
                      onClickFunction={
                        stepCount < 4 ? () => {
                          setStepCount((prev) => prev + 1);
                          setStepIndex(null);
                        } :
                          () => {
                            validateForm();
                          }
                      }
                      disabled={
                        stepCount < 4
                          ? previousData[stepCount]
                            ? false
                            : true
                          : false
                      }
                    />
                  </div> : null
              }
            </Grid>
          </Grid>
        }
      </Box>
    </Modal>
  );
}

export default ScheduleDemo;
