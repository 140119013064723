import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import "../../../CommonSCSS/common.scss";
import Footer from "../../../Components/Desktop/Footer/Footer";
import Navbar from "../../../Components/Desktop/Navbar/Navbar";
import MNavbar from "../../../Components/Mobile/Navbar/MNavbar";
import useIsMobile from "../../../SupportingFiles/MobileProvider";

function Aboutus() {
  const isMobile = useIsMobile();
  const [open, setOpen] = useState<boolean>(false);
  const principleList = [
    {
      img: "./images/fundamental_img1.svg",
      title: "Accomplish Tasks",
      para: "We hold the belief that any concept can materialise through diligent work and effort. Therefore, if a task is achievable, we have the capability to accomplish it.",
    },
    {
      img: "./images/fundamental_img2.svg",
      title: "Accept Ownership",
      para: "We make no excuses. Your success directly reflects our reputation. We have the courage to take full responsibility for all our endeavors and take pride in associating our name with them.",
    },
  ];
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, 500);
  }, [])

  return (
    <>
      {isMobile ? <MNavbar /> : <Navbar open={open} setOpen={setOpen} />}
      <Box className="cms_wrapper">
        <Box className="about_header" marginBottom={"80px"}>
          <Grid container spacing={3}>
            <Grid xs={12} sm={12} md={8} lg={8} margin={"auto"} textAlign={"center"}>
              <h5>
                About the <span>Evervent</span>
              </h5>
              <p>
                In 2020, Evervent started on a mission to build a global fintech
                company. Its company of trust a for large financial
                corporations, who are leveraging our platforms for their
                mission-critical.
              </p>
              <img src="./images/aboutus_img.svg" alt="" />
            </Grid>
          </Grid>
        </Box>

        {/* our mission */}
        <Box className="our_mission" marginBottom={"80px"}>
          <Grid container spacing={3}>
            <Grid xs={12} sm={12} md={8} lg={8} alignSelf={"center"}>
              <h5>Our Mission</h5>
              <h6>
                Passion for technology is embedded in our ethos. Satifying our
                customers our biggest responsibility
              </h6>
              <p>
                Evervent started with a mission to build a global Technology and
                Marketing Company. Building Digital products and solutions which
                solve business problem using cutting edge technology and
                Automation across industries and borders. Helping our customers
                to increase their ROI and efficiencies.
              </p>
            </Grid>
            <Grid
              xs={12}
              sm={12}
              md={4}
              lg={4}
              className="lg:text-left text-center"
            >
              <img src="./images/mission_img.svg" alt="" />
            </Grid>
          </Grid>
        </Box>

        {/* how it works */}

        <Box className="itWorks_section" marginBottom={"80px"}>
          <Grid container spacing={3}>
            <Grid xs={12} columnSpacing={0}>
              <Box className="works_box">
                <Grid container spacing={3}>
                  <Grid
                    xs={12}
                    sm={12}
                    md={4}
                    lg={4}
                    className="fundamental_box"
                  >
                    <h5>How it works?</h5>
                    <h6>Our Fundamental Principles</h6>
                    <p>
                      We place our culture's focus on empowering our clients,
                      and by incorporating these four guiding principles into
                      our work, we actively contribute to our clients' success
                      while maintaining our own identity."
                    </p>
                  </Grid>
                  <Grid xs={12} sm={12} md={8} lg={8}>
                    <ul className="principle_list">
                      {principleList.map((data, index) => (
                        <li>
                          <Box className="contentBox">
                            <img src={data.img} />
                            <h6>{data.title}</h6>
                          </Box>
                          <Box>
                            <p>{data.para}</p>
                          </Box>
                        </li>
                      ))}
                    </ul>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>

        {/* expert team */}

        <Box className="expert_team" marginBottom={"80px"}>
          <Grid container spacing={3}>
            <Grid
              xs={12}
              sm={12}
              md={4}
              lg={4}
              className="lg:text-left text-center"
            >
              <img src="./images/expert_img.svg" alt="" />
            </Grid>
            <Grid xs={12} sm={12} md={8} lg={8} alignSelf={"center"}>
              <h5>We are an Expert Team</h5>
              <h6>
                We create product solutions for your business problems that help
                you to Stay Ahead
              </h6>
              <p>
                Evervent exists to help you enable digital transformation and
                grow your business. Evervent's have always believed that its
                team is its strength. Evervent has team of mainly software
                engineers, designers and testing professionals. Employees loves
                working at Evervent because provides open environment, helps to
                learn and innovate.
              </p>
              <p>
                We are experts , Digital Marketers, Technology Thinkers,
                Innovators, Experimenters and most Importantly Products
                Enthusiasts. We love building disruptive products and solutions
                for our clients.
              </p>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Footer />
    </>
  );
}

export default Aboutus;
