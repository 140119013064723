import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Box } from "@mui/material";
import Carousel from "react-multi-carousel";

function Testimonials() {
  const responsive = {
    mobile: {
      breakpoint: { max: 4000, min: 0 },
      items: 1,
    },
  };
  return (
    <Box className="greyBox my-20 py-20 py-40-m my-40-m">
      <Grid container spacing={3}>
        <Grid xs={12} textAlign={"center"}>
          <h2>What People Say</h2>
        </Grid>
        <Grid xs={12} textAlign={"center"} className="testimonials">
          <Carousel responsive={responsive}>
            <div>
              <div className="user">
                <img src="./images/uday.png" alt="user" />
              </div>
              <h5>Arun Mehta</h5>
              <p className="designnation">VP, IT Head</p>
              <h6>
                The team at Evervent has been exceptional in providing top-notch
                service and support for our healthcare software needs. Their
                dedication to custom development has significantly improved our
                operations. Highly recommended!
              </h6>
            </div>
            <div>
              <div className="user">
                <img src="./images/karan.png" alt="user" />
              </div>
              <h5>Mandeep Singh Barar</h5>
              <p className="designnation">
                Business Head, Reputed Organisation
              </p>
              <h6>
                We've been consistently impressed with Evervent's expertise in
                healthcare app development. Their responsive support and
                innovative solutions have been instrumental in our success.
                Thank you!
              </h6>
            </div>
            <div>
              <div className="user">
                <img src="./images/umesh.png" alt="user" />
              </div>
              <h5>Akhil Raizada</h5>
              <p className="designnation">CEO, Reputed Organisation</p>
              <h6>
                Working with Evervent has been a game-changer for our
                sports & wellness practice. Their custom software solutions have
                streamlined our processes, and their ongoing support is
                unmatched. A fantastic partner!
              </h6>
            </div>
          </Carousel>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Testimonials;
