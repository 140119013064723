import React, { FC } from "react";
import { Box } from "@mui/material";
import CustomButton from "../../../Components/Common/CustomButton/CustomButton";
import "./ColorBoxes.scss";

interface ColorBoxesProps {
  class_name: string;
  title: string;
  paragraph: string;
  imageSrc: string;
}

const ColorBoxes: FC<ColorBoxesProps> = ({
  class_name,
  title,
  paragraph,
  imageSrc,
}) => {
  return (
    <Box className={class_name}>
      <h4>{title}</h4>
      <p className="mt-3">{paragraph}</p>
      <img src={imageSrc} alt="Image" className="mt-3" />
    </Box>
  );
};

export default ColorBoxes;
