import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import "../../CommonSCSS/common.scss";
import Footer from "../../Components/Desktop/Footer/Footer";
import Navbar from "../../Components/Desktop/Navbar/Navbar";
import MNavbar from "../../Components/Mobile/Navbar/MNavbar";
import useIsMobile from "../../SupportingFiles/MobileProvider";
import CustomButton from "../../Components/Common/CustomButton/CustomButton";
import CaseStudyBox from "../HealthCare/CaseStudyBox/CaseStudyBox";
import RequestQuotationComponent from "../../Components/Common/RequestComponent/RequestQuotationComponent";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import RequestQuotation from "../../Components/Common/RequestQuotationPopup/RequestQuotation";
import CaseStudyModal, { caseType } from "../../Components/Common/CaseStudy/CaseStudyModal";

const GrowthMarketing = () => {
    const isMobile = useIsMobile();
    const [open, setOpen] = useState<boolean>(false);
    const [openRequestQuotation, setOpenRequestQuotation] = useState<boolean>(false);
    const responsive = {
        tablet: {
            breakpoint: { max: 4000, min: 701 },
            items: 2,
            paritialVisibilityGutter: 40,
        },
        mobile: {
            breakpoint: { max: 700, min: 0 },
            items: 1,
            paritialVisibilityGutter: 40,
        },
    };
    useEffect(() => {
        if (openRequestQuotation) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "";
        }
    }, [openRequestQuotation]);
    type offerType = { id: number, img: string, heading: string, readMore: boolean, content: string, fullContent: string }
    const [offers, setOffers] = useState<offerType[]>([
        {
            id: 1,
            img: "/images/service1.svg",
            heading: "Digital Transformation Engine",
            readMore: false,
            content: "Elevate your operations by integrating AI-powered workflows, ensuring your business is at the forefront of digital innovation, providing efficiency",
            fullContent: "Elevate your operations by integrating AI-powered workflows, ensuring your business is at the forefront of digital innovation, providing efficiency and agility."
        },
        {
            id: 2,
            img: "/images/service2.svg",
            heading: "Branding Tech Prowess",
            readMore: false,
            content: "Forge a distinctive and tech-savvy brand identity using cutting-edge tools and creative prowess, setting your business apart and establishing a strong",
            fullContent: "Forge a distinctive and tech-savvy brand identity using cutting-edge tools and creative prowess, setting your business apart and establishing a strong position in the digital landscape."
        },
        {
            id: 3,
            img: "/images/service3.svg",
            heading: "Brand Amplification Matrix",
            readMore: false,
            content: "Propel your brand's reach with sophisticated analytics and data-driven strategies, cultivating a strong digital presence across diverse channels, and",
            fullContent: "Propel your brand's reach with sophisticated analytics and data-driven strategies, cultivating a strong digital presence across diverse channels, and connecting with your target audience effectively."
        },
        {
            id: 4,
            img: "/images/service4.svg",
            heading: "Lead Quantum Generator",
            readMore: false,
            content: "Transform your lead generation strategy with the predictive power of analytics and machine learning, allowing you to identify, target, and convert",
            fullContent: "Transform your lead generation strategy with the predictive power of analytics and machine learning, allowing you to identify, target, and convert high-quality leads, maximizing your conversion potential."
        },
        {
            id: 5,
            img: "/images/service5.svg",
            heading: "Social Media Tech Symphony",
            readMore: false,
            content: "Harmonize your social media presence with the latest tech trends, leveraging data-driven insights to craft engaging strategies that resonate with",
            fullContent: "Harmonize your social media presence with the latest tech trends, leveraging data-driven insights to craft engaging strategies that resonate with your audience and elevate your brand in the digital sphere."
        },
        {
            id: 6,
            img: "/images/service6.svg",
            heading: "SEM Campaign Management",
            readMore: false,
            content: "Steer through the digital landscape with precision through our SEM expertise, ensuring your brand not only appears but excels in search engine",
            fullContent: "Steer through the digital landscape with precision through our SEM expertise, ensuring your brand not only appears but excels in search engine results. Our Campaign Algorithm Mastery, unleashes the full potential of your marketing campaigns ensuring automation, precision targeting, higher ROI, and strategic customer acquisition."
        },
    ])
    const updateOffers = (item: offerType, i: number) => {
        let clicked = { ...item, readMore: true };
        let data = [...offers];
        data[i] = clicked;
        setOffers(data)
    }
    const [openCaseModal, setOpenCaseModal] = useState<boolean>(false);
    const [clickedCase, setClickedCase] = useState<caseType>({
        head: "", subHead: "", mainHeading: "", keywords: "", headPara: "", intro: "",
        challenge: "", solution: "", key: [], conclusion: ""
    });
    const caseStudies: caseType[] = [
        {
            head: "InsureTech Revolution Unleashed", subHead: "InsureTech Revolution Unleashed",
            keywords: "InsurTech Innovation, Digital Transformation, Strategic Growth in Insurance, Cutting-edge InsurTech Marketing",
            mainHeading: "Insurance Broker's Digital Odyssey: A Transformation Unveiled",
            headPara: "Tech-Infused Insurance Mastery",
            intro: "Embarking on a digital frontier, our partnership with an insurance broker on a transformative journey illustrates the profound impact of InsureTech branding, strategic digital initiatives, and technologically driven marketing.",
            challenge: "The traditional insurance landscape posed challenges of adaptation and growth in a digital era. Our task was not just to navigate this transition but to position the broker as a digital pioneer, seamlessly merging tech advancements with insurance expertise.",
            solution: "Our collaboration involved more than rebranding; it was a systematic transformation. From leveraging data analytics for market insights to executing cutting-edge growth strategies, each move was calibrated for a tech-forward ascent in the competitive insurance domain.",
            key: [
                { heading: "InsureTech Branding Matrix", content: "Forged a brand identity that embraced InsureTech innovation, portraying the broker as a digital authority in insurance services." },
                { heading: "Digital Growth Alchemy", content: "Orchestrated growth initiatives that harnessed data analytics, ensuring the broker's expansion was not just strategic but informed by technology." },
                { heading: "Precision InsurTech Marketing Blitz", content: "Executed a digitally charged marketing campaign, utilizing AI-driven targeting and immersive experiences to captivate a tech-savvy insurance audience." },
                { heading: "Innovative UI/UX Paradigm", content: "Revolutionized client interactions with an innovative UI/UX design, ensuring digital platforms were as cutting-edge as the insurance solutions they offered." },
                { heading: "AI-Enhanced Policy Recommendations", content: "Implemented an AI-driven system for personalized policy recommendations, analyzing customer profiles and market trends for tailored insurance solutions." },
                { heading: "InsurTech Community Engagement", content: "Fostered community engagement through social media and digital platforms, creating a space for clients to share experiences, ask questions, and build a sense of trust." },
            ],
            conclusion: "In the dynamic intersection of insurance and technology, our collaboration with this broker showcases the art of transforming a traditional service into a digital powerhouse. Witness the convergence of insurance and tech in a groundbreaking ascent to digital insurance mastery!"
        },
        {
            head: "B2B2C Insurance Solutions", subHead: "Elevating Insurance through Digital Alliances",
            keywords: "B2B2C Insurance Solutions, Lead Generation, Digital Transformation in Insurance, InsurTech Marketing, Strategic Alliances",
            mainHeading: "Revolutionizing Insurance: A Digital B2B2C Journey",
            headPara: "Digital-First B2B2C Insurance Excellence",
            intro: "In the realm of insurance evolution, our partnership with a forward-thinking broker organization unfolds a narrative of digital reinvention, strategic B2B2C collaborations, and a relentless focus on lead generation in the digital landscape.",
            challenge: "The insurance sector demanded more than a mere transition; it sought a complete digital metamorphosis. The challenge was not only to position the broker as a digital authority but also to forge impactful B2B2C alliances while prioritizing lead generation through cutting-edge digital strategies.",
            solution: "Our journey with the broker was not just about embracing digital; it was a strategic shift. From conceptualizing innovative B2B2C solutions to executing lead generation strategies, every step was aimed at propelling the broker into a digital-first era of insurance.",
            key: [
                { heading: "Digital-First B2B2C Solutions", content: "Engineered insurance solutions that seamlessly integrated B2B2C dynamics, positioning the broker as a digital facilitator for businesses and end consumers." },
                { heading: "Strategic Digital Alliances", content: "Forged partnerships with businesses and digital platforms, expanding the broker's reach and enhancing lead generation channels through strategic collaborations." },
                { heading: "Lead Generation Acceleration", content: "Implemented advanced lead generation strategies, utilizing data analytics, AI-driven targeting, and personalized customer journeys to drive engagement and conversion." },
                { heading: "InsurTech Marketing Blitz", content: "Executed a comprehensive InsurTech marketing campaign, leveraging digital channels and analytics to optimize outreach, foster brand awareness, and drive lead acquisition." },
                { heading: "Innovative UI/UX Paradigm", content: "Redefined client interactions with a user-centric design, ensuring digital touchpoints were intuitive and engaging, contributing to enhanced lead generation and conversion." },
                { heading: "AI-Enhanced Customer Engagement", content: "Utilized AI tools to enhance customer engagement, offering personalized insurance recommendations, streamlined onboarding, and responsive support for businesses and end consumers alike." },
            ],
            conclusion: "In the dynamic landscape of insurance and digital alliances, our collaboration with this broker organization symbolizes the successful fusion of B2B2C innovation, strategic lead generation, and a commitment to digital excellence. Witness the transformation of insurance into a digital powerhouse!"
        },
        {
            head: "Distance Learning", subHead: "Revolutionizing Distance Learning: A Tech-Infused Educational Journey",
            keywords: "Edtech, Distance Learning, Learning Management System, Digital Transformation, AI in Education, Online Education, Student Engagement",
            mainHeading: "Distance Learning Education Transformation: A Tech-Infused Learning Odyssey",
            headPara: "Empowering Education through Digital Evolution, Anytime, Anywhere",
            intro: "Embark on a transformative journey in distance learning with [Your Company], where technology converges with education, transcending geographical boundaries. In the realm of Edtech for distance learning, our focus is on reshaping educational landscapes, fostering innovation, and embracing a digital future that transcends physical constraints.",
            challenge: "Distance learning education faces the challenge of delivering immersive educational experiences while ensuring robust student engagement. The demand for personalized education, adaptive learning content, and seamless online platforms necessitates a tech-driven approach. [Your Company] embraced this challenge, setting out to redefine how distance education meets technology.",
            solution: "Our Distance Learning Education solutions revolve around a Digital Transformation Engine, infusing AI-powered workflows into operations. From creating a state-of-the-art Learning Management System (LMS) for distance learning to integrating AI in education for personalized and adaptive learning experiences, [Your Company] stands at the forefront of reshaping the future of distance education.",
            key: [
                { heading: "Digital Transformation Engine", content: "Redefining distance education with AI-powered workflows, ensuring agility and efficiency in content delivery." },
                { heading: "Customizable Learning Paths", content: "Tailor education journeys with personalized learning paths, allowing students to progress at their own pace." },
                { heading: "Interactive Virtual Classrooms", content: "Create immersive learning experiences with interactive virtual classrooms, fostering real-time collaboration." },
                { heading: "AI-Driven Personalized Feedback", content: "Implement AI algorithms for personalized feedback, guiding students on areas of improvement and recognizing achievements." },
                { heading: "Real-time Progress Tracking", content: "Enable students and educators to monitor progress in real-time, providing insights into individual and group performance." },
                { heading: "Collaborative Learning Spaces", content: "Facilitate collaborative learning through dedicated online spaces, encouraging group discussions, projects, and peer interactions." },
                { heading: "Adaptive Assessments", content: "Implement AI-driven adaptive assessments that dynamically adjust difficulty based on individual student performance, ensuring personalized evaluation." },
                { heading: "Multimodal Content Delivery", content: "Support diverse learning styles with multimodal content delivery, including video lectures, interactive simulations, and engaging multimedia materials." },
                { heading: "Secure Examination Environment", content: "Ensure the integrity of online examinations with secure proctoring and anti-cheating measures, creating a trustworthy evaluation process." },
                { heading: "Seamless Integration with Learning Apps", content: "Seamlessly integrate with popular learning applications, providing a comprehensive and connected educational ecosystem." },
                { heading: "Mobile Learning Accessibility", content: "Optimize the learning experience for mobile devices, allowing students to access educational content on the go." },
                { heading: "Robust Analytics and Reporting", content: "Provide comprehensive analytics and reporting tools for educators to assess the effectiveness of teaching methods and course materials." },
                { heading: "SEO-Optimized Content Management", content: "Implement SEO best practices within the content management system, ensuring that educational materials are discoverable through search engines." },
                { heading: "SEM Campaign Integration", content: "Seamlessly integrate SEM campaigns within the platform, allowing educational institutions to run targeted advertising for specific courses or programs." },
                { heading: "Branding Customization Tools", content: "Provide branding customization tools, allowing educational institutions to align the platform's visual identity with their own brand for a cohesive online presence." },
                { heading: "Lead Generation Forms", content: "Incorporate lead generation forms within the learning management system, enabling educational institutions to capture prospective student information for outreach." },
                { heading: "Analytics for Marketing Performance", content: "Integrate analytics tools that specifically measure the performance of marketing efforts, providing insights into the effectiveness of branding and lead generation campaigns." },
                { heading: "Social Media Integration for Brand Visibility", content: "Enable seamless integration with social media platforms, allowing educational institutions to extend their brand visibility and engage with the audience on social channels." },
            ],
            conclusion: "In the realm of Edtech for distance learning, [Your Company] emerges as a trailblazer, transforming traditional distance education into a tech-infused, innovative learning experience accessible anytime, anywhere. Join us in reshaping the future of distance education."
        },
    ]

    return (
      <Box className="pagesWrapper">
        <RequestQuotation
          open={openRequestQuotation}
          setOpen={setOpenRequestQuotation}
          popup_heading=""
        />

        <CaseStudyModal
          open={openCaseModal}
          setOpen={setOpenCaseModal}
          item={clickedCase}
        />

        {isMobile ? <MNavbar /> : <Navbar open={open} setOpen={setOpen} />}

        <Box className="growth_header">
          <Grid container columnSpacing={3}>
            <Grid xs={12} lg={12} className="head" textAlign={"center"}>
              <h1 className="mb-6">
                Growth & Marketing <span>Services</span>
              </h1>
              <p className="mb-6">
                Embark on a digital journey where innovation becomes the
                compass, and tradition becomes the map – shaping a future where
                every click is a step toward transformation.
              </p>
              <img src="/images/growth_header.svg" alt="con" />
            </Grid>
          </Grid>
        </Box>

        <Box className="our_services">
          <Grid container spacing={3}>
            <Grid xs={12} lg={12} className="head mb-6" textAlign={"center"}>
              <h2 className="mb-3">Our Limitless Offerings Expand</h2>
              <p>
                Tech-Infused Triumph: Elevate, Propel, Transform – Your Digital
                Odyssey Unleashed.
              </p>
            </Grid>
            <Grid xs={12} sm={12} md={4} lg={4} className="sectionImg">
              <img src="/images/growth_limiteless.svg" alt="lim" />
            </Grid>
            <Grid xs={12} sm={12} md={8} lg={8}>
              <Carousel
                responsive={responsive}
                renderArrowsWhenDisabled={true}
                swipeable={true}
                draggable={true}
                customTransition={"transform 800ms ease-in-out"}
                className="growth_carousel"
              >
                {offers?.map((item, i) => (
                  <div className="main_box">
                    <div className="img_box">
                      <img src={item.img} alt="service" />
                      <h6>{item.heading}</h6>
                    </div>
                    {item.readMore ? (
                      <p>{item.fullContent}</p>
                    ) : (
                      <p>
                        {item.content}
                        <a
                          onClick={() => {
                            updateOffers(item, i);
                          }}
                        >
                          {" "}
                          Read More.
                        </a>
                      </p>
                    )}

                    <Box className="ctaBtn">
                      <CustomButton
                        text_name={"Inquire"}
                        class_name="blueMdBtn"
                        onClickFunction={() => setOpenRequestQuotation(true)}
                      />
                    </Box>
                  </div>
                ))}
              </Carousel>
            </Grid>
          </Grid>
        </Box>

        <Box className="growth_ride">
          <Grid container columnSpacing={3}>
            <Grid xs={12} lg={12} className="head mb-6" textAlign={"center"}>
              <h2 className="mb-3">
                Ride the path to <span>Success with Us</span>
              </h2>
              <p>
                Evervent: Crafting Digital Excellence, One Tailored Strategy at
                a Time.
              </p>
            </Grid>
            <Box className="content">
              <Box className="boxes_row px-3">
                <Box className="main_box">
                  <img src="images/data_backed.svg" alt="data" width={75} />
                  <h5>Data-Backed Insights</h5>
                  <p>
                    Leverage big data analytics to derive actionable insights,
                    enabling strategic decision-making and personalized customer
                    experiences.
                  </p>
                </Box>
                <Box className="main_box">
                  <img src="images/data_backed2.svg" alt="data" width={75} />
                  <h5>Digitalization Roadmap</h5>
                  <p>
                    Developing a step-by-step digitalization roadmap tailored to
                    the insurance sector's specific needs.
                  </p>
                </Box>
                <Box className="main_box">
                  <img src="images/data_backed3.svg" alt="data" width={75} />
                  <h5>Scalable Marketing Framework</h5>
                  <p>
                    Implementing a scalable marketing framework that adapts to
                    the evolving demands of the digital landscape.
                  </p>
                </Box>
                <Box className="main_box">
                  <img src="images/data_backed4.svg" alt="data" width={75} />
                  <h5>Technology Integration</h5>
                  <p>
                    Seamlessly integrating cutting-edge technologies to enhance
                    operational efficiency and customer experience.
                  </p>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Box>

        <Box className="growth_usp">
          <Grid container columnSpacing={3}>
            <Grid xs={12} lg={12} className="head mb-6" textAlign={"center"}>
              <h2 className="mb-3">
                Standing Tall with <span>Delivered Promises</span>
              </h2>
              <p>
                Tailored Solutions for Functional, Operational & ROI Excellence
              </p>
            </Grid>
            <Grid lg={12}>
              <Box className="content">
                <Grid container rowSpacing={3}>
                  <Grid xs={12} lg={4} className="content_box">
                    <Box className="usp_content">
                      <img src="images/mdi_tick.svg" alt="check" />
                      <p>
                        Unparalleled industry expertise with a decade of
                        managing large-scale performance and branding campaigns.
                      </p>
                    </Box>
                    <Box className="usp_content">
                      <img src="images/mdi_tick.svg" alt="check" />
                      <p>
                        A dedicated team of digital marketing professionals
                        committed to achieving lead generation and marketing
                        goals.
                      </p>
                    </Box>
                    <Box className="usp_content">
                      <img src="images/mdi_tick.svg" alt="check" />
                      <p>
                        Proven success in transforming traditional insurance
                        models into digitally robust and customer-centric
                        entities.
                      </p>
                    </Box>
                  </Grid>
                  <Grid
                    xs={12}
                    lg={4}
                    display={"flex"}
                    justifyContent={"center"}
                  >
                    <img src="/images/usp.svg" alt="usp" />
                  </Grid>
                  <Grid xs={12} lg={4} className="content_box">
                    <Box className="usp_content usp_content2">
                      <img src="images/mdi_tick.svg" alt="check" />
                      <p>
                        One-stop digital marketing team, offering end-to-end
                        solutions for lead generation, digital transformation,
                        and brand scaling.
                      </p>
                    </Box>
                    <Box className="usp_content usp_content2">
                      <img src="images/mdi_tick.svg" alt="check" />
                      <p>
                        Deepening your comprehension of channels and strategies
                        that drive sales. Equip your team with tools and
                        insights to navigate the evolving landscape of marketing
                        technology, influencing your revenue.
                      </p>
                    </Box>
                    <Box className="usp_content usp_content2">
                      <img src="images/mdi_tick.svg" alt="check" />
                      <p>
                        Transforming obstacles and challenges, such as market
                        disruptions and shifting trends, into opportunities for
                        robust business growth.
                      </p>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box>
          <Grid container columnSpacing={3}>
            <Grid xs={12} className="mb-10" textAlign={"center"}>
              <h2 className="mb-3">Case Studies</h2>
              <p>
                In the realm of challenges, every case study is a chapter of
                solutions, each offering its unique wisdom to those who seek the
                knowledge it holds.
              </p>
            </Grid>
          </Grid>
          <Box className="case_bottom">
            <Grid
              container
              columnSpacing={3}
              justifyContent={"center"}
              rowGap={"24px"}
            >
              {caseStudies?.map((item) => (
                <Grid xs={6} sm={6} md={4} lg={3}>
                  <CaseStudyBox
                    color="#79D05A"
                    title={item?.head}
                    description={item?.subHead}
                    onClickFunction={() => {
                      setOpenCaseModal(true);
                      setClickedCase(item);
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>

        <Box className="growth_quotation">
          <RequestQuotationComponent />
        </Box>

        <Footer />
      </Box>
    );
}

export default GrowthMarketing;
