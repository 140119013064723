import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import "../../CommonSCSS/common.scss";
import Footer from "../../Components/Desktop/Footer/Footer";
import Navbar from "../../Components/Desktop/Navbar/Navbar";
import MNavbar from "../../Components/Mobile/Navbar/MNavbar";
import useIsMobile from "../../SupportingFiles/MobileProvider";
import CustomButton from "../../Components/Common/CustomButton/CustomButton";
import CaseStudyBox from "../HealthCare/CaseStudyBox/CaseStudyBox";
import RequestQuotationComponent from "../../Components/Common/RequestComponent/RequestQuotationComponent";
import "react-multi-carousel/lib/styles.css";
import RequestQuotation from "../../Components/Common/RequestQuotationPopup/RequestQuotation";
import CaseStudyModal, { caseType } from "../../Components/Common/CaseStudy/CaseStudyModal";

function TravelHospitality() {
    const isMobile = useIsMobile();
    const [open, setOpen] = useState<boolean>(false);
    const [openRequestQuotation, setOpenRequestQuotation] = useState<boolean>(false);
    const travelNeed = [
        { heading: "Enhanced Customer Experiences", content: "Streamlining processes such as bookings and itinerary management to provide seamless and personalized services." },
        { heading: "Competitive Edge", content: " Staying competitive in the digital landscape by adopting innovative features like mobile apps, AI-driven recommendations, and data analytics." },
        { heading: "Operational Efficiency", content: "Automating tasks to reduce manual effort, minimize errors, and improve overall operational effectiveness." },
        { heading: "Security Measures", content: " Integrating robust security features to protect sensitive customer data and maintain trust." },
        { heading: "Data Harnessing", content: "Utilizing advanced analytics to gain insights into customer behavior, preferences, and trends for informed decision-making and targeted marketing." },
        { heading: "Customization", content: "Tailoring software solutions to the specific requirements of the travel or hospitality business for optimal functionality." },
        { heading: "Continuous Technological Updates", content: "Ensuring ongoing support, maintenance, and upgrades to keep the software up-to-date with the latest features and security patches." },
        { heading: "User-Friendly Online Presence", content: "Creating user-friendly websites, mobile apps, and digital platforms to meet the expectations of the modern traveler and enhance brand image." },
        { heading: "Future-Ready Investment", content: "Recognizing that investing in software development services is essential for future success, growth, and sustainability in the dynamic travel and hospitality industry." },
    ]
    useEffect(() => {
        if (openRequestQuotation) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "";
        }
    }, [openRequestQuotation]);
    const [openCaseModal, setOpenCaseModal] = useState<boolean>(false);
    const [clickedCase, setClickedCase] = useState<caseType>({
        head: "", subHead: "", mainHeading: "", keywords: "", headPara: "", intro: "",
        challenge: "", solution: "", key: [], conclusion: ""
    });
    const caseStudies: caseType[] = [
        {
            head: "CRM System", subHead: "Revolutionizing Guest Engagement with AI-Enhanced Hotel CRM",
            keywords: "CRM System, AI Tools, User-Friendly Online Presence",
            mainHeading: "Redefining Hospitality with Tailored CRM Modules",
            headPara: "Crafting Personalized Stays through Advanced CRM Solutions",
            intro: "Evervent embarked on transforming hospitality with an AI-enhanced Hotel CRM system, designed to redefine guest interactions. The goal was to address the need for a comprehensive CRM that goes beyond traditional functionalities.",
            challenge: "In the era of hospitality, where personalized experiences matter, traditional CRMs lacked the sophistication needed for exceptional stays. The challenge was to introduce a CRM that not only streamlined reservations but also offered tailored modules for guest engagement, loyalty programs, and feedback analytics.",
            solution: "Evervent's Hotel CRM system goes beyond standard functionalities, leveraging AI for personalized guest interactions. Modules for guest profiling, loyalty programs, seamless reservations, real-time feedback analytics, and automated upselling suggestions were integrated, ensuring an unparalleled guest experience.",
            key: [
                { heading: "Guest Profiling Module", content: "Personalized profiles for tailored guest experiences." },
                { heading: "Loyalty Program Integration", content: "Enhancing guest retention through personalized rewards." },
                { heading: "Seamless Reservations", content: "Streamlining the booking process for a frictionless experience." },
                { heading: "Real-time Feedback Analytics", content: "Instant insights into guest satisfaction for prompt service improvements." },
                { heading: "Automated Upselling Suggestions", content: "AI-driven recommendations for personalized guest experiences." },
                { heading: "Intelligent Room Assignment", content: "AI-powered room allocation based on guest preferences." },
                { heading: "Contactless Check-in", content: "Streamlining arrivals with a seamless, contactless check-in process." },
            ],
            conclusion: "Through advanced CRM solutions with tailored modules, Evervent has redefined the hospitality landscape, offering hotels the tools needed to create delightful and memorable stays for their guests."
        },
        {
            head: "Revenue Management", subHead: "Elevating Profitability Through AI-Driven Revenue Management",
            keywords: "AI Tools, Hotel Revenue Management System, Data Analytics",
            mainHeading: "Unleashing Revenue Potential with Intelligent Hotel Management",
            headPara: "Smart Decision-Making for Peak Revenue and Operational Efficiency",
            intro: "Evervent took on the challenge of transforming revenue strategies in hotel management. The focus was on introducing a dynamic Hotel Revenue Management System, powered by AI tools, to optimize pricing, inventory, and distribution.",
            challenge: "Traditional revenue management processes often rely on manual efforts, leading to inefficiencies and missed opportunities. The challenge was to replace manual approaches with automation and AI-driven decision-making, aiming for enhanced profitability.",
            solution: "Evervent's solution was an AI-powered Hotel Revenue Management System, strategically optimizing pricing, inventory, and distribution. The goal was to empower hotels with the ability to make data-driven decisions for maximizing revenue and operational efficiency.",
            key: [
                { heading: "AI Tools for Decision-Making", content: "Leveraging artificial intelligence for strategic revenue decisions." },
                { heading: "Dynamic Pricing Optimization", content: "AI-driven algorithms for optimal room pricing based on demand." },
                { heading: "Inventory Management", content: "Streamlining inventory processes for better resource utilization." },
                { heading: "Distribution Strategy Optimization", content: "Maximizing reach and bookings through intelligent distribution strategies." },
                { heading: "Data Analytics Insights", content: "Utilizing data analytics to gain insights into customer behavior and market trends." },
                { heading: "Competitor Analysis", content: "AI-driven tools to analyze competitor pricing and market positioning." },
                { heading: "Forecasting Models", content: "Predictive analytics for anticipating market trends and adjusting strategies." },
            ],
            conclusion: "Through the implementation of AI-powered revenue management, Evervent has revolutionized how hotels operate, enabling them to make intelligent decisions, stay competitive, and unlock peak revenue potential in the ever-evolving hospitality landscape."
        },
    ]
    return (
      <Box className="pagesWrapper">
        <RequestQuotation
          open={openRequestQuotation}
          setOpen={setOpenRequestQuotation}
          popup_heading=""
        />

        <CaseStudyModal
          open={openCaseModal}
          setOpen={setOpenCaseModal}
          item={clickedCase}
        />

        {isMobile ? <MNavbar /> : <Navbar open={open} setOpen={setOpen} />}

        <Box className="travel_header pagesHeader mb-20 mb-40-m">
          <Grid container spacing={3} alignItems="center">
            <Grid xs={12} textAlign={"center"}>
              <h1 className="mb-6">
                Evervent: Navigating Excellence in <span>Hospitality</span> and{" "}
                <span>
                  Travel Software - Crafting Tomorrow's Experiences, Today!
                </span>
              </h1>
              <p>
                Evervent: Igniting Digital Transformation in Hospitality and
                Travel. Elevate Your Experience, Conquer Challenges, and Shape
                Tomorrow's Journeys -
              </p>
              <p className="mb-6">
                Where Innovation Sparks Unforgettable Adventures!
              </p>
            </Grid>
            <Grid xs={12}>
              <Box className="product_header_background">
                <img
                  src="./images/travel_header.svg"
                  alt="product-development"
                  className="prod-header-img"
                />
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box className="travel_service">
          <Grid container alignItems="center">
            <Grid xs={12} textAlign={"center"} className="mb-10">
              <h2>
                <span>Our services specialize in the </span>development of
                custom software for the hospitality and travel industry
              </h2>
            </Grid>
            <Grid xs={12} lg={6}>
              <Box display={"flex"} justifyContent={"center"}>
                <img
                  src="./images/travel_service.svg"
                  alt="product-development"
                  className="prod-header-img"
                />
              </Box>
            </Grid>
            <Grid xs={12} lg={6}>
              <p className="mb-6 mt-6 px-3">
                We offer a wide array of services designed to enhance your
                workflow, and we are capable of joining a project at any phase.
                Our primary services encompass:
              </p>
              <Box>
                <Grid container columnSpacing={3} alignItems="center">
                  <Grid xs={12} sm={6}>
                    <div className="main_box">
                      <p>
                        Strategic Advisory for Hospitality & Travel Software
                        Development
                      </p>
                    </div>
                  </Grid>
                  <Grid xs={12} sm={6}>
                    <div className="main_box">
                      <p>
                        Comprehensive Web, Desktop, and Mobile Development
                        Catered to Hospitality & Travel Enterprises
                      </p>
                    </div>
                  </Grid>
                  <Grid xs={12} sm={6}>
                    <div className="main_box">
                      <p>
                        Ground-Up Creation and Revitalization of Existing
                        Solutions in Hospitality & Travel Software Development
                      </p>
                    </div>
                  </Grid>
                  <Grid xs={12} sm={6}>
                    <div className="main_box">
                      <p>
                        Ongoing Maintenance and Support for Hospitality & Travel
                        Software
                      </p>
                    </div>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box className="edu_service travel_keys">
          <Grid container columnSpacing={3}>
            <Grid xs={12} className="head mb-10" textAlign={"center"}>
              <h2 className="mb-3">Key Services</h2>
              <p>We are ready with that whatever you want</p>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid xs={12} sm={6} lg={4}>
              <Box className="content">
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <div>
                    <span>01</span>
                    <h5>Travel API Integration</h5>
                  </div>
                  <img src="/images/travelKey1.svg" alt="lor" />
                </Box>
                <div className="seprator mt-6"></div>
                <p>
                  Developing a comprehensive travel platform that integrates
                  multiple APIs to offer users a unified experience, covering
                  flights, hotel bookings, and a diverse range of activities for
                  a hassle-free travel planning process.
                </p>
                <Box className="ctaBtn">
                  <CustomButton
                    text_name={"Inquire"}
                    class_name="blueMdBtn"
                    onClickFunction={() => setOpenRequestQuotation(true)}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid xs={12} sm={6} lg={4}>
              <Box className="content">
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <div>
                    <span>02</span>
                    <h5>Travel Portal</h5>
                  </div>
                  <img src="/images/travelKey2.svg" alt="lor" />
                </Box>
                <div className="seprator mt-6"></div>
                <p>
                  Embarking on the creation of a customized travel portal
                  software, designed to seamlessly integrate flights, hotels,
                  and activities, offering users a personalized and
                  all-encompassing platform for effortless trip planning and
                  booking.
                </p>
                <Box className="ctaBtn">
                  <CustomButton
                    text_name={"Inquire"}
                    class_name="blueMdBtn"
                    onClickFunction={() => setOpenRequestQuotation(true)}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid xs={12} sm={6} lg={4}>
              <Box className="content">
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <div>
                    <span>03</span>
                    <h5>Booking Engine</h5>
                  </div>
                  <img src="/images/travelKey3.svg" alt="lor" />
                </Box>
                <div className="seprator mt-6"></div>
                <p>
                  Creating a dynamic booking engine that goes beyond the
                  ordinary, providing users with a frictionless experience for
                  reserving hotels, flights, and activities. Our aim is to blend
                  intuitive design with robust functionality, ensuring a
                  streamlined and comprehensive booking process.
                </p>
                <Box className="ctaBtn">
                  <CustomButton
                    text_name={"Inquire"}
                    class_name="blueMdBtn"
                    onClickFunction={() => setOpenRequestQuotation(true)}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid xs={12} sm={6} lg={4}>
              <Box className="content">
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <div>
                    <span>04</span>
                    <h5>CRM System</h5>
                  </div>
                  <img src="/images/travelKey4.svg" alt="lor" />
                </Box>
                <div className="seprator mt-6"></div>
                <p>
                  Crafting a magnetic Hotel and Travel CRM experience—where
                  reservations become an art and customer connections are more
                  than data. Elevate your hospitality prowess with personalized
                  profiles, seamless reservations, and analytics that turn
                  insights into impact for hotels and travel agencies.
                </p>
                <Box className="ctaBtn">
                  <CustomButton
                    text_name={"Inquire"}
                    class_name="blueMdBtn"
                    onClickFunction={() => setOpenRequestQuotation(true)}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid xs={12} sm={6} lg={4}>
              <Box className="content">
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <div>
                    <span>05</span>
                    <h5>Hotel Property Management System</h5>
                  </div>
                  <img src="/images/travelKey5.svg" alt="lor" />
                </Box>
                <div className="seprator mt-6"></div>
                <p>
                  Developing a specialized Hotel Property Management System
                  (PMS) to streamline operations. Our focus includes effortless
                  reservation handling, smooth check-in/check-out processes, and
                  personalized features for a hassle-free and delightful hotel
                  Service.
                </p>
                <Box className="ctaBtn">
                  <CustomButton
                    text_name={"Inquire"}
                    class_name="blueMdBtn"
                    onClickFunction={() => setOpenRequestQuotation(true)}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid xs={12} sm={6} lg={4}>
              <Box className="content">
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <div>
                    <span>06</span>
                    <h5>Hotel Revenue Management System</h5>
                  </div>
                  <img src="/images/travelKey6.svg" alt="lor" />
                </Box>
                <div className="seprator mt-6"></div>
                <p>
                  Creating a dynamic Hotel Revenue Management System to optimize
                  pricing, inventory, and distribution strategies. Our focus is
                  on maximizing revenue and enhancing profitability for hotels
                  through smart and data-driven decision-making.
                </p>
                <Box className="ctaBtn">
                  <CustomButton
                    text_name={"Inquire"}
                    class_name="blueMdBtn"
                    onClickFunction={() => setOpenRequestQuotation(true)}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box className="trans_offers travel_offers">
          <Grid container spacing={3}>
            <Grid xs={12} lg={6}>
              <div className="head mb-6">
                <h2>
                  <span>Why do you need </span>a Travel & Hospitality Software
                  Development Service?
                </h2>
              </div>
              <Box className="content">
                {travelNeed?.map((item) => (
                  <div className="main_box mb-4">
                    <img src="/images/check.svg" alt="check" />
                    <h6>
                      {item?.heading}: <span>{item?.content}</span>
                    </h6>
                  </div>
                ))}
              </Box>
            </Grid>
            <Grid xs={12} lg={6} display={"flex"} justifyContent={"center"}>
              <img src="/images/travel_need.svg" alt="" />
            </Grid>
          </Grid>
        </Box>

        <Box className="case_study">
          <Grid container columnSpacing={3}>
            <Grid xs={12} className="mb-10" textAlign={"center"}>
              <h2 className="mb-3">Case Studies</h2>
              <p>
                In the realm of challenges, every case study is a chapter of
                solutions, each offering its unique wisdom to those who seek the
                knowledge it holds.
              </p>
            </Grid>
          </Grid>
          <Box className="case_bottom">
            <Grid
              container
              columnSpacing={3}
              justifyContent={"center"}
              rowGap={"24px"}
            >
              {caseStudies?.map((item) => (
                <Grid xs={6} sm={6} md={4} lg={3}>
                  <CaseStudyBox
                    color="#79D05A"
                    title={item?.head}
                    description={item?.subHead}
                    onClickFunction={() => {
                      setOpenCaseModal(true);
                      setClickedCase(item);
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>

        <Box className="edu_quotation">
          <RequestQuotationComponent />
        </Box>

        <Footer />
      </Box>
    );
}

export default TravelHospitality;


